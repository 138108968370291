import React, { useEffect, useState } from 'react'
import CompaniesController from '../../controllers/Companies/CompaniesController';
import handleApiResponse from '../../services/ApiResponse';

interface Companies {
  id: number,
  created_at: string,
  updated_at: string,
  uuid: string,
  name: string,
  description: string,
  image: string,
  address: string,
  longitude: number,
  latitude: number,
  email: string,
  phone_number: string,
  approved: number,
  active: number,
  verification_status: string,
  category: number
}

const GetCompanies = (id?: number | string | null, sub?: boolean, group?: string | null) => {
  const [compIndex, setcompIndex] = useState<Map<number, Companies>>(new Map());
  let [companies, setCompanies] = useState<Companies[]>([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        let response
        if (group == "search")
          response = await CompaniesController.getSearchCompanies(id!)
        else if (sub) {
          if (id && localStorage.getItem("company_id"))
            response = await CompaniesController.getAllCompanies()
          else if (id)
            response = await CompaniesController.getCompanyByCategory(id)
        }
        else {
          if (id)
            response = await CompaniesController.getAllCompanies()
          else
            response = await CompaniesController.getAllCompanies()
        }
        const apiResponse = handleApiResponse(response)
        if (apiResponse.success) {
          companies = apiResponse.data;
          setCompanies(apiResponse.data)
          apiResponse.data.forEach((element: any) => {
            let ob: Companies = {
              id: element["id"],
              created_at: element["created_at"],
              updated_at: element["updated_at"],
              uuid: element["uuid"],
              name: element["name"],
              description: element["description"],
              image: element["image"],
              address: element["address"],
              longitude: element["longitude"],
              latitude: element["latitude"],
              email: element["email"],
              phone_number: element["phone_number"],
              approved: element["approved"],
              active: element["active"],
              verification_status: element["verification_status"],
              category: element["category"]
            };
            compIndex.set(element["id"], ob);
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    return () => {
    }
  }, [compIndex]);


  return { companies, compIndex };
}

export default GetCompanies;