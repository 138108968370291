import { Grid, Box, Skeleton, Stack } from "@mui/material";

export function loadingGrid() {
  return (
    <Grid display={'flex'}>
      <Box sx={{ width: 300 }}>
        <div>
          <br />
          <Skeleton variant="circular" width={100} height={100} style={{ margin: "auto" }} />
          <Skeleton variant="rectangular" width={180} height={30} style={{ margin: "auto", marginTop: "10px" }} />
        </div>
      </Box>
      <Box sx={{ width: 300 }}>
        <div>
          <br />
          <Skeleton variant="circular" width={100} height={100} style={{ margin: "auto" }} />
          <Skeleton variant="rectangular" width={180} height={30} style={{ margin: "auto", marginTop: "10px" }} />
        </div>
      </Box>
      <Box sx={{ width: 300 }}>
        <div>
          <br />
          <Skeleton variant="circular" width={100} height={100} style={{ margin: "auto" }} />
          <Skeleton variant="rectangular" width={180} height={30} style={{ margin: "auto", marginTop: "10px" }} />
        </div>
      </Box>
    </Grid>
  )
}

export function loadingTable() {
  return (
    <Stack spacing={1}>
      <Skeleton variant="rectangular" style={{
        width: "100%",
        height: "50px"
      }} />
      <Skeleton variant="rectangular" style={{
        width: "100%",
        height: "50px"
      }} />
      <Skeleton variant="rectangular" style={{
        width: "100%",
        height: "50px"
      }} />
      <Skeleton variant="rectangular" style={{
        width: "100%",
        height: "50px"
      }} />
      <Skeleton variant="rectangular" style={{
        width: "100%",
        height: "50px"
      }} />
    </Stack>
  )
}