import React, { ChangeEvent, SetStateAction } from 'react'
import { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import ImageUpload from '../../components/DragUpload';
import CategoriesController from '../../controllers/Categories/CategoriesController';
import handleApiResponse from '../../services/ApiResponse';
import { CircularProgress } from '@mui/material';
import { AlertDialog } from '../../components/Dialog';
import { useParams } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { GetCategories, GetCategoriesP } from '../../components/Categories/GetCategories';
import Alert from '@mui/material/Alert';
import Validation from '../../components/Validation';


interface obj {
  name_en: string,
  name_ar: string,
  image?: string | File,
  parent: string
}


function CategoryCreate() {
  var tbnclass = document.getElementsByClassName("topboxnum") as HTMLCollectionOf<HTMLElement>;
  var tbtclass = document.getElementsByClassName("topboxtext") as HTMLCollectionOf<HTMLElement>;
  const [errors, setError] = useState<string[]>([]);
  const [nexti, setNexti] = useState(0);
  const [categorydata, setCategoryData] = useState<obj>({
    name_en: "",
    name_ar: "",
    image: "" || new File([""], "", { type: 'image/png' }),
    parent: ""
  });

  const [keys, setKeys] = useState(0);
  const [subsCatDrop, setSubCatDrop] = useState<Map<string, any>>(new Map());
  const [loadCat, setloadCat] = useState(false);

  const changecat = (event: SelectChangeEvent) => {
    setCategoryData({
      ...categorydata,
      parent: event.target.value
    });
    setloadCat(true)
    let name = event.target.name
    Array.from(subsCatDrop.entries()).map(([key, value]) => {
      if (parseInt(key) >= parseInt(name)) {
        subsCatDrop.delete(key)
      }
      setSubCatDrop(subsCatDrop)
    })
    const fetchData = async () => {
      const data = await GetCategoriesP(parseInt(event.target.value), false);
      if (data.length > 0) {
        setSubCatDrop(new Map(subsCatDrop).set(name, data))
        setloadCat(false)
      }
      else
        setloadCat(false)
    };
    fetchData();
  };


  const [checkBox, setcheckBox] = useState(false);
  const handleChangeCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setcheckBox(event.target.checked);
  };


  const { categories } = GetCategories();

  const [loading, setLoading] = useState(false);
  const [opendi, setOpendi] = React.useState(false)
  const [datadia, setDiag] = useState({
    title: 'Category',
    message: 'Category created successfuly',
    mode: 'normal'
  })

  function checkDialogAnswer(data: { clicked: boolean; }) {
    setOpendi(false)
    window.location.reload();
  }


  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCategoryData({
      ...categorydata,
      [name]: value
    });
  };

  function objectToFormData(obj: Record<string, any>): FormData {
    const formData = new FormData();

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];

        if (Array.isArray(value)) {
          for (let i = 0; i < value.length; i++) {
            formData.append(`${key}[]`, value[i]);
          }
        } else {
          console.log(key, value)
          formData.append(key, value);
        }
      }
    }
    return formData;
  }

  useEffect(() => {
    setNexti(0)
    tbnclass[0].style.backgroundColor = "#2173FE";
    tbtclass[0].style.color = "#2173FE";
    return () => {
    };
  }, []);


  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response
        if (id) {
          response = await CategoriesController.getCategoryById(id)
          const apiResponse = handleApiResponse(response)
          if (apiResponse.success) {
            setCategoryData(apiResponse.data)
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    return () => {
    }
  }, []);

  async function createCategory() {
    setLoading(true)
    try {
      let response
      if (id && categorydata.parent != null) {
        console.log("update sub category")
        if (typeof categorydata.image == "string")
          delete categorydata.image
        response = await CategoriesController.updateSubCategory(id, objectToFormData(categorydata))
      }
      else if (id && categorydata.parent == null) {
        console.log("update category")
        if (typeof categorydata.image == "string")
          delete categorydata.image
        response = await CategoriesController.updateCategory(id, objectToFormData(categorydata))
      }
      else if (!id && checkBox) {
        console.log("create sub category")
        response = await CategoriesController.createSubCategory(objectToFormData(categorydata))
      }
      else {
        console.log("create category")
        response = await CategoriesController.createCategory(objectToFormData(categorydata))
      }
      const apiResponse = handleApiResponse(response)
      if (apiResponse.success) {
        datadia.title = "Success"
        id ? datadia.message = "Category saved successfully" : datadia.message = "Category added successfully"
        setOpendi(true)
      } else {
        console.error('Failed to fetch user data.');
      }
    } catch (error: any) {
      datadia.title = "Error"
      datadia.message = "Error creating category"
      setOpendi(true)
      console.error('Error fetching user data:', error);
    }
    setLoading(false)
  }

  function handleNext() {
    if (nexti < tbnclass.length) {
      setNexti(nexti + 1)
    }
    if (nexti < tbnclass.length - 1) {
      tbnclass[nexti + 1].style.backgroundColor = "#2173FE";
      tbtclass[nexti + 1].style.color = "#2173FE";
    }
    else {
      console.log(typeof categorydata.image)
      console.log(id)
      const { errors, flag } = Validation(
        [
          id ? [categorydata.image, "Image is required", 1] : (typeof categorydata.image != "string" ? [categorydata.image?.name, "Image is required", 1] : ["", "Image is required", 1, false]),
          [categorydata.name_en, "Name is required", 0],
          [categorydata.name_ar, "Name Arabic is required", 0],
          [categorydata.parent, "Category is required", 0, !checkBox],
        ]
      )
      setError(errors)
      if (flag == 1000)
        createCategory()
      else
        setNexti(flag)
    }
  }

  const handleImageUpload = (files: FileList | null) => {
    if (files) {
      categorydata.image = files[0]
    }
  };

  return (
    <div className='holder'>
      <Stack direction="row" className='topnbs' margin={"auto"} marginBottom={3}>
        <div className='topboxnum'>1</div>
        <span className='topboxtext'>Shop Info</span>
        <div className='topboxline'></div>
        <div className='topboxnum'>2</div>
        <span className='topboxtext'>Media</span>
      </Stack>
      {nexti === 0 ? (
        <Card className='boxcard'>
          <h2 style={{ textAlign: "center" }}>Category Information</h2>
          <Stack direction="row">
            <Stack direction="column" flex={1}>
              <p>Name</p>
              <TextField
                label="Name"
                variant="outlined"
                className='inpp'
                value={categorydata.name_en}
                name='name_en'
                onChange={handleChange} />
            </Stack>
            <Stack direction="column" flex={1} style={{ marginLeft: "10px" }}>
              <p>Arabic Name</p>
              <TextField
                label="Arabic Name"
                variant="outlined"
                className='inpp'
                value={categorydata.name_ar}
                name='name_ar'
                onChange={handleChange} />
            </Stack>
          </Stack>
          <Stack direction="column" flex={1} style={{ marginTop: "10px" }}>
            {/* {!id && ( */}
            <FormControlLabel control={<Checkbox checked={checkBox} onChange={handleChangeCheck} />} label="Sub Category?" />
            {/* )} */}
            {/* {checkBox || categorydata.parent != "" && categorydata.parent != null ? ( */}
            {checkBox ? (
              <>
                <p>Choose Category</p>
                <Select
                  className='inpp'
                  value={categorydata.parent}
                  label="Category"
                  name={"0"}
                  onChange={changecat}
                >
                  {categories.map((category) => (
                    <MenuItem value={category.id}>{
                      localStorage.getItem('language') == "1" ? category.name_en : category.name_ar
                    }</MenuItem>
                  ))}
                </Select>
                {
                  Array.from(subsCatDrop.entries()).map(([key, value], i) => (
                    <>
                      <Select
                        className='inpp'
                        value={categorydata.parent}
                        label={"Sub Category " + key}
                        name={"" + (i + 1)}
                        onChange={changecat}
                      >
                        {value.map((v: any) => (
                          <MenuItem value={v.id}>{
                            localStorage.getItem('language') == "1" ? v.name_en : v.name_ar
                          }</MenuItem>
                        ))}
                      </Select>
                    </>
                  ))
                }
                {
                  loadCat &&
                  <CircularProgress className='boxbutton' style={{
                    margin: "auto",
                    marginTop: "10px",
                  }} />
                }
              </>) : (null)}
          </Stack>
        </Card>
      ) : (
        <ImageUpload onImageUpload={handleImageUpload} imageFile={categorydata.image} />
      )}
      {errors.length > 0 && (<Alert severity="error">
        <ul>
          {errors.map((e) => (
            <li>{e}</li>
          ))}
        </ul>
      </Alert>)}
      {loading ? (
        <CircularProgress className='boxbutton' style={{
          marginTop: "10px"
        }} />) : (<Button variant="contained" className='boxbutton' onClick={handleNext} disableElevation style={{
          backgroundColor: "#2173FE",
          marginTop: "10px"
        }}>
          Next
        </Button>)}
      <AlertDialog opendialog={opendi} datadia={datadia} dialogAnswer={checkDialogAnswer} />
    </div>
  )
}

export default CategoryCreate