import axios from "axios";
import api from "../../services/apiMain";
const headers = { "Content-Type": "multipart/form-data" }

const BannersApiService = {
    createBanner: (data: FormData) => {
        return api.post('/api/banner', data, { headers });
    },
    getAllBanners: () => {
        return api.get('/api/banners');
    },
    deleteBanner: (id: string | number) => {
        return api.delete('/api/banner/' + id);
    },
    getBannerById: (id: string | number) => {
        return api.get('/api/banner/id/'+id);
    },
    updateBanner: (id: string|number, data: FormData) => {
        return api.post('/api/banner/update/' + id, data, { headers });
    },
}

export default BannersApiService