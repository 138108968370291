import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import { useParams } from 'react-router-dom';
import BillsController from '../../controllers/Orders/OrdersController';
import handleApiResponse from '../../services/ApiResponse';
import { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { convertDate } from '../../components/DateConverter';
import DeleteOrder from '../../components/Orders/DeleteOrder';
import DeleteIcon from '@mui/icons-material/Delete';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { jsPDF } from "jspdf";
import html2pdf from 'html2pdf.js';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';




function OrdersBill() {

  const [ordersBill, setOrdersBill] = React.useState<any>([]);
  const [bill, setBill] = React.useState<any>([]);
  const [noData, setNoData] = React.useState<any>(false);
  const { id } = useParams()
  const { group } = useParams()
  const [isCopied, setIsCopied] = React.useState(false);


  async function fetchOrdersBill() {
    let result;
    if (id) {
      result = await BillsController.getBillOrders(id)
      const apiResponse = handleApiResponse(result)
      if (apiResponse.success) {
        setOrdersBill(apiResponse.data)
        if (Array.isArray(ordersBill.data)) {
          if (ordersBill.data.length == 0)
            setNoData(true)
        }
        else
          setNoData(true)
      }
      else {
        setNoData(true)
      }
    }
  }

  // function PrintExportSelectedRows() {
  //   const { data, loading } = useDemoData({
  //     dataSet: 'Commodity',
  //     rowLength: 10,
  //     maxColumns: 6,
  //   });
  // }


  function exportPDF() {
    let contents = "<div style='font-size:16px'>";
    contents += `<h3>Bill Details for <small>User: ${bill.user_full_name}</small></h3>` +
      `<small>Created at: ${bill.created_at}</small>` +
      "<hr>";

    let prodprice = 0;
    for (let i = 0; i < ordersBill.length; i++) {
      if (i % 3 == 0 && i !== 0) {
        contents += "<span class='html2pdf__page-break'></span><h3>Order Details<h3>" +
          "<hr>";
      }

      contents += "<span>Item Code: " + ordersBill[i].item_code + "</span>";
      contents += `<h4 style='margin-top:5px;'>${ordersBill[i].item_name}, from ${ordersBill[i].company_name} company</h4>` +
        `<div style='margin-bottom:15px'>Price: $${ordersBill[i].price_unit}<br>` +
        `Quantity: ${ordersBill[i].quantity}<br>`;

      if (ordersBill[i].combo)
        contents += "<span>Combo: " + ordersBill[i].combo_price + "</span>";
      if (ordersBill[i].sold)
        contents += "<span>Sold: " + ordersBill[i].sold_price + "</span>";

      contents += `Status: ${ordersBill[i].status}<br>` +
        `<b>Total: </b>$${ordersBill[i].total_price}</div><br><hr><br>`;
      prodprice += ordersBill[i].total_price;
    }

    contents += `Products Price: $${prodprice}<br>` +
      `Delivery: $${bill.delivery_charge}<br>` +
      `<b>Total: </b>$${bill.total_amount}</div></div>`;


    html2pdf(contents, {
      margin: 0.5,
      filename: "invoice " + bill.user_full_name,
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { dpi: 76, letterRendering: true },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
    },
    );

    // const conts = document.createElement('div');
    // conts.innerHTML = contents;
    // conts.id="tet";
    // const tt=document.getElementById("tet");


    // html2canvas(tt!).then((canvas) => {
    //   const pdf = new jsPDF();
    //   pdf.save('exported-content.pdf');
    // });

  }



  async function fetchBillById() {
    let result;
    if (id) {
      result = await BillsController.getBillById(id)
      const apiResponse = handleApiResponse(result)
      if (apiResponse.success) {
        setBill(apiResponse.data)
      }
    }
  }

  useEffect(() => {
    fetchOrdersBill()
    fetchBillById()
  }, [])

  useEffect(() => {
    console.log(bill)
    console.log(ordersBill)
  }, [bill, ordersBill])

  const google_map_link="https://www.google.com/maps?q="+bill.longitude+","+bill.latitude;
  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(google_map_link);
      setIsCopied(true);
    } catch (error) {
      console.error('Unable to copy to clipboard', error);
    }
  };
  return (
    <>
      {/* <div style={{ height: 300, width: '100%' }}>
        <DataGrid {...ordersBill} slots={{ toolbar: GridToolbar }} />
      </div> */}


      <Card id="j" style={{
        marginBottom: "20px"
      }}>
        {
          bill.id &&
          <CardContent style={{
            display: "flex",
            justifyContent: "space-between"
          }}>
            <p>
              <b>Bill Id: </b>{bill.id}<br />
              <b>Created: </b>{convertDate(bill.created_at)}<br />
              <b>User: </b>{bill.user_full_name}<br />
              <b>Address: </b>{bill.address}<br />
              <b>Address URL: </b> 
              <a style={{color:"#306BFC",fontWeight:'bold'}} href={google_map_link} target='_blank'>View on Google Maps</a>
              <button style={{margin:'10px',color:"#306BFC",paddingTop:'10px',paddingBottom:'5px',paddingRight:'20px',paddingLeft:'20px',backgroundColor:'white',border:'none',cursor:'pointer'}} onClick={handleCopyClick}>
                {isCopied ? 'Copied!' : <>
                  <ContentCopyIcon
                  style={{
                    fontSize: "20px",
                  color: "#0000FF",
                  cursor: "pointer"
                  }}
                  />
                </>}
              </button>
              <br />
              <b>Total: </b>{bill.total_amount}$<br />
            </p>
            <Button variant="text" onClick={exportPDF}><PictureAsPdfIcon /></Button>
          </CardContent>
        }


      </Card>
      <Box sx={{ width: '100%' }}>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          {ordersBill.map((item: any) => (
            <Grid
              // style={{
              //   margin:"auto"
              // }}
              key={item.id}
              item
              xs={12}
              sm={6}
              md={4}>
              <Card sx={{ maxWidth: 345 }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    image={"https://api.kolshein.online/storage/" + item.item_image}
                  />
                  <CardContent>
                    <p><b>Item Name: </b>{item.item_name}</p>
                    <p><b>Company Name: </b>{item.company_name}</p>
                    <b>Item Description: </b><Typography variant="body2" color="text.secondary">
                      {item.item_description}
                    </Typography>
                    <p><b>Size: </b>{item.size}</p>
                    {item.sold && <p><b>Sold: </b><span>✅</span></p>}
                    {/* <span><b>Extra: </b>{item.extra}</span> */}
                    {item.combo && <p><b>Combo: </b><span>✅</span></p>}
                    <p><b>Quantity: </b>{item.quantity}</p>
                    <p><b>Price Unit: </b>{item.price_unit}</p>
                    <p><b>Total Price: </b>{item.total_price}</p>
                    <p><b>Ordered: </b>{item.ordered}</p>
                    <p><b>Status: </b>{item.status}</p>
                    <b>Comment: </b><Typography variant="body2" color="text.secondary">
                      {item.comment}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <center>
                  <DeleteOrder id={item.id} icon={
                    {
                      color: "red",
                      icon: <DeleteIcon />
                    }
                  } />
                </center>


                {/* <CardActions>
                <Button size="small" color="primary">
                  Edit
                </Button>
              </CardActions> */}
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  )
}

export default OrdersBill;

