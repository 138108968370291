import React, { useEffect, useState } from 'react'
import ColorsController from '../../../controllers/Settings/Colors/ColorsController';
import handleApiResponse from '../../../services/ApiResponse';

interface Colors {
  id: number,
  name_en: string,
  name_ar: string,
  code: string
}

const GetColors = (id?: number | string) => {
  const colorIndex: Map<number, Colors> = new Map();
  let [colors, setColors] = useState<Colors[]>([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        let response
        if (id)
          response = await ColorsController.getColorById(id)
        else
          response = await ColorsController.getAllColors()
        const apiResponse = handleApiResponse(response)
        if (apiResponse.success) {
          colors = apiResponse.data;
          setColors(apiResponse.data)
          apiResponse.data.forEach((element: any) => {
            let ob: Colors = {
              id: element["id"],
              name_en: element["name_en"],
              name_ar: element["name_ar"],
              code: element["code"]
            };
            colorIndex.set(element["id"], ob);
          });
          console.log(colors)
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    return () => {
    }
  }, []);


  return { colors, colorIndex };
}

export default GetColors;