import { useEffect, useState } from 'react';
import { Tables } from '../../components/Tables';
import GetCompanies from '../../components/Companies/GetCompanies';
import { loadingTable } from '../../components/loadingSkeleton';


interface Company {
    id: string;
    data: Array<number | string>,
}

function Orders() {
    const heads = ['Name', 'Description', 'Address', 'Email', 'Phone', 'Category'];
    const [companyData, setCompanyData] = useState<Company[]>([]);
    // if (localStorage.getItem("company_id"))
    //     window.location.href = '/companymanage/' + localStorage.getItem("company_id")

    const { companies, compIndex } = GetCompanies(null, false, null);
    console.log(companies)

    useEffect(() => {
        if (companyData.length === 0) {
            const updatedCompanyData = companies.map((element: any) => ({
                id: element.uuid,
                img: "https://api.kolshein.online/storage/" + element.image,
                data: [
                    0,
                    <span style={{ cursor: "pointer" }} onClick={() => { window.location.href = "../../../bills/company/" + element.uuid }}>{element.name}</span>,
                    // element.created_at,
                    element.description,
                    element.address,
                    element.email,
                    element.phone_number,
                    // <VerifyCompany verified={element.verification_status} icon={
                    //     {
                    //         color: "grey",
                    //         icon: <VerifiedIcon />
                    //     }
                    // } id={element.uuid} />,
                    // catsIndex.get(element.category)?.name_en,
                    localStorage.getItem('language') == "1" ? element.category_name_en : element.category_name_ar,
                ],
                // icons: [
                //     {
                //         mode: 'linked',
                //         icon: <EditIcon />,
                //         color: "#2173FE",
                //         link: "/companymanage/" + element.uuid
                //     },
                //     {
                //         mode: 'delete',
                //         item: <DeleteCompanies icon={
                //             {
                //                 color: "red",
                //                 icon: <DeleteIcon />
                //             }
                //         } id={element.uuid} />
                //     },
                // ]
            }));
            setCompanyData([...updatedCompanyData]);
        }
    }, [companies]);


    function pageChange(data: { page: number }) {

    }

    return (
        <>
            {companyData.length > 0 ? (
                <Tables heads={heads} rows={companyData} setpage={pageChange} />
            ) : (
                loadingTable()
            )}
        </>
    );
}

export default Orders;
