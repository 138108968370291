import { useEffect, useState } from 'react';
import { Tables } from '../../components/Tables';
import GetCompanies from '../../components/Companies/GetCompanies';
import { loadingTable } from '../../components/loadingSkeleton';
import { useParams } from 'react-router-dom';
import BillsController from '../../controllers/Orders/OrdersController';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOrders from '../../components/Orders/UpdateOrdersStatus';
import TimerIcon from '@mui/icons-material/Timer';
import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress';
import UpdateOrdersStatus from '../../components/Orders/UpdateOrdersStatus';
import handleApiResponse from '../../services/ApiResponse';

interface Bill {
    id: string;
    data: Array<number | string>,
}

function BillsUsers() {
    const heads = ['Id', 'User Name', 'Company Name', 'Address', 'Status', 'Total', 'Action'];
    const [billData, setBillData] = useState<Bill[]>([]);
    const [billBE, setBillBE] = useState<any>([]);
    const [noData, setNoData] = useState<any>(false);
    const { id } = useParams()
    const { group } = useParams()
    const [page, setPage] = useState(1);
    const [last_page, setLastPage] = useState(1);
    // if (localStorage.getItem("company_id"))
    //     window.location.href = '/companymanage/' + localStorage.getItem("company_id")

    async function fetchBillCompanyUser() {
        let result;
        if (group) {
            if (group == "company")
                result = await BillsController.getCompanyBills(id!, page, "active")
            // else if (group == "user")
            //     result = await BillsController.getUserBills(id!, "active")
            const apiResponse = handleApiResponse(result)
            if (apiResponse.success) {
                console.log(apiResponse)
                setBillBE(apiResponse.data.data)
                setLastPage(apiResponse.data.last_page)
                // console.log(apiResponse.data.data.company_details)
                if (Array.isArray(billBE.bill_data)) {
                    if (billBE.length == 0)
                        setNoData(true)
                }
                else
                    setNoData(true)
            }
            else {
                setNoData(true)
            }
        }
    }

    useEffect(() => {
        fetchBillCompanyUser()
    }, [page])


    // async function setStatus(oid: string | number, status: string) {
    //     await BillsController.updateOrderStatus(oid!, { status: status })
    //     fetchBillCompany()
    // }

    function statusIcon(icon: any, oid: string | number, status: string) {
        return (
            <span style={{
                cursor: "pointer"
            }}
            // onClick={() => setStatus(oid, status)}
            >
                <UpdateOrdersStatus
                    icon={
                        {
                            icon: icon
                        }
                    }
                    oid={oid}
                    status={status}
                    fetchData={fetchBillCompanyUser}
                />
                {/* {icon} */}
            </span>
        )
    }

    useEffect(() => {
        let data = billBE.bill_data
        if (Array.isArray(data)) {
            let company_name = billBE.company_details.name
            console.log(data)
            const updatedCompanyData = data.map((element: any) => ({
                id: element.id,
                data: [
                    0,
                    element.id,
                    element.user_full_name,
                    company_name,
                    element.address,
                    element.status == "pending" ? statusIcon(<TimerIcon style={{ color: 'grey' }} />, element.id, "active") : statusIcon(<CheckIcon style={{ color: 'green' }} />, element.id, "pending"),
                    element.total_amount
                ],
                icons: [
                    {
                        mode: 'linked',
                        icon: <VisibilityIcon />,
                        color: "#2173FE",
                        link: "/ordersbill/" + element.id
                    }
                ]
            }));
            setBillData([...updatedCompanyData]);
        }
        // if (billData.length === 0)
        //     setNoData(true)
        // else
        //     setNoData(false)
    }, [billBE]);


    async function pageChange(data: { page: number }) {
        setPage(data.page)
        fetchBillCompanyUser()
    }

    return (
        <>
            {
                billData.length > 0 ? (
                    <Tables heads={heads} last_page={last_page} setpage={pageChange} page={page} rows={billData} />
                ) : (
                    noData ? (<h3>No data found!</h3>) : (loadingTable())
                )
            }
        </>
    );
}

export default BillsUsers;
