import React, { ChangeEvent } from 'react'
import { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ImageUpload from '../../components/DragUpload';
import GoogleMap from '../../components/GoogleMap';
import AssignAdmin from '../../components/Users/AssignAdmin';
import DeleteAccount from '../../components/Users/DeleteAccount';
import CompaniesController from '../../controllers/Companies/CompaniesController';
import handleApiResponse from '../../services/ApiResponse';
import CategoriesController from '../../controllers/Categories/CategoriesController';
import CircularProgress from '@mui/material/CircularProgress';
import { AlertDialog } from '../../components/Dialog';
import { useParams } from 'react-router-dom';
import {GetCategories} from '../../components/Categories/GetCategories';
import Alert from '@mui/material/Alert';
import Validation from '../../components/Validation';

interface Company {
  name: string,
  description: string,
  image?: string | File,
  address: string,
  longitude: number,
  latitude: number,
  email: string,
  phone_number: string,
  category: string
}

function Companycreate() {
  var tbnclass = document.getElementsByClassName("topboxnum") as HTMLCollectionOf<HTMLElement>;
  var tbtclass = document.getElementsByClassName("topboxtext") as HTMLCollectionOf<HTMLElement>;
  const [nexti, setNexti] = useState(0);
  const [errors, setError] = useState<string[]>([]);
  const [location, setLocation] = useState('loc 1');
  const { categories } = GetCategories();
  const [companydata, setCompanyData] = useState<Company>({
    name: "",
    description: "",
    image: "" || new File([""], "", { type: 'image/png' }),
    address: "",
    longitude: 1,
    latitude: 1,
    email: "",
    phone_number: "",
    category: ""
  });



  const [loading, setLoading] = useState(false);
  const [opendi, setOpendi] = React.useState(false)
  const [datadia, setDiag] = useState({
    title: 'Category',
    message: 'Category created successfuly',
    mode: 'normal'
  })

  function checkDialogAnswer(data: { clicked: boolean; }) {
    setOpendi(false)
  }



  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCompanyData({
      ...companydata,
      [name]: value
    });
  };


  const changecat = (event: SelectChangeEvent) => {
    setCompanyData({
      ...companydata,
      category: event.target.value
    });
  };


  const changeloc = (event: SelectChangeEvent) => {
    setLocation(event.target.value as string);
  };

  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response
        if (id) {
          response = await CompaniesController.getCompanyById(id)
          const apiResponse = handleApiResponse(response)
          if (apiResponse.success) {
            console.log(apiResponse.data)
            setCompanyData(apiResponse.data)
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    return () => {
    }
  }, []);



  function objectToFormData(obj: Record<string, any>): FormData {
    const formData = new FormData();

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];

        if (Array.isArray(value)) {
          for (let i = 0; i < value.length; i++) {
            formData.append(`${key}[]`, value[i]);
          }
        } else {
          //console.log(key, value)
          formData.append(key, value);
        }
      }
    }

    return formData;
  }

  async function createCompany() {
    setLoading(true)
    try {
      let response
      if (id) {
        if (typeof companydata.image == "string")
          delete companydata.image
        response = await CompaniesController.updateCompany(id, objectToFormData(companydata))
      }

      else
        response = await CompaniesController.createCompany(objectToFormData(companydata))
      const apiResponse = handleApiResponse(response)
      if (apiResponse.success) {
        datadia.title = "Success"
        id ? datadia.message = "Company saved successfully" : datadia.message = "Company added successfully"
        setOpendi(true)
      } else {
        console.error('Failed to fetch user data.');
      }
    } catch (error: any) {
      datadia.title = "Error"
      datadia.message = "Error creating category"
      setOpendi(true)
      console.error('Error fetching user data:', error);
    }
    setLoading(false)
  }


  useEffect(() => {
    setNexti(0)
    tbnclass[0].style.backgroundColor = "#2173FE";
    tbtclass[0].style.color = "#2173FE";
    return () => {
      // console.log('Component is unmounted');
    };
  }, []);


  // function handleNext() {
  //   if (nexti < tbnclass.length)
  //     setNexti(nexti + 1)
  //   if (nexti < tbnclass.length - 1) {
  //     tbnclass[nexti + 1].style.backgroundColor = "#2173FE";
  //     tbtclass[nexti + 1].style.color = "#2173FE";
  //   }
  //   // setOpendi(true)
  // }

  function handleNext() {
    if (nexti < tbnclass.length) {
      setNexti(nexti + 1)
    }
    if (nexti < tbnclass.length - 1) {
      tbnclass[nexti + 1].style.backgroundColor = "#2173FE";
      tbtclass[nexti + 1].style.color = "#2173FE";
    }
    else {
      const { errors, flag } = Validation(
        [
          id ? [companydata.image, "Image is required", 1] : (typeof companydata.image != "string" ? [companydata.image?.name, "Image is required", 1] : ["", "Image is required", 1, false]),
          [companydata.name, "Name is required", 0],
          [companydata.phone_number, "Phone number is required", 0],
          [companydata.address, "Address is required", 0],
          [companydata.category, "Category is required", 0],
          [companydata.description, "Description is required", 0],
          [companydata.email, "Email is required", 0],
        ]
      )
      setError(errors)
      if (flag == 1000)
        createCompany()
      else
        setNexti(flag)
    }
  }
  const handleImageUpload = (files: FileList | null) => {
    if (files) {
      companydata.image = files[0]
    }
  };
  // const [opendi,setOpendi]=React.useState(false)

  return (
    <div className='holder'>
      <Stack direction="row" width={"70%"} margin={"auto"} marginBottom={3}>
        <div className='topboxnum'>1</div>
        <span className='topboxtext'>Shop Info</span>
        <div className='topboxline'></div>
        <div className='topboxnum'>2</div>
        <span className='topboxtext'>Media</span>
      </Stack>


      {nexti === 0 ? (
        <Card className='boxcard'>
          <h2 style={{ textAlign: "center" }}>Company Information</h2>
          <Stack direction="row">
            <Stack direction="column" flex={1}>
              <p>Name</p>
              <TextField
                variant="outlined"
                className='inpp'
                label="Name"
                value={companydata.name}
                name='name'
                onChange={handleChange} />
            </Stack>
          </Stack>
          <Stack direction="column" flex={1}>
            <p>Description</p>
            <TextField
              variant="outlined"
              className='inpp'
              label="Description"
              value={companydata.description}
              name='description'
              onChange={handleChange} />
          </Stack>
          <Stack direction="column" flex={1}>
            <p>Address</p>
            <div style={{
              width: "100%",
              display: "flex",
            }}>
              <TextField
                style={{
                  flex: "1"
                }}
                variant="outlined"
                className='inpp'
                label="Address"
                value={companydata.address}
                name='address'
                onChange={handleChange} />
              <GoogleMap />
            </div>

          </Stack>

          <Stack direction="column" flex={1}>
            <p>Email</p>
            <TextField
              variant="outlined"
              className='inpp'
              label="Email"
              value={companydata.email}
              name='email'
              onChange={handleChange} />
          </Stack>
          <Stack direction="column" flex={1}>
            <p>Phone</p>
            <TextField
              variant="outlined"
              className='inpp'
              label="Phone"
              value={companydata.phone_number}
              name='phone_number'
              onChange={handleChange} />
          </Stack>
          <Stack direction="column" flex={1}>
            <p>Category</p>
            <Select
              className='inpp'
              value={companydata.category}
              label="Category"
              onChange={changecat}
            >
              {categories.map((category, i) => (
                <MenuItem value={category.id}>{category.name_en}</MenuItem>
              ))}
            </Select>
          </Stack>
          <Stack direction="column" flex={0.1}></Stack>
        </Card>
      ) : (
        <ImageUpload onImageUpload={handleImageUpload} imageFile={companydata.image} />
      )}
      {errors.length > 0 && (<Alert severity="error">
        <ul>
          {errors.map((e) => (
            <li>{e}</li>
          ))}
        </ul>
      </Alert>)}
      {loading ? (
        <CircularProgress className='boxbutton' style={{
          marginTop: "10px"
        }} />) : (<Button variant="contained" className='boxbutton' onClick={handleNext} disableElevation style={{
          backgroundColor: "#2173FE",
          marginTop: "10px"
        }}>
          Next
        </Button>)}
      <AlertDialog opendialog={opendi} datadia={datadia} dialogAnswer={checkDialogAnswer} />
    </div>
  )
}

export default Companycreate