import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';


interface Row {
    id: string,
    img?: string,
    data: Array<number | string>,
    icons?: Array<any>,
    dialog?: any
}

interface DataRow {
    id: number;
    d1?: number | string | null;
    d2?: number | string | null;
    d3?: number | string | null;
    d4?: number | string | null;
    d5?: number | string | null;
    d6?: number | string | null;
}

interface TablesProps {
    heads: string[];
    rows: Row[];
    dialogAnswerMultiTab?: (data: {
        clicked: boolean,
        id: string,
        mode: string
    }) => void;
    setpage: (data: {
        page: number
    }) => void;
    last_page?: number,
    page?:number
}

interface DataTablesProps {
    dataheads: GridColDef[];
    datarows: DataRow[];

}


export function DataTables({ dataheads, datarows }: DataTablesProps) {
    return (
        <div style={{ width: '100%', backgroundColor: "white" }}>
            <DataGrid
                className="datagrid"
                rows={datarows}
                columns={dataheads}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                }}
                pageSizeOptions={[5, 10]}
            // checkboxSelection
            />
        </div>
    )
}



export function Tables({ heads, rows, dialogAnswerMultiTab, setpage, last_page,page }: TablesProps) {
    // const [dataDiaglog,setDataDiaglog]= useState({})
    function checkDialogAnswer(data: { clicked: boolean, id: string, mode: string }) {
        if (dialogAnswerMultiTab) {
            dialogAnswerMultiTab(data)
        }
    }

    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setpage({ page: value });
    };
    // const [dialog, setDialog] = React.useState<DialogState>({});

    // const [datadia, setDatadia] = React.useState({
    //     title: "Delete",
    //     message: "test",
    //     mode: "delete"
    // });


    // React.useEffect(() => {
    //     const initialDialogState = Object.fromEntries(rows.map((row) => [row.id, false]));
    //     setDialog(initialDialogState);
    // }, [rows]);


    // function handleClick(i: number, mode: string): void {
    //     if (mode === "delete") {
    //         datadia.message = "Do you want to delete " + i
    //         setDialog((prevDialog) => ({ ...prevDialog, [i]: true }));
    //     }
    // }


    return (
        <div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {heads.map((head, i) => (
                                i === 0 ? (
                                    <TableCell key={i} align="left" style={{ fontWeight: "bold" }}>
                                        {head}
                                    </TableCell>
                                ) :
                                    <TableCell key={i} align="center" style={{ fontWeight: "bold" }}>
                                        {head}
                                    </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, j) => (
                            <TableRow key={row.id}>
                                {row.data.map((data, i) => (
                                    i === 0 ? (null) :
                                        i === 1 ? (
                                            <TableCell key={i} align="left">
                                                <div className='tableimg'>
                                                    {row.img && <img src={row.img} alt="" />}
                                                    <p>{data}</p>
                                                </div>
                                            </TableCell>
                                        ) :
                                            (
                                                <TableCell key={i} align="center">{data}</TableCell>
                                            )
                                ))}
                                <TableCell align="center">
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center"
                                    }}>
                                        {row.icons?.map((icon, i) => (
                                            icon.mode === "linked" ? (
                                                <Link
                                                    key={i}
                                                    to={`../${icon.link}`}
                                                    style={{
                                                        color: icon.color,
                                                        cursor: "pointer",
                                                    }}
                                                >{icon.icon}
                                                </Link>
                                            ) :
                                                (
                                                    icon.item
                                                    // <DeleteAccount icon={
                                                    //     {
                                                    //         color: icon.color,
                                                    //         icon: icon.icon
                                                    //     }
                                                    // } id={row.id} />



                                                    // <span
                                                    //     key={i}
                                                    //     style={{
                                                    //         color: icon.color,
                                                    //         cursor: "pointer",
                                                    //     }}
                                                    // // onClick={() => handleClick(row.id, icon.mode)}
                                                    // >
                                                    //     {/* {icon.icon} */}
                                                    //     <AlertDialogMulti
                                                    //         key={row.id}
                                                    //         icon={icon.icon}
                                                    //         datadia={
                                                    //             {
                                                    //                 title: "Delete",
                                                    //                 message: "Do you want to delete " + row.id,
                                                    //                 mode: "delete",
                                                    //                 buttons: {
                                                    //                     text: "Delete",
                                                    //                     color: "red"
                                                    //                 }
                                                    //             }
                                                    //         }
                                                    //         dataforaction={
                                                    //             {
                                                    //                 id: row.id,
                                                    //                 url: icon.link
                                                    //             }
                                                    //         }
                                                    //         dialogAnswerMulti={checkDialogAnswer}
                                                    //     />
                                                    // </span>
                                                )
                                        ))}
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Stack style={{ float: "right", marginTop: "10px" }} spacing={2}>
                <Pagination count={last_page} page={page} variant="outlined" shape="rounded" onChange={handleChangePage} />
            </Stack>
        </div >
    );
}
