import axios from 'axios';
import api from '../../services/apiMain';

const headers = { "Content-Type": "multipart/form-data" }
const CompaniesApiController = {
    getAllCategories: () => {
        return api.get('/api/category');
    },
    getCategoryById: (id: string | number) => {
        return api.get('/api/category/'+id);
    },
    getSubCategory: (id: string | number) => {
        return api.get('/api/sub_category/' + id);
    },
    getSearchCategory: (id: string | number) => {
        return api.get('/api/search/category/' + id);
    },
    createCategory: (data: FormData) => {
        return api.post('/api/category', data, { headers });
    },
    updateCategory: (id: string, data: FormData) => {
        return api.post('/api/category/' + id, data, { headers });
    },
    deleteCategory: (id: string | number) => {
        return api.delete('/api/category/' + id);
    },
    createSubCategory: (data: FormData) => {
        return api.post('/api/sub_category', data, { headers });
    },
    updateSubCategory: (id: string, data: FormData) => {
        return api.post('/api/sub_category/' + id, data, { headers });
    },
    deleteSubCategory: (id: string) => {
        return api.delete('/api/category/' + id);
    }
};

export default CompaniesApiController;
