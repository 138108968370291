import React, { ChangeEvent } from 'react'
import { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import { Alert, CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import ColorsController from '../../../controllers/Settings/Colors/ColorsController';
import { AlertDialog } from '../../../components/Dialog';
import handleApiResponse from '../../../services/ApiResponse';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Validation from '../../../components/Validation';


interface ColorData {
  name_en: string,
  name_ar: string,
  code: string
}

function ColorCreate() {
  const [colordata, setColordata] = useState<ColorData>({
    name_en: "",
    name_ar: "",
    code: ""
  });

  const [errors, setError] = useState<string[]>([]);

  const [loading, setLoading] = useState(false);
  const [opendi, setOpendi] = React.useState(false)
  const [datadia, setDiag] = useState({
    title: 'Color',
    message: 'Color created successfuly',
    mode: 'normal'
  })

  function checkDialogAnswer(data: { clicked: boolean; }) {
    setOpendi(false)
  }


  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setColordata({
      ...colordata,
      [name]: value
    });
  };


  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response
        if (id) {
          response = await ColorsController.getColorById(id)
          const apiResponse = handleApiResponse(response)
          if (apiResponse.success) {
            setColordata(apiResponse.data)
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    return () => {
    }
  }, []);

  async function createColor() {
    const { errors, flag } = Validation(
      [
        [colordata.name_en, "Color name is required", 0],
        [colordata.name_ar, "Color name in arabic is required", 0],
        [colordata.code, "Color code is required", 0],
      ]
    )
    setError(errors)
    if (flag == 1000) {
      setLoading(true)
      try {
        console.log(colordata)
        let response
        if (id)
          response = await ColorsController.updateColor(id, colordata)
        else
          response = await ColorsController.createColor(colordata)
        const apiResponse = handleApiResponse(response)
        if (apiResponse.success) {
          datadia.title = "Success"
          id ? datadia.message = "Color saved successfully" : datadia.message = "Color added successfully"
          setOpendi(true)
        } else {
          console.error('Failed to fetch user data.');
        }
      } catch (error: any) {
        datadia.title = "Error"
        datadia.message = "Error creating color"
        setOpendi(true)
        console.error('Error fetching user data:', error);
      }
      setLoading(false)
    }

  }

  function handleSave() {
    createColor()
  }

  return (
    <div className='holder'>
      <Card className='boxcard'>
        <h2 style={{ textAlign: "center" }}>Color Information</h2>
        <Stack direction="row">
          <Stack direction="column" flex={1}>
            <p>Name</p>
            <TextField
              label="Name"
              variant="outlined"
              className='inpp'
              value={colordata.name_en}
              name='name_en'
              onChange={handleChange} />
          </Stack>
          <Stack direction="column" flex={1} style={{ marginLeft: "10px" }}>
            <p>Arabic Name</p>
            <TextField
              label="Arabic Name"
              variant="outlined"
              className='inpp'
              value={colordata.name_ar}
              name='name_ar'
              onChange={handleChange} />
          </Stack>
        </Stack>
        <Stack direction="column" flex={1}>
          <p>Color Code</p>
          <Stack direction="column" flex={1}>
            <TextField
              type='color'
              label="Name"
              variant="outlined"
              className='inpp'
              value={colordata.code}
              name='code'
              onInput={handleChange} />
          </Stack>
        </Stack>
      </Card>
      {errors.length > 0 && (<Alert severity="error">
        <ul>
          {errors.map((e) => (
            <li>{e}</li>
          ))}
        </ul>
      </Alert>)}
      {loading ? (
        <CircularProgress className='boxbutton' style={{
          marginTop: "10px"
        }} />) : (<Button variant="contained" className='boxbutton' onClick={handleSave} disableElevation style={{
          backgroundColor: "#2173FE",
          marginTop: "10px"
        }}>
          Next
        </Button>)}
      <AlertDialog opendialog={opendi} datadia={datadia} dialogAnswer={checkDialogAnswer} />
    </div>
  )
}

export default ColorCreate