import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface DialogPropsMulti {
    icon: any,
    dataforaction: {
        id: string,
        url: string
    }
    datadia: {
        title: string;
        message: string;
        mode: string;
        url?: string;
        body?: any;
        buttons?: any
    };
    dialogAnswerMulti?: (data: {
        clicked: boolean,
        id: string,
        mode: string
    }) => void;
}


interface DialogProps {
    opendialog: boolean,
    datadia: {
        [x: string]: any;
        title: string;
        message: string;
        mode: string;
    };
    dialogAnswer?: (data: {
        clicked: boolean
    }) => void;
}

export const AlertDialogMulti: React.FC<DialogPropsMulti> = ({ datadia, icon, dataforaction, dialogAnswerMulti }) => {
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleAction = () => {
        if (datadia.mode === "delete") {
            // console.log("deleted " + dataforaction.id)
            console.log("link " + dataforaction.url + dataforaction.id)
            if (dialogAnswerMulti) {
                dialogAnswerMulti({ clicked: true, id: dataforaction.id, mode: datadia.mode })
            }
        }
        setOpen(false);
    };
    return (
        <div>
            <span onClick={handleClickOpen}>
                {/* <Button variant="outlined">
                    Open alert dialog
                </Button> */}
                {icon}
            </span>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {datadia.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {datadia.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>close</Button>
                    <Button onClick={handleAction} style=
                        {{ color: `${datadia.buttons.color}` }} > {datadia.buttons.text}</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}


export const AlertDialog: React.FC<DialogProps> = ({ datadia, opendialog, dialogAnswer }) => {

    const [openDialog, setOpenDialog] = React.useState(opendialog);

    React.useEffect(() => {
        setOpenDialog(opendialog);
    }, [opendialog]);

    const handleClose = () => {
        if (dialogAnswer)
            dialogAnswer({ clicked: false })
        // setOpenDialog(false);
        // if (datadia.url)
        //     window.location.href = datadia.url;
        // else
        //     window.location.reload();
    };
    const handleAction = () => {
        if (datadia.mode === "delete" || datadia.mode === "admin") {
            if (dialogAnswer)
                dialogAnswer({ clicked: true })
        }
    };

    return (
        <div>
            <Dialog
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {datadia.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {datadia.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>close</Button>
                    {datadia.mode === "delete" ? (
                        <Button onClick={handleAction} style=
                            {{ color: "red" }} >Delete</Button>
                    ) : (
                        datadia.mode === "admin" ? (<Button onClick={handleAction} style=
                            {{ color: "green" }} >Agree</Button>) : (null)
                    )

                    }
                </DialogActions>
            </Dialog>
        </div>
    );
}
