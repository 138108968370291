import { useEffect, useState } from 'react';
import { Tables } from '../../components/Tables';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteCompanies from '../../components/Companies/DeleteCompanies';
import VerifiedIcon from '@mui/icons-material/Verified';
import VerifyCompany from '../../components/Companies/VerifyCompany';
import { GetCategories } from '../../components/Categories/GetCategories';
import GetCompanies from '../../components/Companies/GetCompanies';
import { useParams } from 'react-router-dom';
import { Stack, Skeleton } from '@mui/material';
import { loadingTable } from '../../components/loadingSkeleton';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';


interface Company {
  id: string;
  data: Array<number | string>,
}

function Companies() {
  const heads = ['Name', 'Created', 'Description', 'Address', 'Email', 'Phone', 'Verification', 'Category', 'Action'];
  const [companyData, setCompanyData] = useState<Company[]>([]);
  const { id } = useParams()
  const { group } = useParams()
  // if (localStorage.getItem("company_id"))
  //   window.location.href = '/companymanage/' + localStorage.getItem("company_id")
  const { companies, compIndex } = GetCompanies(id ? id : null, id ? true : false, group ? group : null);
  console.log(companies)
  const [loaded, setLoad] = useState(false);
  const [page, setPage] = useState(1);
  const [last_page, setLastPage] = useState(1);

  useEffect(() => {
    if (companyData.length === 0) {
      const updatedCompanyData = companies.map((element: any) => ({
        id: element.uuid,
        img: "https://api.kolshein.online/storage/" + element.image,
        data: [
          0,
          <span style={{ cursor: "pointer" }} onClick={() => { window.location.href = "../../../categories/" + (element.category + "," + element.uuid) }}>{element.name}</span>,
          element.created_at,
          element.description,
          element.address,
          element.email,
          element.phone_number,
          <VerifyCompany verified={element.verification_status} icon={
            {
              color: "grey",
              icon: <VerifiedIcon />
            }
          } id={element.uuid} />,
          // catsIndex.get(element.category)?.name_en,
          localStorage.getItem('language') == "1" ? element.category_name_en : element.category_name_ar,
        ],
        icons: [
          {
            mode: 'linked',
            icon: <EditIcon />,
            color: "#2173FE",
            link: "/companymanage/" + element.uuid
          },
          {
            mode: 'linked',
            icon: <ReceiptLongIcon />,
            color: "#2173FE",
            link: "/bills/company/" + element.uuid
          },
          {
            mode: 'delete',
            item: <DeleteCompanies icon={
              {
                color: "red",
                icon: <DeleteIcon />
              }
            } id={element.uuid} />
          },
        ]
      }));
      setCompanyData([...updatedCompanyData]);
    }
  }, [companies]);

  function checkDialogAnswer(data: { clicked: boolean, id: string, mode: string }) {
    // console.log(data.clicked)
    // console.log(data.id)
    // console.log(data.mode)
  }

  function pageChange(data: { page: number }) {

  }

  return (
    <>
      {companyData.length > 0 ? (
        <Tables heads={heads} rows={companyData} last_page={last_page} setpage={pageChange} page={page} dialogAnswerMultiTab={checkDialogAnswer} />
      ) : (
        loadingTable()
      )}
    </>
  );
}

export default Companies;
