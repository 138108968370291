import axios from "axios";
import api from "../../services/apiMain";
const headers = { "Content-Type": "multipart/form-data" }

const NotificationApiService={
    sendNotification: (data: FormData) => {
        return api.post('/api/notification', data, { headers });
    }
}

export  default NotificationApiService;