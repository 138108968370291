import React, { useEffect, useState } from 'react'
import CategoriesController from '../../controllers/Categories/CategoriesController';
import handleApiResponse from '../../services/ApiResponse';
import ItemsController from '../../controllers/Items/ItemsController';

interface Categories {
  id: number,
  name_en: string,
  name_ar: string,
  image: string,
  parent: null
}

export const GetCategoriesP = async (id?: number | string | null, sub?: boolean, group?: string | null, next?: (cat: any) => void) => {
  try {
    let response;
    if (sub) {
      if (id) {
        response = await ItemsController.getCompanyCategories(id);
      }
    }
    else {
      if (group == "search") {
        if (id)
          response = await CategoriesController.getSearchCategory(id);
      }
      else if (id) response = await CategoriesController.getSubCategory(id);
      else response = await CategoriesController.getAllCategories();
    }
    const apiResponse = handleApiResponse(response);
    if (apiResponse.success) {
      const categories = apiResponse.data;
      const catsIndex: Map<number, Categories> = new Map();
      categories.forEach((element: any) => {
        const ob: Categories = {
          id: element["id"],
          name_en: element["name_en"],
          name_ar: element["name_ar"],
          image: element["image"],
          parent: element["parent"]
        };
        catsIndex.set(element["id"], ob);
      });
      console.log("test")
      if (next) {
        if (categories.length > 0)
          next(categories);
        else
          next(false)
      }

      return categories;
    } else {
      if (next)
        next([]);
      return null;
    }
  } catch (error) {
    if (next)
      next([]);
    return null;
  }
};


export const GetCategories = (id?: number | string | null, sub?: boolean) => {
  const [catsIndex, setCatsIndex] = useState<Map<number, Categories>>(new Map());
  let [categories, setCategories] = useState<Categories[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response
        if (sub) {
          if (id) {
            response = await ItemsController.getCompanyCategories(id)
          }
        }
        else {
          if (id)
            response = await CategoriesController.getSubCategory(id)
          else
            response = await CategoriesController.getAllCategories()
        }

        const apiResponse = handleApiResponse(response)
        if (apiResponse.success) {
          categories = apiResponse.data;
          setCategories(apiResponse.data)
          apiResponse.data.forEach((element: any) => {
            let ob: Categories = {
              id: element["id"],
              name_en: element["name_en"],
              name_ar: element["name_ar"],
              image: element["image"],
              parent: element["parent"]
            };
            catsIndex.set(element["id"], ob);
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [catsIndex]);
  return { categories, catsIndex };
}
