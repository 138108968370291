import React, { useEffect, useState } from 'react'
import handleApiResponse from '../../services/ApiResponse';
import ItemsController from '../../controllers/Items/ItemsController';

interface Item {
  id: number | string,
  item_code: string,
  name: string,
  name_ar: string,
  description: string,
  description_ar: string,
  image?: string | File
  category_id: string,
  company_id: string,
  size: Array<string>,
  size_ar: Array<string>,
  basic_color: Map<string, string>,
  original_price: number,
  sold: boolean,
  sold_price: number,
  in_stock: boolean,
  stock_quantity: number,
  ingredients: Array<string>,
  ingredients_ar: Array<string>,
  extra: Array<Map<string, number>>,
  extra_ar: Array<Map<string, number>>,
  combo: boolean,
  combo_price: number,
}
export const GetItems = async (page?: number, id?: number | string | null, by?: string, next?: (items: Item[] | boolean, last_page: number) => void) => {
  try {
    console.log(page)
    let response;
    if (by === "categorycompany") {
      let idcomp = typeof id == "string" ? id!.split(",") : " , "
      response = id ? await ItemsController.getItemsCategory(id, page!) : null;
      // response = id ? await ItemsController.getItemsCompanyCategory(idcomp[1], idcomp[0], page!) : null;
    }
    else if (by === "category") {
      response = id ? await ItemsController.getItemsCategory(id, page!) : null;
    }
    else if (by === "company") {
      response = id ? await ItemsController.getItemsCompany(id, page!) : await ItemsController.getAllItems(page!);
    }
    else if (by === "search") {
      response = id ? await ItemsController.getItemsSearch(id, page!) : null;
    }
    else if (id) {
      response = await ItemsController.getItemById(id);
    }
    else {
      response = await ItemsController.getAllItems(page!);
    }
    const apiResponse = handleApiResponse(response);
    // console.log(apiResponse)
    if (apiResponse.success) {
      const itemsData = apiResponse.data.data.map((element: any) => {
        return {
          id: element["id"],
          item_code: element["item_code"],
          name: element["name"],
          name_ar: element["name_ar"],
          description: element["description"],
          description_ar: element["description_ar"],
          image: element["image"],
          category_id: element["category_id"],
          company_id: element["company_id"],
          size: element["size"],
          size_ar: element["size_ar"],
          basic_color: element["basic_color"],
          original_price: element["original_price"],
          sold: element["sold"],
          sold_price: element["sold_price"],
          in_stock: element["in_stock"],
          stock_quantity: element["stock_quantity"],
          ingredients: element["ingredients"],
          ingredients_ar: element["ingredients_ar"],
          extra: element["extra"],
          extra_ar: element["extra_ar"],
          combo: element["combo"],
          combo_price: element["combo_price"],
        };
      });
      if (next) {
        if (itemsData.length > 0) {
          next(itemsData, apiResponse.data.last_page);
        } else {
          next(false, 0);
        }
      }
      // console.log(itemsData)
      return itemsData;
    } else {
      if (next) {
        next(false, 0);
      }
      return null;
    }
  } catch (error) {
    if (next) {
      next(false, 0);
    }
    return null;
  }
};