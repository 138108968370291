import UserModel from '../../models/Users/UserModel'
import ItemApiService from './ItemsApiController';
import ApiService from './ItemsApiController';

class ItemsController {
    static createItem(data: FormData) {
        return ItemApiService.createItem(data)
    }
    static updateItem(id: string | number, data: FormData) {
        return ItemApiService.updateItem(id, data);
    }
    static createItemColor(data: FormData) {
        return ItemApiService.createItemColor(data);
    }
    static createItemSize(data: FormData) {
        return ItemApiService.createItemSize(data);
    }
    static deleteItem(id: string | number) {
        return ItemApiService.deleteItem(id)
    }
    static updateItemColor(id: string | number, data: FormData) {
        return ItemApiService.updateItemColor(id, data);
    }
    static deleteItemColor(id: string | number) {
        return ItemApiService.deleteItemColor(id);
    }
    static updateItemSize(id: string | number, data: FormData) {
        return ItemApiService.updateItemSize(id, data);
    }
    static deleteItemSize(id: string | number) {
        return ItemApiService.deleteItemSize(id);
    }
    static getItemById(id: string | number) {
        return ItemApiService.getItemById(id);
    }
    static getItemColor(id: string | number) {
        return ItemApiService.getItemColor(id);
    }
    static getItemSize(id: string | number) {
        return ItemApiService.getItemSize(id);
    }
    static getAllItems(page:number) {
        return ItemApiService.getAllItems(page);
    }
    static getItemsCompany(id: string | number,page:number) {
        return ItemApiService.getItemsCompany(id,page);
    }
    static getItemsCategory(id: string | number,page:number) {
        return ItemApiService.getItemsCategory(id,page);
    }
    static getItemsSearch(id: string | number,page:number) {
        return ItemApiService.getItemsSearch(id,page);
    }
    static getItemsCompanyCategory(company_id: string | number, category_id: string | number, page: number) {
        return ItemApiService.getItemsCompanyCategory(company_id, category_id,page);
    }
    static getCompanyCategories(company_id: string | number) {
        return ItemApiService.getCompanyCategories(company_id);
    }
}

export default ItemsController;
