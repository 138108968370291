import React, { useRef, useState } from 'react';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import BackupIcon from '@mui/icons-material/Backup';
import CloseIcon from '@mui/icons-material/Close';

interface ImageUploadProps {
  onImageUpload: (files: FileList | null) => void;
  imageFile?: string | File
}

const ImageUpload: React.FC<ImageUploadProps> = ({ onImageUpload, imageFile }) => {
  const cardRef = useRef<HTMLDivElement | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [imagePreviews, setImagePreviews] = useState<{ file: File, preview: string }[]>([]);


  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    onImageUpload(files);
    previewImages(files);
  };

  const handleBrowseClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    onImageUpload(files);
    previewImages(files);
  };

  const previewImages = (files: FileList | null) => {
    if (!files) return;

    const previews: { file: File, preview: string }[] = [...imagePreviews];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();

      reader.onload = (e) => {
        if (e.target && e.target.result) {
          previews.push({ file, preview: e.target.result as string });
          setImagePreviews([...previews]);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = (index: number) => {
    const updatedPreviews = [...imagePreviews];
    updatedPreviews.splice(index, 1);
    setImagePreviews(updatedPreviews);
  };

  return (
    <Card style={{
      padding: "30px",
      borderRadius: "3px"
    }}>
      {typeof imageFile=="string" &&
        <img width={100} height={100} style={{
          borderRadius: "50%",
          display: "block",
          margin: "auto",
          marginBottom: "10px",
          cursor: "pointer"
        }} src={"https://api.kolshein.online/storage/" + imageFile} alt="" />}
      <Card
        className="grey lighten-2"
        style={{
          borderStyle: 'dashed',
          borderRadius: '10px',
          borderColor: '#2173FE',
          borderWidth: '2px',
          cursor: 'pointer',
        }}
        ref={cardRef}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={handleBrowseClick}
      >
        <Stack direction="column" className="d-flex flex-column" justifyContent="center" alignItems="center">
          <BackupIcon className="upldicon" style={{ fontSize: '60px' }} />
          <p>
            Drop your image here or <label className="browse-link">Browse</label>
          </p>
          <input
            id="file-input"
            type="file"
            multiple
            style={{
              display: "none"
            }}
            ref={fileInputRef}
            onChange={handleFileInputChange}
          />
          <p className="text-black-50">
            Support: JPG, JPEG, PNG
          </p>
        </Stack>
      </Card>
      {
        imagePreviews.length ? (
          <div>
            {imagePreviews.map((previewObj, index) => (
              <div style={{
                margin: "auto",
                marginTop: "10px",
                marginBottom: "10px",
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '3px',
              }}>
                <Stack direction="row" width={"100%"} alignItems="center" display={"flex"} justifyContent={"space-between"} key={`${previewObj.file.name}-${Date.now()}`}>
                  <Stack key={index} direction="row" alignItems="center">
                    <img src={previewObj.preview} alt={`Preview ${index}`} width="50" height="50" style={{
                      borderRadius: "3px",
                      marginRight: "10px"
                    }} />
                    <span>{previewObj.file.name}</span>
                  </Stack>
                  <CloseIcon
                    onClick={() => handleRemoveImage(index)}
                    style={{
                      color: "red",
                      cursor: "pointer"
                    }} />
                </Stack>
              </div>
            ))}
          </div>
        ) : null
      }
    </Card>
  );
};

export default ImageUpload;
