import React, { useEffect, useState } from 'react';
import apiService from '../../services/apiMain';
import { Tables } from '../../components/Tables';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteAccount from '../../components/Users/DeleteAccount';
import AssignAdmin from '../../components/Users/AssignAdmin';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import UserController from '../../controllers/Users/UserController';
import handleApiResponse from '../../services/ApiResponse';
import { useParams } from 'react-router-dom';


interface User {
  id: string;
  data: Array<number | string>,
}

function convertDate(d: string) {
  const date = new Date(d);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const formattedTimestamp = `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year} ${hour}:${minute}`;
  return formattedTimestamp
}


function UserList() {
  // const heads = ['Full name', 'Created', 'DOB', 'Email', 'Gender', 'Phone', 'User ID', 'Action'];
  const heads = ['User ID', 'Full name', 'Created', 'Email', 'Phone', 'Action'];
  const [usersData, setUserData] = useState<User[]>([]);
  const [page, setPage] = useState(1);
  const [last_page, setLastPage] = useState(1);

  const { id } = useParams()
  const { group } = useParams()

  const fetchData = async () => {
    try {
      let response;
      if (group) {
        if (id)
          response = await UserController.getSearchUser(id, page)
      }
      else
        response = await UserController.getAllUser(page)
      const apiResponse = handleApiResponse(response)
      if (apiResponse.success) {
        if (usersData.length === 0) {
          setLastPage(apiResponse.data.last_page)
          const updatedUserData = apiResponse.data.data.map((element: any) => ({
            id: element.uuid,
            data: [
              0,
              element.uuid,
              element.full_name,
              convertDate(element.created_at),
              // element.date_of_birth,
              element.email,
              // element.gender,
              element.phone_number,
            ],
            icons: [
              {
                mode: 'linked',
                icon: <EditIcon />,
                color: "#2173FE",
                link: "profile/" + element.uuid
              },
              {
                mode: 'delete',
                item: <DeleteAccount icon={
                  {
                    color: "red",
                    icon: <DeleteIcon />
                  }
                } id={element.uuid} />
              },
              {
                mode: 'delete',
                item: <AssignAdmin admin={element.super_admin} icon={
                  {
                    color: "grey",
                    icon: <AdminPanelSettingsIcon />
                  }
                } id={element.uuid} />
              }
            ]
          }));
          setUserData([...updatedUserData]);
        }
      } else {
        console.error('Failed to fetch user data.');
      }
    } catch (error: any) {
      if (error.response.status == 403)
        window.location.href = '/profile'
      console.error('Error fetching user data:', error);
    }
  };


  useEffect(() => {
    if (usersData.length == 0) {
      fetchData();
      console.log(usersData)
    }

  }, [usersData]);

  function checkDialogAnswer(data: { clicked: boolean, id: string, mode: string }) {
    console.log(data.clicked)
    console.log(data.id)
    console.log(data.mode)
  }

  async function pageChange(data: { page: number }) {
    setPage(data.page)
    setUserData([])
    fetchData();
    console.log(usersData)
  }

  return (
    <>
      {usersData.length > 0 ? (
        <Tables heads={heads} rows={usersData} last_page={last_page} setpage={pageChange} page={page} dialogAnswerMultiTab={checkDialogAnswer} />
      ) : null}
    </>
  );
}

export default UserList;
