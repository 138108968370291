import React, { ChangeEvent, useEffect } from 'react'
import { useState } from 'react';
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import ImageUpload from '../../components/DragUpload';
import { CircularProgress } from '@mui/material';
import { AlertDialog } from '../../components/Dialog';
import { useParams } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import ItemsController from '../../controllers/Items/ItemsController';
import handleApiResponse from '../../services/ApiResponse';
import DeleteItemColor from '../../components/Items/DeleteItemColor';


interface obj {
  id?: number,
  item_id: number,
  color: string,
  price: number,
  company_id: string,
  image?: string | File,
}


function ItemColor() {
  const [errors, setError] = useState<string[]>([]);
  const [itemcolordata, setItemColorData] = useState<obj>({
    item_id: 0,
    color: "",
    price: 0,
    image: "" || new File([""], "", { type: 'image/png' }),
    company_id: localStorage.getItem("company_id") + ""
  });

  const [loading, setLoading] = useState(false);
  const [opendi, setOpendi] = React.useState(false)
  const [datadia, setDiag] = useState({
    title: 'Item',
    message: 'Item created successfuly',
    mode: 'normal'
  })

  function checkDialogAnswer(data: { clicked: boolean; }) {
    setOpendi(false)
  }

  function objectToFormData(obj: Record<string, any>): FormData {
    const formData = new FormData();

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];

        if (Array.isArray(value)) {
          for (let i = 0; i < value.length; i++) {
            formData.append(`${key}[]`, value[i]);
          }
        } else {
          console.log(key, value)
          formData.append(key, value);
        }
      }
    }
    return formData;
  }

  /* const handleChangeNum = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const numericValue = parseFloat(value);

    if (!isNaN(numericValue)) {
      setItemColorData({
        ...itemcolordata,
        [name]: numericValue
      });
    } else {
      alert("please insert a number in the ")
    }
  }; */
  const handleChangeNum = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setItemColorData({
      ...itemcolordata,
      [name]: value
    });
  };

  const { id } = useParams();
  const { cid } = useParams();
  const { color } = useParams();

  if (id && color) {
    itemcolordata.color = "#" + color
    itemcolordata.item_id = parseInt(id)
  }

  async function getItemColor() {
    try {
      let response
      if (id) {
        response = await ItemsController.getItemColor(id)
        const apiResponse = handleApiResponse(response)
        if (apiResponse.success) {
          apiResponse.data.map((e: obj) => {
            if (e.id == cid)
              setItemColorData({
                ...e,
                company_id: localStorage.getItem("company_id") + ""
              })

          })
        } else {
          console.error('Failed to fetch user data.');
        }
      }
    } catch (error: any) {
      console.error('Error fetching user data:', error);
    }
  }

  useEffect(() => {
    if (cid) {
      getItemColor()
    }

  }, [])



  async function createColor() {
    setLoading(true)
    try {
      let response
      if (cid) {
        if (typeof itemcolordata.image == "string")
          delete itemcolordata.image
        response = await ItemsController.updateItemColor(cid, objectToFormData(itemcolordata))
      }
      else
        response = await ItemsController.createItemColor(objectToFormData(itemcolordata))
      const apiResponse = handleApiResponse(response)
      if (apiResponse.success) {
        datadia.title = "Success"
        id ? datadia.message = "Item Color Saved successfully" : datadia.message = "Item added successfully"
        setOpendi(true)
      } else {
        console.error('Failed to fetch user data.');
      }
    } catch (error: any) {
      datadia.title = "Error"
      datadia.message = "Error creating item"
      setOpendi(true)
      console.error('Error fetching user data:', error);
    }
    setLoading(false)
  }

  const handleImageUpload = (files: FileList | null) => {
    if (files) {
      itemcolordata.image = files[0]
    }
  };

  return (
    <div className='holder'>
      <Card className='boxcard'>
        <h2 style={{ textAlign: "center" }}>Item Color <span style={{ color: itemcolordata.color, fontSize: "3 0px" }}>●</span></h2>
        <Stack direction="row">
          <Stack direction="column" flex={1}>
            <p>Price</p>
            <TextField
              label="Name"
              variant="outlined"
              className='inpp'
              value={itemcolordata.price}
              name='price'
              onChange={handleChangeNum} />
          </Stack>
        </Stack>
        <ImageUpload onImageUpload={handleImageUpload} imageFile={itemcolordata.image} />
        {cid && (
          <DeleteItemColor text='Delete Item Color?' id={cid} />
        )}
      </Card>
      {errors.length > 0 && (<Alert severity="error">
        <ul>
          {errors.map((e) => (
            <li>{e}</li>
          ))}
        </ul>
      </Alert>)}
      {loading ? (
        <CircularProgress className='boxbutton' style={{
          marginTop: "10px"
        }} />) : (<Button variant="contained" className='boxbutton' onClick={createColor} disableElevation style={{
          backgroundColor: "#2173FE",
          marginTop: "10px"
        }}>
          Save
        </Button>)}
      <AlertDialog opendialog={opendi} datadia={datadia} dialogAnswer={checkDialogAnswer} />
    </div>
  )
}

export default ItemColor