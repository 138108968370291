import { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteCategory from '../../components/Categories/DeleteCategory';
import { GetCategoriesP } from '../../components/Categories/GetCategories';
import { useParams } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/material';
import { loadingGrid } from '../../components/loadingSkeleton';

interface Categories {
  id: number,
  name_en: string,
  name_ar: string,
  image: string,
  parent: null
}

function Categories() {
  const [categories, setCategories] = useState<Categories[]>()
  const { id } = useParams()
  const { group } = useParams()

  useEffect(() => {
    async function fetch() {
      if (group) {
        setCategories(await GetCategoriesP(id, false, group))
      }
      else if (id) {
        setCategories(await GetCategoriesP(id.split(",")[0], false, null, (cat: any) => {
          if (!cat) {
            window.location.href = "../../../itemslist/categorycompany/" + id;
          }
        }))
      }
      else
        setCategories(await GetCategoriesP())
    }

    if (categories?.length !== 0)
      fetch();
    else
      window.location.href = "../../../itemslist/categorycompany/" + id;
  }, []);

  return (
    <div className='holder'>
      {
        categories ? (
          <Grid container rowSpacing={4} columnSpacing={{ xs: 6, sm: 7, md: 4 }}>
            {categories.length > 0 ? (
              categories?.map((catobject) => (
                <Grid item xs={4}>
                  <Card variant="outlined"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      padding: "10px"
                    }}
                  >
                    <div>
                      <br />
                      <img width={100} height={100} style={{
                        borderRadius: "50%",
                        cursor: "pointer"
                      }} src={"https://api.kolshein.online/storage/" + catobject["image"]} alt=""
                        onClick={() => { window.location.href = "../../../categories/" + catobject.id }} />
                      <h4 style={{ color: "#2173FE" }}>{
                        localStorage.getItem('language') == "1" ? catobject.name_en : catobject.name_ar
                      }</h4>

                      <Stack direction="row" flex={1} justifyContent={"center"}>
                        <EditIcon style={{ color: "#2173FE", cursor: "pointer" }} onClick={() => { window.location.href = "../../../categorymanage/" + catobject.id }} />
                        <DeleteCategory icon={
                          {
                            color: "red",
                            icon: <DeleteIcon />
                          }
                        } id={catobject.id} />
                      </Stack>
                    </div>
                  </Card>
                </Grid>
              ))
            ) : (
              loadingGrid()
            )}
          </Grid>
        ) : (
          loadingGrid()
        )
      }
    </div>
  )
}

export default Categories;