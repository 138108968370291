import React, { ChangeEvent } from 'react'
import { useState } from 'react';
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Alert, CircularProgress } from '@mui/material';
import PasswordStrengthChecker from '../../components/Users/PasswordCheck';
import { AlertDialog } from '../../components/Dialog';
import OTP from '../../components/Users/OTP';
import UserController from '../../controllers/Users/UserController';
import handleApiResponse from '../../services/ApiResponse';
import Validation from '../../components/Validation';

function UserCreate() {
    const [userData, setUserData] = useState(
        {
            full_name: "",
            phone_number: "",
            email: "",
            password: "",
            confirm_password: "",
            date_of_birth: "",
            gender: "male",
            language: 1
        });


    const [errors, setError] = useState<string[]>([]);

    const [verify, setVerify] = useState(false);
    const [verified, setVerified] = useState(false);
    const [loading, setLoading] = useState(false);
    const [opendi, setOpendi] = React.useState(false)
    const [datadia, setDiag] = useState({
        title: 'User',
        message: 'user created successfuly',
        mode: 'normal',
        url: '/users'
    })

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setUserData({
            ...userData,
            [name]: value
        });
    };

    const checkOTPVerification = (data: { verified: boolean, otp: string, phone: string }) => {
        setVerified(data.verified);
    };

    const changegender = (event: SelectChangeEvent) => {
        setUserData({
            ...userData,
            gender: event.target.value
        });
    };


    async function handleSave() {

        const { errors, flag } = Validation(
            [
                [userData.full_name, "Full name is required", 0],
                [userData.email, "Email is required", 0],
                [userData.phone_number, "Phone number is required", 0],
                [userData.password, "Password is required", 0],
                [userData.confirm_password, "Confirm password is required", 0],
                [userData.gender, "Gender is required", 0],
                [userData.date_of_birth, "DOB is required", 0],
            ]
        )
        setError(errors)
        if (flag == 1000) {
            setLoading(true)
            try {
                const response = await UserController.createUser(userData);
                const apiResponse = handleApiResponse(response)
                if (apiResponse.success) {
                    datadia.title = "Success"
                    datadia.message = "User created successfully"
                    setOpendi(true)
                    if (!opendi) {
                        localStorage.setItem('token', response.data["token"])
                        localStorage.setItem('id', response.data["uuid"])
                        localStorage.setItem('admin', response.data["super_admin"])
                        localStorage.setItem('company_id', response.data["company_id"])
                        window.location.href = "/companymanage"
                    }
                }
                else {
                    console.error('Error create in:', apiResponse.error);
                }
                setLoading(false);
            } catch (error: any) {
                console.log("test: ", error)
                if (error.response) {
                    if (error.response.status == 400) {
                        setVerify(true)
                    }
                    else {
                        console.log('Error response status code:', error);
                        datadia.title = "Error"
                        datadia.message = "User exists"
                        setOpendi(true)
                    }
                }
                setLoading(false)
            }
        }
    }

    function checkDialogAnswer(data: { clicked: boolean; }) {
        setOpendi(false)
    }

    return (
        <div className='holder'>
            {
                verify && !verified ? (
                    <div>
                        <OTP phonenumber={userData.phone_number} verifiedComp={checkOTPVerification} withverification={true} />
                    </div>
                ) :
                    (
                        <><Card className='boxcard'>
                            <h2 style={{ textAlign: "center" }}>Sign up</h2>
                            <Stack direction="column" flex={1}>
                                <p>Full name</p>
                                <TextField
                                    variant="outlined"
                                    className='inpp'
                                    label="Full name"
                                    value={userData.full_name}
                                    name='full_name'
                                    onChange={handleChange} />
                            </Stack>
                            <Stack direction="column" flex={1}>
                                <p>Phone</p>
                                <TextField
                                    label="Phone"
                                    // disabled
                                    variant="outlined"
                                    className='inpp'
                                    value={userData.phone_number}
                                    name='phone_number'
                                    onChange={handleChange} />
                            </Stack>
                            <Stack direction="column" flex={1}>
                                <p>Email</p>
                                <TextField
                                    label="Email"
                                    variant="outlined"
                                    className='inpp'
                                    value={userData.email}
                                    name='email'
                                    onChange={handleChange} />
                            </Stack>
                            <Stack direction="column" flex={1}>
                                <p>Password</p>
                                <TextField
                                    type='password'
                                    label="Password"
                                    variant="outlined"
                                    className='inpp'
                                    value={userData.password}
                                    name='password'
                                    onChange={handleChange} />
                            </Stack>
                            <PasswordStrengthChecker password={userData.password} />
                            <Stack direction="column" flex={1}>
                                <p>Confirm Password</p>
                                <TextField
                                    type='password'
                                    label="Confirm Password"
                                    variant="outlined"
                                    className='inpp'
                                    value={userData.confirm_password}
                                    name="confirm_password"
                                    onChange={handleChange}
                                />
                            </Stack>
                            <Stack direction='row'>
                                <Stack direction="column" flex={1} style={{
                                    marginRight: '10px'
                                }}>
                                    <p>Confirm Password</p>
                                    <TextField
                                        type='date'
                                        variant="outlined"
                                        className='inpp'
                                        value={userData.date_of_birth}
                                        name='date_of_birth'
                                        onChange={handleChange}
                                    />
                                </Stack>
                                <Stack direction="column" flex={1}>
                                    <p>Gender</p>
                                    <Select
                                        className='inpp'
                                        value={userData.gender}
                                        label="Category"
                                        onChange={changegender}
                                    >
                                        <MenuItem value={"male"}>Male</MenuItem>
                                        <MenuItem value={"female"}>Female</MenuItem>
                                    </Select>
                                </Stack>
                            </Stack>
                            <small><a className='smalllink' href="/users">Sign in</a></small>
                        </Card>
                            <>
                                {errors.length > 0 && (
                                    <Alert severity="error">
                                        <ul>
                                            {errors.map((e) => (
                                                <li>{e}</li>
                                            ))}
                                        </ul>
                                    </Alert>)}
                                {loading ? (
                                    <CircularProgress className='boxbutton' style={{
                                        marginTop: "10px"
                                    }} />) : (<Button variant="contained" className='boxbutton' onClick={handleSave} disableElevation style={{
                                        backgroundColor: "#2173FE",
                                        marginTop: "10px"
                                    }}>
                                        Create Account
                                    </Button>)}
                            </>
                        </>
                    )
            }

            <AlertDialog opendialog={opendi} datadia={datadia} dialogAnswer={checkDialogAnswer} />

        </div>
    )
}

export default UserCreate