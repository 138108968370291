import React, { ChangeEvent } from 'react'
import { useState } from 'react';
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import { CircularProgress } from '@mui/material';
import { AlertDialog } from '../Dialog';
import UserController from '../../controllers/Users/UserController';
import handleApiResponse from '../../services/ApiResponse';

interface OTPProps {
    withverification: boolean,
    phonenumber: string;
    verifiedComp: (data: {
        verified: boolean,
        phone: string,
        otp: string
    }) => void;
}

function OTP(props: OTPProps) {
    const [otp, setOtp] = React.useState('')
    const [phone, setPhone] = useState(props.phonenumber)
    const [steps, setSteps] = useState(0);
    const [loading, setLoading] = useState(false);
    const [verified, setVerified] = useState(0);
    const [opendi, setOpendi] = React.useState(false)
    const [datadia, setDiag] = useState({
        title: '',
        message: '',
        mode: 'normal'
    })


    async function handleSave() {
        setLoading(true)
        if (steps == 0) {
            try {
                const response = await UserController.receiveOTP({ "phone_number": phone })
                const apiResponse = handleApiResponse(response)
                if (apiResponse.success) {
                    console.log(response.data.code)
                    if (!props.withverification) {
                        props.verifiedComp({ verified: true, otp: otp, phone: phone });
                    }
                    else
                        setSteps(1)
                }
            } catch (error: any) {
                console.log(error)
                datadia.title = "Invalid"
                datadia.message = "Invalid number"
                setOpendi(true)
            }
            setLoading(false);
        }

        else if (steps == 1 && props.withverification) {
            try {
                const response = await UserController.verifyOTP({ "phone_number": phone, "otp_code": otp })
                const apiResponse = handleApiResponse(response)
                if (apiResponse.success) {
                    console.log(response.data.code)
                    setVerified(1)
                    props.verifiedComp({ verified: true, otp: otp, phone: phone });
                }
            } catch (error: any) {
                console.log(error)
                setVerified(2)
            }
            setLoading(false);
        }
    }

    return (
        <div className='holder'>
            {steps == 0 ? (
                <Card className='boxcard'>
                    <h2 style={{ textAlign: "center" }}>Phone Verification</h2>
                    <Stack direction="column" flex={1}>
                        <p>Phone</p>
                        <TextField
                            label="Phone"
                            variant="outlined"
                            className='inpp'
                            value={phone}
                            name='phone_number'
                            onChange={(newValue: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                setPhone(newValue.target.value)
                            }} />
                    </Stack>
                </Card>
            ) : (
                <Card className='boxcard'>
                    <h2 style={{ textAlign: "center" }}>Phone Verification</h2>
                    <Stack direction="column" flex={1}>
                        <p>Verfification Code</p>
                        <TextField
                            label="Verfification Code"
                            variant="outlined"
                            className='inpp'
                            value={otp}
                            onChange={(newValue: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                setOtp(newValue.target.value)
                            }} />
                    </Stack>
                    {
                        verified === 1 ? (
                            <small style={{ color: "green" }}>Verified!</small>
                        ) : verified === 2 ? (
                            <small style={{ color: "red" }}>Wrong code</small>
                        ) : (
                            null
                        )
                    }
                </Card>
            )}
            {loading ? (
                <CircularProgress className='boxbutton' style={{
                    marginTop: "10px"
                }} />) : (<Button variant="contained" className='boxbutton' onClick={handleSave} disableElevation style={{
                    backgroundColor: "#2173FE",
                    marginTop: "10px"
                }}>
                    Submit
                </Button>)}
            <AlertDialog opendialog={opendi} datadia={datadia} />
        </div>
    )
}

export default OTP

function verifiedComp(arg0: boolean) {
    throw new Error('Function not implemented.');
}
