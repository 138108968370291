import React from 'react'
import Charts from '../components/Chart'
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import ThreePIcon from '@mui/icons-material/ThreeP';
import Stack from '@mui/material/Stack';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const SText = styled('div')(({ theme }) => ({
  color: "#23272E",
  fontFamily: "Arial",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "26px",
  letterSpacing: "-0.36px",
  marginBottom: "30px"
}))

const Conts = styled('div')(({ theme }) => ({
  display: "flex",
  width: "40%",
  justifyContent: "space-between",
  float: 'right',
}));

const Mainchart = styled('div')(({ theme }) => ({
  borderStyle: "solid",
  borderRadius: "8px",
  borderWidth: "2px",
  borderColor: "#eee"
}));

function Dashboard() {
  const datacharts1 = [
    { year: '12am', count: 60 },
    { year: '8am', count: 30 },
    { year: '4pm', count: 10 }
  ]
  const chart1 = {
    chartType: "pie",
    chartId: 'chart1',
    chartData:
    {
      labels: datacharts1.map(row => row.year),
      fill: true,
      datasets: [
        {
          label: 'New',
          data: datacharts1.map(row => row.count),
          backgroundColor: ['#34AAFD', '#45BBFE', '#2070FF'],
          hoverBackgroundColor: ['#555555', '#555555', '#555555']
        }
      ],
    },

    chartOptions:
    {
      plugins: {
        legend: {
          position: 'none'
        },
        labels: {
          render: 'percentage',
          fontColor: '#fff',
          precision: 0,
        }
      }
    }
  }



  const datacharts21 = [
    { year: 'Mon', count: 25 },
    { year: 'Tue', count: 35 },
    { year: 'Wed', count: 45 },
    { year: 'Thu', count: 45 },
    { year: 'Fri', count: 55 },
    { year: 'Sat', count: 65 },
    { year: 'Sun', count: 55 },
    { year: 'Mon', count: 25 },
    { year: 'Tue', count: 35 },
    { year: 'Wed', count: 45 },
    { year: 'Thu', count: 45 },
    { year: 'Fri', count: 55 },
    { year: 'Sat', count: 65 },
    { year: 'Sun', count: 55 }
  ]
  const datacharts22 = [
    { year: 'Mon', count: 54 },
    { year: 'Tue', count: 44 },
    { year: 'Wed', count: 45 },
    { year: 'Thu', count: 60 },
    { year: 'Fri', count: 55 },
    { year: 'Sat', count: 39 },
    { year: 'Sun', count: 43 },
    { year: 'Mon', count: 54 },
    { year: 'Tue', count: 44 },
    { year: 'Wed', count: 45 },
    { year: 'Thu', count: 60 },
    { year: 'Fri', count: 55 },
    { year: 'Sat', count: 39 },
    { year: 'Sun', count: 43 }
  ]

  const chart2 = {
    chartType: "line",
    chartId: 'chart2',
    chartData:
    {
      labels: datacharts21.map(row => row.year),
      fill: true,
      datasets: [
        {
          label: 'New',
          lineTension: 0.4,
          pointRadius: 0,
          fill: true,
          pointStyle: 'circle',
          data: datacharts21.map(row => row.count),
          backgroundColor: ['#306BFCAA'],
          borderColor: ['#0F60FF00', '#0F60FF00', '#0F60FF00'],
        },
        {
          label: 'New 2',
          lineTension: 0.4,
          pointRadius: 0,
          fill: true,
          pointStyle: 'circle',
          data: datacharts22.map(row => row.count),
          backgroundColor: ['#809FFFAA'],
          borderColor: ['#0F60FF00', '#0F60FF00', '#0F60FF00'],
        }
      ],
    },

    chartOptions:
    {
      interaction: {
        intersect: false,
        mode: 'index',
      },
      scales: {
        x: {
          grid: {
            display: false
          }
        },
        y: {
          display: false,
          grid: {
            display: true
          }
        }
      }
    }
  }

  const datacharts3 = [
    { year: '12am', count: 0 },
    { year: '', count: 40 },
    { year: '8am', count: 0 },
    { year: '', count: 20 },
    { year: '4pm', count: 0 },
    { year: '', count: 60 },
    { year: '11pm', count: 0 }
  ]
  const chart3 = {
    chartType: "line",
    chartId: 'chart2',
    chartData:
    {
      labels: datacharts3.map(row => row.year),
      fill: true,
      datasets: [
        {
          label: 'New',
          lineTension: 0,
          pointRadius: 0,
          fill: false,
          data: datacharts3.map(row => row.count),
          borderColor: ['#306BFCAA'],
        }
      ],
    },

    chartOptions:
    {
      label: {
        display: false
      },
      interaction: {
        intersect: false,
        mode: 'index',
      },
      scales: {
        x: {
          grid: {
            display: false
          }
        },
        y: {
          display: false,
          grid: {
            display: true
          }
        }
      }
    }
  }

  return (
    <div>
      <div style={
        {
          display: "flex"
        }
      }>
        <Card style={{
          padding: "30px",
          flex: "3",
          marginRight: "20px"
        }}>
          <Mainchart>
            <div style={{
              padding: "30px",
              width: "100%",
              margin: "auto",
              marginBottom: "30px",
            }}>
              <span>Sales Report</span>
              <Conts>
                <span>12 Months</span>
                <span>6 Months</span>
                <span>30 Days</span>
                <span>7 Days</span>
              </Conts>
            </div>
            <Charts
              style={{ margin: "auto", width: "90%", height: "100px" }}
              chartdata={chart2.chartData}
              type={chart2.chartType}
              ids={chart2.chartId}
              chartoptions={chart2.chartOptions} />
          </Mainchart>

          <Stack direction="row" useFlexGap flexWrap="wrap" marginTop={5} justifyContent={"space-between"}>
            <Mainchart style={{
              width: "fit-content",
              paddingLeft: "30px",
              paddingRight: "30px"
            }}>
              <Stack spacing={{ sm: 3 }} direction="row" useFlexGap flexWrap="wrap">
                <ThreePIcon style={{ color: "#32A7FD", margin: "auto" }} />
                <p>Users</p>
              </Stack>
              <h1 style={{
                fontSize: "60px",
                margin: 0
              }}>35k</h1>
            </Mainchart>
            <Mainchart style={{
              width: "fit-content",
              paddingLeft: "30px",
              paddingRight: "30px"
            }}>
              <Stack spacing={{ sm: 3 }} direction="row" useFlexGap flexWrap="wrap">
                <LoyaltyIcon style={{ color: "#32A7FD", margin: "auto" }} />
                <p>Sales</p>
              </Stack>
              <h1 style={{
                fontSize: "60px",
                margin: 0
              }}>345$</h1>
            </Mainchart>
            <Mainchart style={{
              width: "fit-content",
              paddingLeft: "30px",
              paddingRight: "30px"
            }}>
              <Stack spacing={{ sm: 3 }} direction="row" useFlexGap flexWrap="wrap">
                <Inventory2Icon style={{ color: "#32A7FD", margin: "auto" }} />
                <p>Items</p>
              </Stack>
              <h1 style={{
                fontSize: "60px",
                margin: 0
              }}>168</h1>
            </Mainchart>
          </Stack>
        </Card>
        {/* /////////////////////////////////////////////////////////// */}
        <Card style={{
          padding: "30px",
          flex: "1",
        }}>

          <SText style={{
            textAlign: "center"
          }}>Total Today Order</SText>

          <SText style={{
            textAlign: "center",
            fontSize: "50px",
            color: "#34AAFD"
          }}>2000</SText>

          <SText style={{
            marginTop: "70px"
          }}>Today Order</SText>

          <Stack direction="row" useFlexGap flexWrap="wrap" justifyContent={"space-between"}>
            <SText style={{
              textAlign: "center",
              fontSize: "35px"
            }}>1.0K</SText>
            <Stack spacing={{ sm: 0.5 }} direction="row" useFlexGap flexWrap="wrap" >
              <ArrowUpwardIcon style={
                {
                  marginTop: "3px",
                  fontSize: '13px',
                  color: "#1EB564"
                }} />
              <span style={{
                fontSize: "13px",
                color: "#1EB564"
              }}>
                6%
              </span>
              <span style={{
                fontSize: "13px",
              }}>
                vs last day
              </span>
            </Stack>
          </Stack>

          <SText
            style={{
              fontSize: "15px",
              color: "grey",
              fontWeight: "1"
            }}
          >Orders Over Time</SText>

          <Charts
            style={{ margin: "auto", width: "90%", height: "250px" }}
            chartdata={chart3.chartData}
            type={chart3.chartType}
            ids={chart3.chartId}
            chartoptions={chart3.chartOptions} />

        </Card>
      </div>
    </div>
  )
}

export default Dashboard