import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
    children: ReactNode;
}

const ProtectedRouteAdmin: React.FC<ProtectedRouteProps> = ({ children }) => {
    let user = true;
    const token = localStorage.getItem('token');
    const admin = localStorage.getItem('admin');
    console.log('Token:', token);

    if (!token) {
        user = false;
    }
    console.log(admin)
    if (admin=='0') {
        user = false;
    }

    if (!user) {
        return <Navigate to="/profile" replace />;
    }

    return <>{children}</>;
};

export default ProtectedRouteAdmin;
