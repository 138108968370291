import BannersApiService from "./BannersApiController";

class BannersController{
    static createBanner(data: FormData) {
        return BannersApiService.createBanner(data);
    }
    static getAllBanners() {
        return BannersApiService.getAllBanners();
    }

    static deleteBanner(id: string | number) {
        return BannersApiService.deleteBanner(id);
    }

    static getBannerById(id: string | number) {
        return BannersApiService.getBannerById(id);
    }

    static updateBanner(id: string|number, data: FormData) {
        return BannersApiService.updateBanner(id, data);
    }
}

export default BannersController;