import ApiResponseModel from "../models/ApiResponseModel";

const handleApiResponse = (axiosResponse: any) => {
    return new ApiResponseModel(
        axiosResponse.status === 200,
        axiosResponse.data,
        axiosResponse.status !== 200 ? axiosResponse.statusText : null
    );
};

export default handleApiResponse;