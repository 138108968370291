import React, { ChangeEvent } from 'react'
import { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ImageUpload from '../../components/DragUpload';
import GoogleMap from '../../components/GoogleMap';
import AssignAdmin from '../../components/Users/AssignAdmin';
import DeleteAccount from '../../components/Users/DeleteAccount';
import ItemsController from '../../controllers/Items/ItemsController';
import handleApiResponse from '../../services/ApiResponse';
import CategoriesController from '../../controllers/Categories/CategoriesController';
import CircularProgress from '@mui/material/CircularProgress';
import { AlertDialog } from '../../components/Dialog';
import { useParams } from 'react-router-dom';
import { GetCategories, GetCategoriesP } from '../../components/Categories/GetCategories';
import Alert from '@mui/material/Alert';
import Validation from '../../components/Validation';
import GetCompanies from '../../components/Companies/GetCompanies';
import CompaniesController from '../../controllers/Companies/CompaniesController';
import SizesController from '../../controllers/Settings/Sizes/SizesController';
import { Paper, Chip, Checkbox, FormControlLabel } from '@mui/material';
import GetColors from '../../components/Settings/Colors/GetColors';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

interface SizeData {
  id: number,
  name_ar: string,
  name_en: string,
  options: Array<string>
  options_ar: Array<string>
}

interface ColorData {
  id: number,
  name_en: string,
  name_ar: string,
  code: string
}

interface CategoriesData {
  id: number,
  image: string,
  name_ar: string,
  name_en: string,
  parent: null | number,
}


interface Item {
  name: string,
  name_ar: string,
  item_code: string,
  description: string,
  description_ar: string,
  image?: string | File
  category_id: string,
  category_name_en: string,
  company_id: string | null,
  size: Array<string>,
  size_ar: Array<string>,
  basic_color: Map<string, string>,
  original_price: number,
  sold: boolean,
  sold_price: number,
  in_stock: boolean,
  stock_quantity: number,
  // ingredients: Array<string>,
  // ingredients_ar: Array<string>,
  // extra: Array<Map<string, number>>,
  // extra_ar: Array<Map<string, number>>,
  // combo: boolean,
  // combo_price: number,
}

function ItemsCreate() {
  var tbnclass = document.getElementsByClassName("topboxnum") as HTMLCollectionOf<HTMLElement>;
  var tbtclass = document.getElementsByClassName("topboxtext") as HTMLCollectionOf<HTMLElement>;
  const [nexti, setNexti] = useState(0);
  const [errors, setError] = useState<string[]>([]);
  const [location, setLocation] = useState('loc 1');
  const [sizes_list, setSizeList] = useState('');
  const [categories, setCategories] = useState<CategoriesData[]>([]);
  const [sizes, setSizes] = useState<SizeData[]>([]);
  const { colors, colorIndex } = GetColors();
  const [colors_list, setColorsList] = useState('');
  const [loadCat, setloadCat] = useState(false);


  const [itemdata, setItemData] = useState<Item>({
    name: "",
    name_ar: "",
    item_code: "",
    description: "",
    description_ar: "",
    image: "" || new File([""], "", { type: 'image/png' }),
    category_id: "",
    category_name_en: "",
    company_id: localStorage.getItem("company_id") ? localStorage.getItem("company_id") : "",
    size: [],
    size_ar: [],
    basic_color: new Map(),
    original_price: 0,
    sold: false,
    sold_price: 0,
    in_stock: false,
    stock_quantity: 0
    // ingredients: [],
    // ingredients_ar: [],
    // extra: [],
    // extra_ar: [],
    // combo: false,
    // combo_price: 0,
  });

  const [loading, setLoading] = useState(false);
  const [opendi, setOpendi] = React.useState(false)
  const [datadia, setDiag] = useState({
    title: 'Item',
    message: 'Item created successfuly',
    mode: 'normal'
  })

  const [ingen, setIngEn] = useState("")
  const [ingar, setIngAr] = useState("")

  const [extraen, setExtraEn] = useState("")
  const [extraar, setExtraAr] = useState("")
  const [extraprice, setExtraPrice] = useState(0)

  function checkDialogAnswer(data: { clicked: boolean; }) {
    setOpendi(false)
    window.location.reload();
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setItemData({
      ...itemdata,
      [name]: value
    });
  };

  /* const handleChangeNum = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    // const numericValue = parseFloat(value);

    // if (!isNaN(numericValue)) {
    setItemData({
      ...itemdata,
      [name]: value
    });
    // } else {
    //   // alert("please insert a number in the ")
    // }
  }; */
  const handleChangeNum = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setItemData({
      ...itemdata,
      [name]: value
    });
  };

  useEffect(() => {
    loadSizes()
  }, [])

  useEffect(() => {
    loadSubCategories()
  }, [itemdata])


  // async function loadSubCategories() {
  //   try {
  //     let response
  //     // console.log(localStorage.getItem("company_id"))
  //     if (itemdata.company_id)
  //       response = await ItemsController.getCompanyCategories("" + itemdata.company_id)
  //     const apiResponse = handleApiResponse(response)
  //     if (apiResponse.success) {
  //       let response2
  //       console.log("apiResponse.data")
  //       console.log(apiResponse.data)
  //       setCategories(apiResponse.data)
  //       // if (apiResponse.data.category)
  //       //   response2 = await CategoriesController.getSubCategory(apiResponse.data.category)
  //       // const apiResponse2 = handleApiResponse(response2)
  //       // console.log(apiResponse2.data)
  //       // if (apiResponse2.success) {
  //       //   setCategories(apiResponse2.data)
  //       // }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  async function loadSubCategories() {
    try {
      // let response
      // console.log("DDDDD")
      // console.log(itemdata)
      // if (itemdata.company_id)
      //   response = await CompaniesController.getCompanyById("" + itemdata.company_id)
      // // response = await ItemsController.getCompanyCategories("" + itemdata.company_id)
      // const apiResponse = handleApiResponse(response)
      // if (apiResponse.success) {
      //   let response2
      //   if (apiResponse.data.category)
      //     response2 = await CategoriesController.getSubCategory(apiResponse.data.category)
      //   const apiResponse2 = handleApiResponse(response2)
      //   // console.log(apiResponse2.data)
      //   if (apiResponse2.success) {
      //     setCategories(apiResponse2.data)
      //   }
      // }

      const response2 = await CategoriesController.getAllCategories()
      const apiResponse2 = handleApiResponse(response2)
      // console.log(apiResponse2.data)
      if (apiResponse2.success) {
        setCategories(apiResponse2.data)
      }

    } catch (error) {
      console.log(error);
    }
  }

  async function loadSizes() {
    try {
      const response = await SizesController.getAllSizes()
      const apiResponse = handleApiResponse(response)
      if (apiResponse.success) {
        // console.log(apiResponse.data)
        setSizes(apiResponse.data)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const [subsCatDrop, setSubCatDrop] = useState<Map<string, any>>(new Map());
  const changecat = (event: SelectChangeEvent) => {
    setItemData({
      ...itemdata,
      category_id: event.target.value
    });
    setloadCat(true)
    let name = event.target.name
    Array.from(subsCatDrop.entries()).map(([key, value]) => {
      if (parseInt(key) >= parseInt(name)) {
        subsCatDrop.delete(key)
      }
      setSubCatDrop(subsCatDrop)
    })
    const fetchData = async () => {
      const data = await GetCategoriesP(parseInt(event.target.value), false);
      if (data.length > 0) {
        setSubCatDrop(new Map(subsCatDrop).set(name, data))
        setloadCat(false)
      }
      else
        setloadCat(false)
    };
    fetchData();
  };

  // const changecat = (event: SelectChangeEvent) => {
  //   setItemData({
  //     ...itemdata,
  //     category_id: event.target.value
  //   });
  // };

  const handleDelete = (data: string) => () => {
    let arindex = itemdata.size.indexOf(data)
    setItemData({
      ...itemdata,
      size: itemdata.size.filter(item => item !== data),
      size_ar: itemdata.size_ar.filter(item => item !== itemdata.size_ar[arindex])
    })
  };

  const handleDeleteAr = (data: string) => () => {
    let enindex = itemdata.size_ar.indexOf(data)
    setItemData({
      ...itemdata,
      size_ar: itemdata.size_ar.filter(item => item !== data),
      size: itemdata.size.filter(item => item !== itemdata.size[enindex])
    })
  };

  const handleDeleteColor = (data: string) => () => {
    itemdata.basic_color.delete(data)
    setItemData({
      ...itemdata,
      basic_color: itemdata.basic_color
    });


    let arindex = itemdata.size.indexOf(data)
    setItemData({
      ...itemdata,
      size: itemdata.size.filter(item => item !== data),
      size_ar: itemdata.size_ar.filter(item => item !== itemdata.size_ar[arindex])
    })
  };

  const changesize = (event: SelectChangeEvent) => {
    let size = sizes[event.target.value as unknown as number]
    setItemData({
      ...itemdata,
      size: size.options,
      size_ar: size.options_ar
    });
  };

  const changecolor = (event: SelectChangeEvent) => {
    let col = colors[event.target.value as unknown as number]
    itemdata.basic_color.set(col.name_en, col.code);
    // console.log(itemdata.basic_color)
    setItemData({
      ...itemdata,
      basic_color: itemdata.basic_color
    });
  };

  const handleChangeCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setItemData({
      ...itemdata,
      sold: event.target.checked
    });
  };

  const handleChangeCheckinStock = (event: React.ChangeEvent<HTMLInputElement>) => {
    setItemData({
      ...itemdata,
      in_stock: event.target.checked
    });
  };

  // const handleChangeCheckCombo = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setItemData({
  //     ...itemdata,
  //     combo: event.target.checked
  //   });
  // };

  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response
        if (id) {
          response = await ItemsController.getItemById(id)
          const apiResponse = handleApiResponse(response)
          // console.log(response.data)
          if (apiResponse.success) {
            console.log("load Item Data")
            console.log(apiResponse.data)
            const obj: Item = {
              name: apiResponse.data.name,
              name_ar: apiResponse.data.name_ar,
              item_code: apiResponse.data.item_code,
              description: apiResponse.data.description,
              description_ar: apiResponse.data.description_ar,
              image: apiResponse.data.image,
              category_id: apiResponse.data.category_id,
              company_id: apiResponse.data.company_id,
              category_name_en: apiResponse.data.category_name_en,
              size: apiResponse.data.size,
              size_ar: apiResponse.data.size_ar,
              basic_color: objectToMap(apiResponse.data.basic_color),
              original_price: apiResponse.data.original_price,
              sold: apiResponse.data.sold,
              sold_price: apiResponse.data.sold_price,
              in_stock: apiResponse.data.in_stock,
              stock_quantity: apiResponse.data.stock_quantity,
              // ingredients: apiResponse.data.ingredients,
              // ingredients_ar: apiResponse.data.ingredients_ar,
              // extra: convertObjectsToArrayOfMaps(apiResponse.data.extra),
              // extra_ar: convertObjectsToArrayOfMaps(apiResponse.data.extra_ar),
              // combo: apiResponse.data.combo,
              // combo_price: apiResponse.data.combo_price,
            }
            setItemData(obj)
            console.log("dffff")
            console.log(obj)
            console.log(itemdata)
            loadSubCategories()
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (categories.length == 0) {
      fetchData();
    }
    return () => {
    }
  }, []);

  function mapToObject(map: Map<string, string>): { [key: string]: string } {
    const obj: { [key: string]: string } = {};

    map.forEach((value, key) => {
      obj[key] = value;
    });
    return obj;
  }

  function objectToMap(obj: { [key: string]: string }): Map<string, string> {
    const map = new Map<string, string>();

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        map.set(key, obj[key]);
      }
    }
    return map;
  }


  type KeyValues = { [key: string]: number };
  function convertArrayToObjects(array: Array<Map<string, number>>): Array<KeyValues> {
    return array.map((map) => {
      const obj: KeyValues = {};

      map.forEach((value, key) => {
        obj[key] = value;
      });

      return obj;
    });
  }

  function convertObjectsToArrayOfMaps(objects: Array<KeyValues>): Array<Map<string, number>> {
    return objects.map((obj) => {
      const map = new Map<string, number>();

      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          map.set(key, obj[key]);
        }
      }

      return map;
    });
  }


  function objectToFormData(obj: Record<string, any>): FormData {
    const formData = new FormData();
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        let value = obj[key];
        if (Array.isArray(value)) {
          for (let i = 0; i < value.length; i++) {
            formData.append(key, JSON.stringify(value));
          }
        } else {
          formData.append(key, value);
        }
      }
    }
    formData.append("name", itemdata.name)
    formData.append("name_ar", itemdata.name_ar)
    formData.append("item_code", itemdata.item_code)
    formData.append("description", itemdata.description)
    formData.append("description_ar", itemdata.description_ar)
    formData.append("category_id", itemdata.category_id)
    formData.append("company_id", itemdata.company_id!)
    formData.append("size", JSON.stringify(itemdata.size))
    formData.append("size_ar", JSON.stringify(itemdata.size_ar))
    formData.append("color", JSON.stringify(mapToObject(itemdata.basic_color)))
    // formData.append("original_price", JSON.stringify(itemdata.original_price))
    formData.append("sold", JSON.stringify(itemdata.sold))
    formData.append("in_stock", JSON.stringify(itemdata.in_stock))
    // formData.append("ingredients", JSON.stringify(itemdata.ingredients))
    // formData.append("ingredients_ar", JSON.stringify(itemdata.ingredients_ar))
    // formData.append("extra", JSON.stringify(convertArrayToObjects(itemdata.extra)))
    // formData.append("extra_ar", JSON.stringify(convertArrayToObjects(itemdata.extra_ar)))
    // formData.append("combo", JSON.stringify(itemdata.combo))
    return formData;
  }

  // const handleDeleteIng = (data: string) => () => {
  //   let arindex = itemdata.ingredients.indexOf(data)
  //   setItemData({
  //     ...itemdata,
  //     ingredients: itemdata.ingredients.filter(item => item !== data),
  //     ingredients_ar: itemdata.ingredients_ar.filter(item => item !== itemdata.ingredients_ar[arindex])
  //   })
  // };

  // const handleDeleteIngAr = (data: string) => () => {
  //   let enindex = itemdata.ingredients_ar.indexOf(data)
  //   setItemData({
  //     ...itemdata,
  //     ingredients_ar: itemdata.ingredients_ar.filter(item => item !== data),
  //     ingredients: itemdata.ingredients.filter(item => item !== itemdata.ingredients[enindex])
  //   })
  // };

  // const handleAppendIng = () => {
  //   if (itemdata.ingredients.indexOf(ingen) == -1 && ingen != "" && itemdata.ingredients_ar.indexOf(ingar) == -1 && ingar != "") {
  //     setItemData({
  //       ...itemdata,
  //       ingredients: [...itemdata.ingredients, ingen],
  //       ingredients_ar: [...itemdata.ingredients_ar, ingar]
  //     })
  //     setIngEn("")
  //     setIngAr("")
  //   }
  // };


  // const handleAppendExtra = () => {
  //   let c = 0;
  //   itemdata.extra.forEach(element => {
  //     if (element.has(extraen))
  //       c++;
  //   })
  //   itemdata.extra_ar.forEach(element => {
  //     if (element.has(extraar))
  //       c++;
  //   })

  //   if (c == 0) {
  //     let extramap = new Map<string, number>()
  //     let extramap_ar = new Map<string, number>()
  //     extramap.set(extraen, extraprice)
  //     extramap_ar.set(extraar, extraprice)
  //     setItemData({
  //       ...itemdata,
  //       extra: [...itemdata.extra, extramap],
  //       extra_ar: [...itemdata.extra_ar, extramap_ar],
  //     })
  //     setExtraEn("")
  //     setExtraAr("")
  //     setExtraPrice(0)
  //     // console.log(itemdata.extra)
  //   }
  // };

  // const handleDeleteExtra = (data: string) => () => {
  //   let index = 0;
  //   itemdata.extra.forEach((element, i) => {
  //     if (element.has(data))
  //       index = i
  //   })
  //   setItemData({
  //     ...itemdata,
  //     extra: itemdata.extra.filter(item => item !== itemdata.extra[index]),
  //     extra_ar: itemdata.extra_ar.filter(item => item !== itemdata.extra_ar[index])
  //   })
  // };

  // const handleDeleteExtraAr = (data: string) => () => {
  //   let index = 0;
  //   itemdata.extra_ar.forEach((element, i) => {
  //     if (element.has(data))
  //       index = i
  //   })
  //   setItemData({
  //     ...itemdata,
  //     extra: itemdata.extra.filter(item => item !== itemdata.extra[index]),
  //     extra_ar: itemdata.extra_ar.filter(item => item !== itemdata.extra_ar[index])
  //   })
  // };

  async function createItem() {
    // console.log(itemdata)
    setLoading(true)
    try {
      let response
      if (id) {
        if (typeof itemdata.image == "string")
          delete itemdata.image
        response = await ItemsController.updateItem(id, objectToFormData(itemdata))
      }
      else
        response = await ItemsController.createItem(objectToFormData(itemdata))
      const apiResponse = handleApiResponse(response)
      if (apiResponse.success) {
        datadia.title = "Success"
        id ? datadia.message = "Item saved successfully" : datadia.message = "Item added successfully"
        setOpendi(true)
      } else {
        console.error('Failed to fetch user data.');
      }
    } catch (error: any) {
      datadia.title = "Error"
      datadia.message = "Error creating item"
      setOpendi(true)
      console.error('Error fetching user data:', error);
    }
    setLoading(false)
  }


  useEffect(() => {
    setNexti(0)
    tbnclass[0].style.backgroundColor = "#2173FE";
    tbtclass[0].style.color = "#2173FE";
    return () => {
      // console.log('Component is unmounted');
    };
  }, []);


  function handleNext() {
    if (nexti < tbnclass.length) {
      setNexti(nexti + 1)
    }
    if (nexti < tbnclass.length - 1) {
      tbnclass[nexti + 1].style.backgroundColor = "#2173FE";
      tbtclass[nexti + 1].style.color = "#2173FE";
    }
    else {
      const { errors, flag } = Validation(
        [
          id ? [itemdata.image, "Image is required", 1] : (typeof itemdata.image != "string" ? [itemdata.image?.name, "Image is required", 1] : ["", "Image is required", 1, false]),
          [itemdata.name, "Name is required", 0],
          [itemdata.name_ar, "Arabic Name is required", 0],
          [itemdata.description, "Description is required", 0],
          [itemdata.description_ar, "Arabic Description is required", 0],
          [itemdata.category_id, "Category is required", 0],
          [itemdata.company_id, "Company is required", 0],
          [itemdata.original_price, "Original price is required", 0],
        ]
      )
      setError(errors)
      if (flag == 1000)
        createItem()
      else
        setNexti(flag)
    }
  }
  const handleImageUpload = (files: FileList | null) => {
    if (files) {
      itemdata.image = files[0]
    }
  };

  return (
    <div className='holder'>
      <Stack direction="row" width={"70%"} margin={"auto"} marginBottom={3}>
        <div className='topboxnum'>1</div>
        <span className='topboxtext'>Shop Info</span>
        <div className='topboxline'></div>
        <div className='topboxnum'>2</div>
        <span className='topboxtext'>Media</span>
      </Stack>

      {nexti === 0 ? (
        <Card className='boxcard'>
          <h2 style={{ textAlign: "center" }}>Item Information</h2>
          <Stack direction="row" spacing={1}>
            <Stack direction="column" flex={1}>
              <p>Name</p>
              <TextField
                variant="outlined"
                className='inpp'
                label="Item Name"
                value={itemdata.name}
                name='name'
                onChange={handleChange} />
            </Stack>
            <Stack direction="column" flex={1}>
              <p>Arabic Name</p>
              <TextField
                variant="outlined"
                className='inpp'
                label="Item Arabic Name"
                value={itemdata.name_ar}
                name='name_ar'
                onChange={handleChange} />
            </Stack>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Stack direction="column" flex={1}>
              <p>Item Code</p>
              <TextField
                variant="outlined"
                className='inpp'
                label="Item Code"
                value={itemdata.item_code}
                name='item_code'
                onChange={handleChange} />
            </Stack>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Stack direction="column" flex={1}>
              <p>Description</p>
              <TextField
                variant="outlined"
                className='inpp'
                label="Description"
                value={itemdata.description}
                name='description'
                onChange={handleChange} />
            </Stack>
            <Stack direction="column" flex={1}>
              <p>Description Arabic</p>
              <TextField
                variant="outlined"
                className='inpp'
                label="Arabic Description"
                value={itemdata.description_ar}
                name='description_ar'
                onChange={handleChange} />
            </Stack>
          </Stack>
          <Stack direction="column" flex={1}>
            <p>Category: {itemdata.category_name_en}</p>
            <Select
              className='inpp'
              value={itemdata.category_id}
              label="Category"
              name={"0"}
              onChange={changecat}
            >
              {categories.map((category, i) => (
                <MenuItem value={category.id}>{category.name_en}</MenuItem>
              ))}
            </Select>
            {
              Array.from(subsCatDrop.entries()).map(([key, value], i) => (
                <>
                  <Select
                    className='inpp'
                    value={itemdata.category_id}
                    label={"Sub Category " + key}
                    name={"" + (i + 1)}
                    onChange={changecat}
                  >
                    {value.map((v: any) => (
                      <MenuItem value={v.id}>{
                        localStorage.getItem('language') == "1" ? v.name_en : v.name_ar
                      }</MenuItem>
                    ))}
                  </Select>
                </>
              ))
            }
            {
              loadCat &&
              <CircularProgress className='boxbutton' style={{
                margin: "auto",
                marginTop: "10px",
              }} />
            }
          </Stack>
          <Stack direction="column" flex={1}>
            <p>Sizes</p>
            <Select
              className='inpp'
              value={sizes_list}
              label="Size"
              onChange={changesize}
            >
              {sizes.map((size, i) => (
                <MenuItem value={i}>{size.name_en}, {size.name_ar}</MenuItem>
              ))}
            </Select>
          </Stack>
          {itemdata.size != null &&
            <>
              <p>Size Units</p>
              <Paper
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  listStyle: 'none',
                  p: 0.5,
                  m: 0,
                }}
                component="ul"
              >
                {itemdata.size.map((data) => {
                  let icon;
                  return (
                    <Chip
                      icon={icon}
                      label={data}
                      onDelete={handleDelete(data)}
                    />
                  );
                })}
              </Paper>
            </>
          }
          {itemdata.size_ar != null &&
            <Paper
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                listStyle: 'none',
                p: 0.5,
                m: 0,
              }}
              component="ul"
            >
              {itemdata.size_ar.map((data) => {
                let icon;
                return (
                  <Chip
                    icon={icon}
                    label={data}
                    onDelete={handleDeleteAr(data)}
                  />
                );
              })}
            </Paper>}


          <Stack direction="column" flex={1}>
            <p>Colors</p>
            <Select
              className='inpp'
              value={colors_list}
              label="Color"
              onChange={changecolor}
            >
              {colors.map((color, i) => (
                <MenuItem value={i}>
                  {color.name_en},
                  {color.name_ar},
                  <div style={{
                    fontSize: "40px",
                    color: color.code
                  }}>●</div></MenuItem>
              ))}
            </Select>
          </Stack>

          {Array.from(itemdata.basic_color, ([key, value]) => ({ key, value })).map((data) => {
            let icon;
            return (
              <Chip
                icon={icon}
                style={{
                  fontWeight: "bold",
                  backgroundColor: data.value + "77"
                }}
                label={data.key}
                onDelete={handleDeleteColor(data.key)}
              />
            );
          })}

          <Stack direction="column" flex={1}>
            <p>Original Price</p>
            <TextField
              variant="outlined"
              className='inpp'
              label="Original Price"
              value={itemdata.original_price}
              name='original_price'
              onChange={handleChangeNum} />
          </Stack>

          <Stack direction="row" justifyContent={"space-evenly"} style={{ marginTop: "40px", display: "flex" }}>
            <FormControlLabel style={{ flex: "1" }} control={<Checkbox checked={itemdata.sold} onChange={handleChangeCheck} />} label="Sold" />
            {itemdata.sold && (
              <TextField
                style={{ flex: "5" }}
                variant="outlined"
                className='inpp'
                label="Sold Price"
                value={itemdata.sold_price}
                name='sold_price'
                onChange={handleChangeNum} />
            )}
          </Stack>

          <Stack direction="row" justifyContent={"space-evenly"} style={{ marginTop: "40px", display: "flex" }}>
            <FormControlLabel style={{ flex: "1" }} control={<Checkbox checked={itemdata.in_stock} onChange={handleChangeCheckinStock} />} label="In Stock" />
            {itemdata.in_stock && (
              <TextField
                style={{ flex: "5" }}
                variant="outlined"
                className='inpp'
                label="Stock Quantity"
                value={itemdata.stock_quantity}
                name='stock_quantity'
                onChange={handleChangeNum} />
            )}
          </Stack>

          {/* <Stack direction="row" justifyContent={"space-evenly"} style={{ marginTop: "40px", display: "flex" }}>
            <FormControlLabel style={{ flex: "1" }} control={<Checkbox checked={itemdata.combo} onChange={handleChangeCheckCombo} />} label="Combo" />
            {itemdata.combo && (
              <TextField
                style={{ flex: "5" }}
                variant="outlined"
                className='inpp'
                label="Combo Price"
                value={itemdata.combo_price}
                name='combo_price'
                onChange={handleChangeNum} />
            )}
          </Stack>

          <Stack direction="row" marginTop={5}>
            <Stack direction="column" flex={1}>
              <TextField
                label="Ingredient"
                variant="outlined"
                className='inpp'
                value={ingen}
                name='ingen'
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setIngEn(event.target.value);
                }} />
            </Stack>
            <Stack direction="column" flex={1} marginLeft={1}>
              <TextField
                label="Ingredient Arabic"
                variant="outlined"
                className='inpp'
                value={ingar}
                name='ingar'
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setIngAr(event.target.value);
                }} />
            </Stack>
            <AddCircleOutlineIcon onClick={handleAppendIng} style={{ fontSize: "50px", margin: "auto", cursor: "pointer", color: "#2173FE" }} />
          </Stack>
          {itemdata.ingredients.length > 0 &&
            <>
              <p>Ingredients</p>
              <Paper
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  listStyle: 'none',
                  p: 0.5,
                  m: 0,
                }}
                component="ul"
              >
                {itemdata.ingredients.map((data) => {
                  let icon;
                  return (
                    <Chip
                      icon={icon}
                      label={data}
                      onDelete={handleDeleteIng(data)}
                    />
                  );
                })}
              </Paper>
            </>
          }
          {itemdata.ingredients_ar.length > 0 &&
            <Paper
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                listStyle: 'none',
                p: 0.5,
                m: 0,
              }}
              component="ul"
            >
              {itemdata.ingredients_ar.map((data) => {
                let icon;
                return (
                  <Chip
                    icon={icon}
                    label={data}
                    onDelete={handleDeleteIngAr(data)}
                  />
                );
              })}
            </Paper>}


          <Stack direction="row" marginTop={5}>
            <Stack direction="column" flex={1}>
              <TextField
                label="Extra"
                variant="outlined"
                className='inpp'
                value={extraen}
                name='extraen'
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setExtraEn(event.target.value);
                }} />
            </Stack>
            <Stack direction="column" flex={1} marginLeft={1}>
              <TextField
                label="Extra Arabic"
                variant="outlined"
                className='inpp'
                value={extraar}
                name='extraar'
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setExtraAr(event.target.value);
                }} />
            </Stack>
            <Stack direction="column" flex={1} marginLeft={1}>
              <TextField
                label="Price"
                variant="outlined"
                className='inpp'
                value={extraprice}
                name='extraprice'
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  const parsedValue = parseFloat(event.target.value);
                  setExtraPrice(isNaN(parsedValue) ? 0 : parsedValue);
                }} />
            </Stack>
            <AddCircleOutlineIcon onClick={handleAppendExtra} style={{ fontSize: "50px", margin: "auto", cursor: "pointer", color: "#2173FE" }} />
          </Stack>
          {itemdata.extra.length > 0 &&
            <>
              <p>Extra</p>
              <Paper
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  listStyle: 'none',
                  p: 0.5,
                  m: 0,
                }}
                component="ul"
              >
                {itemdata.extra.map((data) => {
                  let icon;
                  return (
                    <Chip
                      icon={icon}
                      label={
                        Array.from(data.entries()).map(([key, value]) => (
                          <><b key={key}>{key}:</b>{value}</>
                        ))
                      }
                      onDelete={handleDeleteExtra(Array.from(data.entries()).map(([key, value]) => (
                        key
                      ))[0])}
                    />
                  );
                })}
              </Paper>
            </>
          }
          {itemdata.extra_ar.length > 0 &&
            <>
              <Paper
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  listStyle: 'none',
                  p: 0.5,
                  m: 0,
                }}
                component="ul"
              >
                {itemdata.extra_ar.map((data) => {
                  let icon;
                  return (
                    <Chip
                      icon={icon}
                      label={
                        Array.from(data.entries()).map(([key, value]) => (
                          <><b key={key}>{key}:</b>{value}</>
                        ))
                      }
                      onDelete={handleDeleteExtraAr(Array.from(data.entries()).map(([key, value]) => (
                        key
                      ))[0])}
                    />
                  );
                })}
              </Paper>
            </>
          } */}
        </Card>
      ) : (
        <ImageUpload onImageUpload={handleImageUpload} imageFile={itemdata.image} />
      )}
      {errors.length > 0 && (<Alert severity="error">
        <ul>
          {errors.map((e) => (
            <li>{e}</li>
          ))}
        </ul>
      </Alert>)}
      {loading ? (
        <CircularProgress className='boxbutton' style={{
          marginTop: "10px"
        }} />) : (<Button variant="contained" className='boxbutton' onClick={handleNext} disableElevation style={{
          backgroundColor: "#2173FE",
          marginTop: "10px"
        }}>
          Next
        </Button>)}
      <AlertDialog opendialog={opendi} datadia={datadia} dialogAnswer={checkDialogAnswer} />
    </div>
  )
}

export default ItemsCreate