import { useEffect, useState } from 'react';
import { Tables } from '../../components/Tables';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { GetCategories } from '../../components/Categories/GetCategories';
import { GetItems } from '../../components/Items/GetItems';
import { useParams } from 'react-router-dom';
import DeleteItems from '../../components/Items/DeleteItems';
import Skeleton from '@mui/material/Skeleton';
import { Stack } from '@mui/material';
import { loadingTable } from '../../components/loadingSkeleton';
import ItemsController from '../../controllers/Items/ItemsController';
import handleApiResponse from '../../services/ApiResponse';

interface Item {
  id: string;
  data: Array<number | string>,
}


interface ItemType {
  id: number | string,
  item_code: string,
  name: string,
  colors?: any[],
  size_details?: any[],
  name_ar: string,
  description: string,
  description_ar: string,
  image?: string | File
  category_id: string,
  company_id: string,
  size: Array<string>,
  size_ar: Array<string>,
  basic_color: Map<string, string>,
  original_price: number,
  sold: boolean,
  sold_price: number,
  in_stock: boolean,
  stock_quantity: number,
  ingredients: Array<string>,
  ingredients_ar: Array<string>,
  extra: Array<Map<string, number>>,
  extra_ar: Array<Map<string, number>>,
  combo: boolean,
  combo_price: number,
}

function Items() {
  const heads = [
    'Name',
    'Description',
    'Sizes',
    'Colors',
    'Price',
    'Sold',
    'Sold Price',
    'In Stock',
    'Quantity',
    'Item code',
    // 'Ingredients',
    // 'Extras',
    // 'Combo',
    // 'Combo Price',
    'Action'
  ];
  const [items, setItemDatas] = useState<ItemType[]>([]);
  const [itemData, setItemData] = useState<Item[]>([]);
  const [notfound, setNotfound] = useState(false);
  let id: any
  let group: any
  let { id: idparam } = useParams()
  let { group: groupparam } = useParams()
  const [page, setPage] = useState(1);
  const [last_page, setLastPage] = useState(1);


  id = idparam ? idparam : localStorage.getItem("company_id")
  console.log("group:" + groupparam)
  if (groupparam) {
    group = groupparam
  }
  else {
    group = "company"
  }

  async function fetch() {
    console.log(page)
    await GetItems(page, id ? id : null, group ? group : "", (itms: any, last_page: number) => {
      if (!itms)
        setNotfound(true)
      else {
        setLastPage(last_page)
        setItemDatas(itms)
        // ConvertToTable();
      }
    })
    // try {
    //   const response = await ItemsController.getAllItems(page!);
    //   const apiResponse = handleApiResponse(response)
    //   if (apiResponse.success) {
    //     setItemDatas(apiResponse.data.data);
    //   }
    // }
    // catch (error: any) {

    // }
  }

  useEffect(() => {
    fetch();
  }, [page]);


  useEffect(() => {
    ConvertToTable()
  }, [items]);


  function objectToMap(obj: any): Map<string, string> {
    const map = new Map<string, string>();

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        map.set(key, obj[key]);
      }
    }
    return map;
  }

  function organizeSizes(sizes: Array<string>, id: number | string, sizeData: any[]) {
    return (
      <span>
        {
          sizes.map((child) => (
            <span>
              {!checkExists(sizeData, child) ? (
                <span style={{ cursor: "pointer" }} onClick={() => { window.location.href = "../itemsize/" + id + "/" + child }}>
                  {child}
                </span>
              ) : (
                <>
                  {child}
                  <EditIcon style={{
                    fontSize: "12px",
                    color: "#2173FE",
                    cursor: "pointer"
                  }}
                    onClick={() => { window.location.href = "../itemsize/" + id + "/" + child + "/" + checkExists(sizeData, child) }}
                  />
                </>
              )}
              <br />
            </span>
          ))
        }
      </span>
    )
  }

  function checkExists(data: any[], value: string) {
    for (const e of data) {
      console.log(e);
      if (e.color == value || e.size == value) {
        return e.id;
      }
    }
    return false;
  }

  function organizeColor(color: Map<string, string>, id: number | string, colors: any[]) {
    return (
      <>
        {Array.from(objectToMap(color).entries()).map(([key, value], i) => (
          <span style={{ display: "flex", alignItems: "center" }}>
            <br />
            {!checkExists(colors, value) ? (
              <span style={{ cursor: "pointer" }} onClick={() => { window.location.href = "../itemcolor/" + id + "/" + value.replace("#", "") }}>
                <span>{key}</span>
                <span style={{ color: value, fontSize: "20px" }}>●</span>
              </span>
            ) : (
              <>
                <span>
                  <span>{key}</span>
                  <span style={{ color: value, fontSize: "20px" }}>●</span>
                </span>
                <EditIcon style={{
                  fontSize: "12px",
                  color: "#2173FE",
                  cursor: "pointer"
                }}
                  onClick={() => { window.location.href = "../itemcolor/" + id + "/" + value.replace("#", "") + "/" + checkExists(colors, value) }}
                />
              </>
            )}
          </span>
        ))}
      </>
    )
  }

  function renderExtra(childe: any) {
    console.log(childe)
    if (childe != null)
      return childe.map((child: any) => (
        <>
          <br />
          {
            Array.from(objectToMap(child).entries()).map(([key, value], i) => (
              <>
                <br />
                {key}:
                <span>{value}</span>
              </>
            ))
          }
        </>
      ))
    else {
      return "No Extra"
    }
  }

  // useEffect(() => {
  function ConvertToTable() {
    const updatedItemData: any = items.map((element: ItemType, i) => ({
      id: element.id,
      img: "https://api.kolshein.online/storage/" + element.image,
      data: [
        0,
        // <span style={{ cursor: "pointer" }} onClick={() => { window.location.href = "../subcategories/" + element.uuid }}>{element.name}</span>,
        localStorage.getItem('language') == "1" ? element.name : element.name_ar,
        localStorage.getItem('language') == "1" ? element.description : element.description_ar,
        // Array.isArray(element.size) ? (element.size ? element.size.join(' - ') : '') : "",


        // Array.isArray(
        //   localStorage.getItem('language') == "1" ? element.size : element.size_ar)
        //   ? (localStorage.getItem('language') == "1" ? element.size : element.size_ar ?
        //     organizeSizes(
        //       localStorage.getItem('language') == "1" ? element.size : element.size_ar,
        //       element.id, element.size_details ? element.size_details : []) :
        //     "No size details") :
        //   "No size details",

        Array.isArray(
          element.size)
          ? (element.size ?
            organizeSizes(
              element.size,
              element.id, element.size_details ? element.size_details : []) :
            "No size details") :
          "No size details",


        organizeColor(element.basic_color, element.id, element.colors ? element.colors : []),
        element.original_price,
        element.sold ? "true" : "false",
        element.sold_price,
        element.in_stock ? "true" : "false",
        element.stock_quantity,
        element.item_code,
        // Array.isArray(element.ingredients) ? (element.ingredients.length > 0 ? element.ingredients.join(' - ') : 'No Ingredient') : "No Ingredient",
        // <>
        //   {
        //     renderExtra(element.extra).length > 0 ? renderExtra(element.extra) : "No Extra"
        //   }
        // </>,
        // element.combo ? "true" : "false",
        // element.combo_price,
      ],
      icons: [
        {
          mode: 'linked',
          icon: <EditIcon />,
          color: "#2173FE",
          link: "/itemsmanage/" + element.id
        },
        {
          mode: 'delete',
          item: <DeleteItems icon={
            {
              color: "red",
              icon: <DeleteIcon />
            }
          } id={element.id} />
        },
      ],
    }));
    setItemData([...updatedItemData]);
  }
  // }, [items]);

  function checkDialogAnswer(data: { clicked: boolean, id: string, mode: string }) {
    // console.log(data.clicked)
    // console.log(data.id)
    // console.log(data.mode)
  }

  async function pageChange(data: { page: number }) {
    setPage(data.page)
    fetch()
    ConvertToTable();
  }
  return (
    <>
      {notfound && (<h3>No Items found</h3>)}
      {itemData.length > 0 ? (
        <Tables heads={heads} rows={itemData} last_page={last_page} setpage={pageChange} page={page} dialogAnswerMultiTab={checkDialogAnswer} />
      ) : (
        !notfound && loadingTable()
      )}
    </>
  );
}

export default Items;
