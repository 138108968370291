import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, createStyles } from '@mui/styles';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';

import IconExpandLess from '@mui/icons-material/ExpandLess'
import IconExpandMore from '@mui/icons-material/ExpandMore'
import AppMenuItemComponent from './AppMenuItemComponent';

// React runtime PropTypes
export const AppMenuItemPropTypes = {
    title: PropTypes.string.isRequired,
    to: PropTypes.string,
    arrow: PropTypes.string,
    icon: PropTypes.element,
    sublist: PropTypes.array,
}

export type AppMenuItemPropsWithClick = AppMenuItemPropTypes & {
    onTitleClick: (title: string) => void;
    clss:any
};

type AppMenuItemPropTypes = PropTypes.InferProps<typeof AppMenuItemPropTypes>
type AppMenuItemPropsWithoutItems = Omit<AppMenuItemPropTypes, 'sublist'>

// Improve child items declaration
export type AppMenuItemProps = AppMenuItemPropsWithoutItems & {
    sublist?: AppMenuItemProps[]
}
var distitle: string
const AppMenuItem: React.FC<AppMenuItemPropsWithClick> = props => {
    const { title, to, icon, sublist = [], onTitleClick } = props
    const classes = useStyles()
    const isExpandable = sublist && sublist.length > 0
    const [open, setOpen] = React.useState(false)

    function handleClick(s: string) {
        onTitleClick(s);
        setOpen(!open)
    }

    const MenuItemRoot = (
        <AppMenuItemComponent className={props.clss} link={to} onClick={() => handleClick(title)}>
            <div className={classes.smallic} key={title}></div>
            {!!icon && (
                <ListItemIcon className={classes.menuItemIcon}>
                    {icon}
                </ListItemIcon>
            )}
            <ListItemText primary={title} inset={!icon} style={{marginLeft:"-20px"}}/>
            {isExpandable && !open && <IconExpandMore />}
            {isExpandable && open && <IconExpandLess />}
        </AppMenuItemComponent>
    )

    const MenuItemChildren = isExpandable ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
            {/* <List component="div" disablePadding>
                {sublist.map((item, index) => (
                    <AppMenuItem {...item} key={index} />
                ))}
            </List> */}
            <List component="nav" disablePadding>
                {sublist.map((item, index) => (
                    <AppMenuItem clss={classes.menuSubItem} {...item} key={index} onTitleClick={onTitleClick} />
                ))}
            </List>
        </Collapse>
    ) : null

    return (
        <>
            {MenuItemRoot}
            {MenuItemChildren}
        </>
    )
}


const useStyles = makeStyles(theme =>
    createStyles({
        smallic: {
            display:'none',
            width: '6px',
            height: '30px',
            flexShrink: '0',
            borderRadius: '0px 20px 20px 0px',
            background: '#32A7FD',
            position: 'relative',
            left: '-38px'
        },
        menuSubItem: {
            '&.active div': {
                display:'block'
            },
            '&.active': {
                borderRadius: '8px',
                // background: 'linear-gradient(180deg, #2F9AFE 0%, #257DFE 45.31%, #309EFE 95.83%)',
                // color: 'blue',
                background: 'linear-gradient(180deg, #2F9AFE 0%, #257DFE 45.31%, #309EFE 95.83%)',
                color: 'white',
                '& .MuiListItemIcon-root': {
                    color: 'white'
                },
            },
        },
        menuItemIcon: {
            color: '#3A3541',
        },
    }),
)

export default AppMenuItem