import React, { ChangeEvent, SetStateAction } from 'react'
import { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import ImageUpload from '../../components/DragUpload';
import CategoriesController from '../../controllers/Categories/CategoriesController';
import handleApiResponse from '../../services/ApiResponse';
import { CircularProgress } from '@mui/material';
import { AlertDialog } from '../../components/Dialog';
import { useParams } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { GetCategories, GetCategoriesP } from '../../components/Categories/GetCategories';
import Alert from '@mui/material/Alert';
import Validation from '../../components/Validation';
import {Chip,Paper} from '@mui/material';
import CompaniesController from '../../controllers/Companies/CompaniesController';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import BannersController from '../../controllers/Banners/BannersController';
interface obj {
  type:number,
  form:number,
  description:string,
  image?: string | File,
  web_image?: string | File,
  company_id:string | null,
  category_id?:number,
  items_id:Array<String>,
  items_code:Array<string>
}

interface CategoriesData {
  id: number,
  image: string,
  name_ar: string,
  name_en: string,
  parent: null | number,
}


function BannerCreate() {

  //const [type , setType] = useState(1);
  

  const [itemCode, setItemCode] = useState("")
  const [bannerData, setBannerData] = useState<obj>({
    type: 0,
    form: 0,
    description: "",
    image: "" || new File([""], "", { type: 'image/png' }),
    web_image: "" || new File([""], "", { type: 'image/png' }),
    company_id: localStorage.getItem("company_id") ? localStorage.getItem("company_id") : "",
    category_id : 0,
    items_id : [],
    items_code : []
  });

  const { id } = useParams();
    useEffect(() => {
        const fetchData = async () => {
          try {
            let response
            if (id) {
              response = await BannersController.getBannerById(id)
              const apiResponse = handleApiResponse(response)
              if (apiResponse.success) {
                setBannerData(apiResponse.data)
              }
            }
          } catch (error) {
            console.log(error);
          }
        };
        fetchData();
        return () => {
        }
      }, []);

  const changeType = (event: SelectChangeEvent) =>{
    setBannerData({
      ...bannerData,
      type: parseInt(event.target.value) 
    });
  }
  
  const changeForm = (event: SelectChangeEvent) =>{
    setBannerData({
      ...bannerData,
      form: parseInt(event.target.value) 
    });
  }
  
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setBannerData({
      ...bannerData,
      [name]: value
    });
  };

  const [loading, setLoading] = useState(false);
  const [opendi, setOpendi] = React.useState(false)
  const [datadia, setDiag] = useState({
    title: 'Category',
    message: 'Category created successfuly',
    mode: 'normal'
  })

  function checkDialogAnswer(data: { clicked: boolean; }) {
    setOpendi(false)
    window.location.reload();
  }


  function objectToFormData(obj: Record<string, any>): FormData {
    const formData = new FormData();
    
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        
        if (Array.isArray(value)) {
          /* for (let i = 0; i < value.length; i++) {
            formData.append(`${key}[]`, value[i]);
          } */
          if(key=="items_code")
          {
            formData.append("items_code",JSON.stringify(bannerData.items_code))
          }
          
          
        } else {
          console.log(key, value)
          if(key=="category_id")
          {
            if(bannerData.category_id!=0)
            {
              formData.append("category_id",JSON.stringify(bannerData.category_id))
            }
          }else{
            formData.append(key, value);
          } 
          
          
        }
      }
    }
    
    return formData;
  }


  async function uploadBanner() {
    setLoading(true)
    try {
      const response = await BannersController.createBanner( objectToFormData(bannerData))
      const apiResponse = handleApiResponse(response)
      if (apiResponse.success) {
        datadia.title = "Success"
        datadia.message = "Banner created successfully"
        setOpendi(true)
      } else {
        console.error('Failed to create banner.');
      }
    } catch (error: any) {
      datadia.title = "Error"
      datadia.message = "Error creating banner"
      setOpendi(true)
      console.error('Error creating banner:', error);
    }
    setLoading(false)
  }
  
  async function editBanner() {
    setLoading(true)
    try {
      console.log("web image=")
      console.log(bannerData.web_image)
      if(id)
      {
        if(typeof bannerData.image == "string")
        {
          delete bannerData.image
        }
        if(typeof bannerData.web_image == "string" || bannerData.web_image==null)
        {
          delete bannerData.web_image
        }
        if(bannerData.category_id==null)
        {
          delete bannerData.category_id
        }
        const response = await BannersController.updateBanner(id, objectToFormData(bannerData))
        const apiResponse = handleApiResponse(response)
        if (apiResponse.success) {
          datadia.title = "Success"
          datadia.message = "Banner updated successfully"
          setOpendi(true)
        } else {
          console.error('Failed to update banner.');
        }
      }
      
    } catch (error: any) {
      datadia.title = "Error"
      datadia.message = "Error updating banner"
      setOpendi(true)
      console.error('Error updating banner:', error);
    }
    setLoading(false)
  }

  const handleImageUpload = (files: FileList | null) => {
    if (files) {
      bannerData.image = files[0]
      console.log(bannerData.image);
    }
  };
  const handleWebImageUpload = (files: FileList | null) => {
    if (files) {
      bannerData.web_image = files[0]
      console.log(bannerData.web_image);
    }
  };
  useEffect(() => {
    loadSubCategories()
  }, [bannerData])
  const [categories, setCategories] = useState<CategoriesData[]>([]);
  async function loadSubCategories() {
    try {
      /* let response
      console.log("DDDDD")
      console.log(bannerData)
      if (bannerData.company_id)
        response = await CompaniesController.getCompanyById("" + bannerData.company_id)
      // response = await ItemsController.getCompanyCategories("" + itemdata.company_id)
      const apiResponse = handleApiResponse(response)
      if (apiResponse.success) {
        let response2
        if (apiResponse.data.category)
          response2 = await CategoriesController.getSubCategory(apiResponse.data.category)
        const apiResponse2 = handleApiResponse(response2)
        // console.log(apiResponse2.data)
        if (apiResponse2.success) {
          setCategories(apiResponse2.data)
        }
      } */
      const response2 = await CategoriesController.getAllCategories()
      const apiResponse2 = handleApiResponse(response2)
      // console.log(apiResponse2.data)
      if (apiResponse2.success) {
        setCategories(apiResponse2.data)
      }
    } catch (error) {
      console.log(error);
    }
  }
  console.log(bannerData);

  const [loadCat, setloadCat] = useState(false);
  const [subsCatDrop, setSubCatDrop] = useState<Map<string, any>>(new Map());
  const changecat = (event: SelectChangeEvent) => {
    setBannerData({
      ...bannerData,
      category_id: parseInt(event.target.value) 
    });
    setloadCat(true)
    let name = event.target.name
    Array.from(subsCatDrop.entries()).map(([key, value]) => {
      if (parseInt(key) >= parseInt(name)) {
        subsCatDrop.delete(key)
      }
      setSubCatDrop(subsCatDrop)
    })
    const fetchData = async () => {
      const data = await GetCategoriesP(parseInt(event.target.value), false);
      if (data.length > 0) {
        setSubCatDrop(new Map(subsCatDrop).set(name, data))
        setloadCat(false)
      }
      else
        setloadCat(false)
    };
    fetchData();
  };

  const handleAppendIng = () => {
    if (bannerData.items_code.indexOf(itemCode) == -1 && itemCode != "") {
      setBannerData({
        ...bannerData,
        items_code: [...bannerData.items_code, itemCode]
      })
      setItemCode("")
    }
  };

  const handleDeleteIng = (data: string) => () => {
    let arindex = bannerData.items_code.indexOf(data)
    setBannerData({
      ...bannerData,
      items_code: bannerData.items_code.filter(item => item !== data)
    })
  };

  return (
    <div className='holder'>
      <Card className='boxcard'>
      {id!=null &&
        <h2 style={{ textAlign: "center" }}>
        Edit banner
        </h2>
      }
      {id==null &&
        <h2 style={{ textAlign: "center" }}>
        Add banner
        </h2>
      }
        <Stack direction="column" flex={1}>
          <p>Type:</p>
        </Stack>
        <Stack direction="column" flex={1}>
        <Select
              className='inpp'
              value={""+ bannerData.type}
              label="Type"
              name={'0'}
              onChange={changeType}
            >
             
              <MenuItem value={1}>{1}</MenuItem>
              <MenuItem value={2}>{2}</MenuItem>
              <MenuItem value={3}>{3}</MenuItem>
              <MenuItem value={4}>{4}</MenuItem>
              <MenuItem value={5}>{5}</MenuItem>
              <MenuItem value={6}>{6}</MenuItem>
              <MenuItem value={7}>{7}</MenuItem>
              <MenuItem value={8}>{8}</MenuItem>
              <MenuItem value={9}>{9}</MenuItem>
              <MenuItem value={10}>{10}</MenuItem>
              
            </Select>
        </Stack>
        <Stack direction="column" flex={1}>
          <p>Form:</p>
        </Stack>
        <Stack direction="column" flex={1}>
        <Select
              className='inpp'
              value={""+bannerData.form}
              label="Type"
              name={'0'}
              onChange={changeForm}
            >
             
              <MenuItem value={1}>{"Carousel"}</MenuItem>
              <MenuItem value={2}>{"Linear"}</MenuItem>
              <MenuItem value={3}>{"Grid"}</MenuItem>
              
            </Select>
        </Stack>
        <Stack direction="column" flex={1}>
              <p>Description:</p>
              <TextField
                variant="outlined"
                className='inpp'
                label="Description"
                value={bannerData.description}
                name='description'
                onChange={handleChange} 
                />
            </Stack>
            
              <Stack direction="column" flex={1}>
              <p>Category:</p>
            </Stack>
            <Stack direction="column" flex={1}>
            <Select
                  className='inpp'
                  value={""+bannerData.category_id}
                  label="Type"
                  name={'0'}
                  onChange={changecat}
                >
                 
                 {categories.map((category, i) => (
                    <MenuItem value={category.id}>{category.name_en}</MenuItem>
                  ))}
                  
                </Select>
                {
                  Array.from(subsCatDrop.entries()).map(([key, value], i) => (
                    <>
                      <Select
                        className='inpp'
                        value={""+bannerData.category_id}
                        label={"Sub Category " + key}
                        name={"" + (i + 1)}
                        onChange={changecat}
                      >
                        {value.map((v: any) => (
                          <MenuItem value={v.id}>{
                            localStorage.getItem('language') == "1" ? v.name_en : v.name_ar
                          }</MenuItem>
                        ))}
                      </Select>
                    </>
                  ))
                }
                {
                  loadCat &&
                  <CircularProgress className='boxbutton' style={{
                    margin: "auto",
                    marginTop: "10px",
                  }} />
                }
            </Stack>
            
        
          <Stack direction="row" marginTop={5}>
            <Stack direction="column" flex={1}>
              <TextField
                label="Item Code"
                variant="outlined"
                className='inpp'
                value={itemCode}
                name='code'
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setItemCode(event.target.value);
                }} />
            </Stack>
            
            <AddCircleOutlineIcon onClick={handleAppendIng} style={{ fontSize: "50px", margin: "auto", cursor: "pointer", color: "#2173FE" }} />
          </Stack>
          {bannerData.items_code.length > 0 &&
            <>
              <p>Items Code</p>
              <Paper
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  listStyle: 'none',
                  p: 0.5,
                  m: 0,
                }}
                component="ul"
              >
                {bannerData.items_code.map((data) => {
                  let icon;
                  return (
                    <Chip
                      icon={icon}
                      label={data}
                      onDelete={handleDeleteIng(data)}
                    />
                  );
                })}
              </Paper>
            </>
          }
          <br />
          <Stack direction="column" flex={1}>
              <p>Mobile Banner Image:</p>
            </Stack>
          <ImageUpload onImageUpload={handleImageUpload} imageFile={bannerData.image}/>
          <Stack direction="column" flex={1}>
              <p>Web Banner Image:</p>
            </Stack>
          <ImageUpload onImageUpload={handleWebImageUpload} imageFile={bannerData.web_image}/>
          { id==null &&
            <Stack direction="row" style={{marginTop:"25px"}}>
            <Stack direction="column" flex={1}>
            {loading ? (
          <CircularProgress className='boxbutton' style={{
            marginTop: "10px"
          }} />) : (
          <Button variant="contained" className='boxbutton' onClick={uploadBanner} disableElevation style={{
            backgroundColor: "#2173FE",
            marginTop: "10px"
          }}>
            Create
          </Button>
          )}
        <AlertDialog opendialog={opendi} datadia={datadia} dialogAnswer={checkDialogAnswer} />
            </Stack>
            </Stack>
          }
          { id!=null &&
            <Stack direction="row" style={{marginTop:"25px"}}>
            <Stack direction="column" flex={1}>
            {loading ? (
          <CircularProgress className='boxbutton' style={{
            marginTop: "10px"
          }} />) : (
          <Button variant="contained" className='boxbutton' onClick={editBanner} disableElevation style={{
            backgroundColor: "#2173FE",
            marginTop: "10px"
          }}>
            Update banner
          </Button>
          )}
        <AlertDialog opendialog={opendi} datadia={datadia} dialogAnswer={checkDialogAnswer} />
            </Stack>
            </Stack>
          }
      
      
      </Card>
      {/* <ImageUpload onImageUpload={handleImageUpload} imageFile={bannerData.image} />
      {loading ? (
        <CircularProgress className='boxbutton' style={{
          marginTop: "10px"
        }} />) : (<Button variant="contained" className='boxbutton' onClick={uploadBanner} disableElevation style={{
          backgroundColor: "#2173FE",
          marginTop: "10px"
        }}>
          Upload
        </Button>)}
      <AlertDialog opendialog={opendi} datadia={datadia} dialogAnswer={checkDialogAnswer} /> */}
    </div>
  )
}

export default BannerCreate