import UserModel from '../../models/Users/UserModel'
import ApiService from './UserApiController';

class UserController {
    static signInUser(credentials: any) {
        return ApiService.signInUser(credentials);
    }

    static createUser(credentials: any) {
        return ApiService.createUser(credentials);
    }

    static changePassword(credentials: any) {
        return ApiService.changePassword(credentials);
    }

    static resetPassword(credentials: any) {
        return ApiService.resetPassword(credentials);
    }

    static editUser(id: string, updateddata: any) {
        return ApiService.editUser(id, updateddata);
    }

    static deleteUser(id: string | number) {
        return ApiService.deleteUser(id);
    }

    static assignAdmin(id: string, adminAR: object) {
        return ApiService.assignAdmin(id, adminAR);
    }

    static receiveOTP(phone: object) {
        return ApiService.receiveOTP(phone);
    }

    static verifyOTP(phoneOTP: object) {
        return ApiService.verifyOTP(phoneOTP);
    }

    static getUser(id: string) {
        return ApiService.getUser(id);
    }

    static getSearchUser(id: string, page: number) {
        return ApiService.getSearchUser(id, page);
    }

    static getAllUser(page: number) {
        return ApiService.getAllUser(page);
    }
}

export default UserController;
