import axios from 'axios';
import api from '../../services/apiMain';

const headers = { "Content-Type": "multipart/form-data" }
const ItemApiService = {
    createItem: (data: FormData) => {
        return api.post('/api/items', data, { headers });
    },
    updateItem: (id: string | number, data: FormData) => {
        return api.post('/api/item/' + id, data, { headers });
    },
    createItemColor: (data: FormData) => {
        return api.post('/api/items/color', data, { headers });
    },
    createItemSize: (data: FormData) => {
        return api.post('/api/items/size', data, { headers });
    },
    deleteItem: (id: string | number) => {
        return api.delete('/api/item/' + id);
    },
    updateItemColor: (id: string | number, data: FormData) => {
        return api.post('/api/items/color/' + id, data, { headers });
    },
    deleteItemColor: (id: string | number) => {
        return api.delete('/api/item/color/' + id);
    },
    updateItemSize: (id: string | number, data: FormData) => {
        return api.post('/api/items/size/' + id, data, { headers });
    },
    deleteItemSize: (id: string | number) => {
        return api.delete('/api/item/size/' + id);
    },
    getItemById: (id: string | number) => {
        return api.get('/api/items/' + id);
    },
    getItemColor: (id: string | number) => {
        return api.get('/api/item/colors/' + id);
    },
    getItemSize: (id: string | number) => {
        return api.get('/api/item/size/' + id);
    },
    getAllItems: (page: number) => {
        return api.get('/api/items?page=' + page);
    },
    getItemsCompany: (id: string | number, page: number) => {
        return api.get('/api/items/company/' + id + "?page=" + page);
    },
    getItemsCategory: (id: string | number, page: number) => {
        return api.get('/api/items/category/' + id + "?page=" + page);
    },
    getItemsSearch: (id: string | number, page: number) => {
        return api.get('/api/search/item/' + id + "?page=" + page);
    },
    getItemsCompanyCategory: (company_id: string | number, category_id: string | number, page: number) => {
        return api.get('/api/items/company/' + company_id + '/category/' + category_id + "?page=" + page);
    },
    getCompanyCategories: (company_id: string | number) => {
        return api.get('/api/categories/company/' + company_id);
    },
};

export default ItemApiService;
