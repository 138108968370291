import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
    children: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    let user = true;
    const token = localStorage.getItem('token');
    console.log('Token:', token);

    if (!token) {
        user = false;
    }

    if (!user) {
        return <Navigate to="/users" replace />;
    }

    return <>{children}</>;
};

export default ProtectedRoute;
