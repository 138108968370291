import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid';
import CategoriesController from '../../controllers/Categories/CategoriesController';
import handleApiResponse from '../../services/ApiResponse';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteAccount from '../../components/Users/DeleteAccount';
import DeleteCategory from '../../components/Categories/DeleteCategory';
import { useParams } from 'react-router-dom';
import { GetCategories } from '../../components/Categories/GetCategories';
import { loadingGrid } from '../../components/loadingSkeleton';

interface Categories {
  id: number,
  name_en: string,
  name_ar: string,
  image: string,
  parent: null
}


function SubCategories() {
  const { id } = useParams();

  const { categories } = GetCategories(id, false);

  return (
    <div className='holder'>
      {
        categories ? (
          <Grid container rowSpacing={4} columnSpacing={{ xs: 6, sm: 7, md: 4 }}>
            {categories.length > 0 ? (
              categories?.map((catobject) => (
                <Grid item xs={4}>
                  <Card variant="outlined"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      padding: "10px"
                    }}>
                    <div>
                      <br />
                      {/* <img width={100} height={100} style={{
                  borderRadius: "50%"
                }} src={"https://api.kolshein.online/storage/" + catobject["image"]} alt="" /> */}
                      <img width={100} height={100} style={{
                        borderRadius: "50%",
                        cursor: "pointer"
                      }} src={"https://api.kolshein.online/storage/" + catobject["image"]} alt=""
                        onClick={() => { window.location.href = "../../../categories/" + catobject.id }} />
                      <h4 style={{ color: "#2173FE" }}>{
                        localStorage.getItem('language') == "1" ? catobject.name_en : catobject.name_ar}
                      </h4>

                      <Stack direction="row" flex={1} justifyContent={"space-around"}>
                        <EditIcon style={{ color: "#2173FE", cursor: "pointer" }} onClick={() => { window.location.href = "../../../categorymanage/" + catobject.id }} />
                        <DeleteCategory icon={
                          {
                            color: "red",
                            icon: <DeleteIcon />
                          }
                        } id={catobject.id} />
                      </Stack>
                    </div>

                  </Card>
                </Grid>
              ))
            ) : (
              loadingGrid()
            )}
          </Grid>
        ) : (
          loadingGrid()
        )
      }
    </div>
  )
}

export default SubCategories;
