import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import { useParams } from 'react-router-dom';
import BillsController from '../../controllers/Orders/OrdersController';
import handleApiResponse from '../../services/ApiResponse';
import { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { convertDate } from '../../components/DateConverter';
import DeleteOrder from '../../components/Orders/DeleteOrder';
import DeleteIcon from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import EditIcon from '@mui/icons-material/Edit';
import BannersController from '../../controllers/Banners/BannersController';
import DeleteBanner from '../../components/Banners/DeleteBanners';




function Banners() {

    
    const [banners, setBanners] = React.useState<any>([]);
    const [noData, setNoData] = React.useState<any>(false);

    
    
    async function fetchBanners() {
        let result;
        
        result = await BannersController.getAllBanners();
        const apiResponse = handleApiResponse(result)
        if (apiResponse.success) {
            setBanners(apiResponse.data)
            if (Array.isArray(banners.data)) {
                if (banners.data.length == 0)
                    setNoData(true)
            }
            else
                setNoData(true)
        }
        else {
            setNoData(true)
        }
        
    }

    


    

    useEffect(() => {
        fetchBanners()
    }, [])

    useEffect(() => {
        console.log(banners)
    }, [banners])


    return (
        <>
            {/* <div style={{ height: 300, width: '100%' }}>
        <DataGrid {...ordersBill} slots={{ toolbar: GridToolbar }} />
      </div> */}


            <Card id="j" style={{
                marginBottom: "20px"
            }}>

            </Card>
            <Box sx={{ width: '100%' }}>
                <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                    {banners.map((item: any) => (
                        <Grid
                            // style={{
                            //   margin:"auto"
                            // }}
                            key={item.id}
                            item
                            xs={12}
                            sm={6}
                            md={4}>
                            <Card sx={{ maxWidth: 345 }}>
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        height="200"
                                        width="345"
                                        image={"https://api.kolshein.online/storage/" + item.image}
                                        style={{ objectFit: 'fill' }}
                                    />
                                    <CardContent>
                                        <p><b>Banner ID: </b>{item.id}</p>
                                        <p><b>Banner type: </b>{item.type}</p>
                                        <p><b>Banner form: </b>{item.form}</p>
                                        {item.description!=null &&
                                            <p><b>Description: </b>{item.description}</p>
                                        }
                                        {item.company_id!=null &&
                                            <p><b>Company ID: </b>{item.company_id}</p>
                                        }
                                        {item.category_id!=null &&
                                            <p><b>Category ID: </b>{item.category_id}</p>
                                        }
                                        {/* <p><b>Items IDS: </b>{item.items_id}</p> */}
                                        {item.item_code!=null &&
                                            <p><b>Items Codes: </b>{item.items_code}</p>
                                        }
                                        
                                    </CardContent>
                                </CardActionArea>
                                <center style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:'20px',paddingRight:'20px'}}>
                                    <DeleteBanner id={item.id} icon={
                                        {
                                            color: "red",
                                            icon: <DeleteIcon />
                                        }
                                    } />
                                    <EditIcon style={{
                                        fontSize: "25px",
                                        color: "#0000FF",
                                        cursor: "pointer"
                                    }}
                                        onClick={() => { window.location.href = "../newbanner/" + item.id }}
                                    />
                                </center>


                                {/* <CardActions>
                <Button size="small" color="primary">
                  Edit
                </Button>
              </CardActions> */}
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </>
    )
}

export default Banners;

