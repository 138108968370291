import axios from 'axios';
import api from '../../services/apiMain';

const headers = { "Content-Type": "multipart/form-data" }
const BillApiService = {
    getBillOrders: (id: string | number) => {
        return api.get('/api/bills/orders/' + id);
    },
    getBillById: (id: string | number) => {
        return api.get('/api/bill/' + id);
    },
    getCompanyBills: (id: string | number, page: number, status: string) => {
        return api.get('/api/bills/company/' + id + "/" + status + "?page=" + page);
    },
    getUserBills: (id: string | number, status: string) => {
        return api.get('/api/bills/user/' + id + "/" + status);
    },
    getOrderById: (id: string | number) => {
        return api.get('/api/order/' + id);
    },
    updateOrderById: (id: string | number) => {
        return api.patch('/api/order/' + id);
    },
    deleteOrderById: (id: string | number) => {
        return api.delete('/api/order/' + id);
    },
    updateOrderStatus: (id: string | number, data: Object) => {
        return api.patch('/api/bill/status/' + id, data);
    }
};

export default BillApiService;
