import React, { Component } from 'react';
import Chart, { ChartTypeRegistry } from 'chart.js/auto';

interface AppProps {
  chartdata: any;
  type: any;
  ids: string;
  chartoptions: any;
  style?:object
}

class Charts extends Component<AppProps> {
  private chartRef: React.RefObject<HTMLCanvasElement>;
  private chartInstance: Chart | null = null;

  constructor(props: AppProps) {
    super(props);
    this.chartRef = React.createRef();
  }

  componentDidMount() {
    if (this.chartRef.current) {
      const ctx = this.chartRef.current.getContext('2d');
      if (ctx) {
        if (this.chartInstance) {
          this.chartInstance.destroy(); // Destroy the previous instance if exists
        }
        this.chartInstance = new Chart(ctx, {
          type: this.props.type,
          data: this.props.chartdata,
          options: this.props.chartoptions,
        });
      }
    }
  }

  render() {
    return (
      <canvas
        style={this.props.style}
        ref={this.chartRef}
        id={this.props.ids}
      ></canvas>
    );
  }
}

export default Charts;
