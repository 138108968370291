import axios from 'axios';
import api from '../../../services/apiMain';

const headers = { "Content-Type": "multipart/form-data" }
const SizesApiController = {
    createSize: (data: object) => {
        return api.post('/api/size',data);
    },
    getAllSizes: () => {
        return api.get('/api/size');
    },
    getSizeById: (id: string | number) => {
        return api.get('/api/size/'+id);
    },
    updateSize: (id: string | number, data: object) => {
        return api.patch('/api/size/'+id,data);
    },
    deleteSize: (id: string | number) => {
        return api.delete('/api/size/'+id);
    },
};

export default SizesApiController;
