import React, { ChangeEvent, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button'
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Alert, CircularProgress, Skeleton } from '@mui/material';
import { AlertDialog } from '../../components/Dialog';
import EditIcon from '@mui/icons-material/Edit';
import DeleteAccount from '../../components/Users/DeleteAccount';
import UserController from '../../controllers/Users/UserController';
import handleApiResponse from '../../services/ApiResponse';
import Validation from '../../components/Validation';

function Profile() {
    const { id } = useParams();
    const [errors, setError] = useState<string[]>([]);

    const [editable, setEditable] = React.useState(false)
    const [loaded, setLoad] = React.useState(false)

    const [userData, setUserData] = useState(
        {
            full_name: "",
            phone_number: "",
            email: "",
            date_of_birth: "",
            gender: "",
            language: 1,
            id: ''
        });

    const [loading, setLoading] = useState(false);
    const [opendi, setOpendi] = React.useState(false)
    const [datadia, setDiag] = useState({
        title: 'User',
        message: 'user created successfuly',
        mode: 'normal'
    })

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setUserData({
            ...userData,
            [name]: value
        });
    };

    const changegender = (event: SelectChangeEvent) => {
        setUserData({
            ...userData,
            gender: event.target.value
        });
    };


    async function handleSave() {

        const { errors, flag } = Validation(
            [
                [userData.full_name, "Full name is required", 0],
                [userData.email, "Email is required", 0],
                [userData.phone_number, "Phone number is required", 0],
                [userData.gender, "Gender is required", 0],
                [userData.date_of_birth, "DOB is required", 0],
            ]
        )
        setError(errors)
        if (flag == 1000) {
            setLoading(true);
            try {
                let response, idedit
                if (id)
                    idedit = id
                else
                    idedit = localStorage.getItem('id')
                if (idedit != null) {
                    response = await UserController.editUser(idedit, userData)
                    console.log(userData)
                }

                const apiResponse = handleApiResponse(response)

                if (apiResponse.success) {
                    datadia.title = "Saved"
                    datadia.message = "User updated"
                    setOpendi(true)
                }
            } catch (error: any) {
                console.log(error)
                datadia.title = "Invalid"
                datadia.message = "Something went wrong please check your data"
                setOpendi(true)
            }
            setLoading(false);
        }
    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                let response, idp
                if (id)
                    idp = id;
                else
                    idp = localStorage.getItem('id');
                if (idp != null) {
                    response = await UserController.getUser(idp)
                    const apiResponse = handleApiResponse(response)
                    if (apiResponse.success) {
                        userData.full_name = apiResponse.data.full_name
                        userData.phone_number = apiResponse.data.phone_number
                        userData.email = apiResponse.data.email
                        userData.date_of_birth = apiResponse.data.date_of_birth
                        userData.gender = apiResponse.data.gender
                        userData.id = apiResponse.data.uuid
                        console.log(userData)
                        setLoad(true)
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();

        return () => {

        }
    }, []);

    function handleEditable(event: any) {
        if (editable) {
            setEditable(false)
        }
        else {
            setEditable(true)
        }
    }

    function checkDialogAnswer(data: { clicked: boolean; }) {
        setOpendi(false)
        setEditable(false)
    }


    return (
        <div className='holder'>
            <Card >
                <EditIcon
                    id="editIcon"
                    style={{
                        float: "right",
                        margin: "5px",
                        color: "blue",
                        cursor: "pointer"
                    }} onClick={handleEditable} />

                {loaded ? (
                    <table style={{
                        margin: "auto",
                        marginTop: "20px",
                        marginBottom: "20px"
                    }}>
                        <tr>
                            <td><b>Name:</b></td>
                            {editable ? (
                                <td>
                                    <TextField
                                        label="Name"
                                        variant="outlined"
                                        className='inpp'
                                        value={userData.full_name}
                                        name='full_name'
                                        onChange={handleChange} />
                                </td>) :
                                (<td>
                                    {userData.full_name}
                                </td>)}
                        </tr>
                        <tr>
                            <td><b>Phone:</b></td>
                            <td>
                                {userData.phone_number}
                            </td>
                            {/* {
                                editable ? (
                                    <td>
                                        <TextField
                                            label="Phone"
                                            variant="outlined"
                                            className='inpp'
                                            value={userData.phone_number}
                                            name='phone_number'
                                            onChange={handleChange} />
                                    </td>) :
                                    (<td>
                                        {userData.phone_number}
                                    </td>)
                            } */}
                        </tr>
                        <tr>
                            <td><b>Email:</b></td>
                            <td>
                                {userData.email}
                            </td>
                            {/* {editable ? (
                                <td>
                                    <TextField
                                        label="Email"
                                        variant="outlined"
                                        className='inpp'
                                        value={userData.email}
                                        name='email'
                                        onChange={handleChange} />
                                </td>) :
                                (<td>
                                    {userData.email}
                                </td>)} */}
                        </tr>
                        <tr>
                            <td><b>DOB:</b></td>
                            {editable ? (
                                <td>
                                    <TextField
                                        label="DOB"
                                        variant="outlined"
                                        className='inpp'
                                        type='date'
                                        value={userData.date_of_birth}
                                        name='date_of_birth'
                                        onChange={handleChange} />
                                </td>) :
                                (<td>
                                    {userData.date_of_birth}
                                </td>)}
                        </tr>
                        <tr>
                            <td><b>Gender:</b></td>
                            {editable ? (
                                <td>
                                    <Select
                                        className='inpp'
                                        value={userData.gender}
                                        label="Category"
                                        onChange={changegender}
                                    >
                                        <MenuItem value={"male"}>Male</MenuItem>
                                        <MenuItem value={"female"}>Female</MenuItem>
                                    </Select>
                                </td>) :
                                (<td>
                                    {userData.gender}
                                </td>)}
                        </tr>
                    </table>) :
                    (
                        <div style={{
                            margin: "auto",
                            marginTop: "20px",
                            marginBottom: "20px"
                        }}>
                            <Skeleton variant="rectangular" className='profileRect' />
                            <Skeleton variant="rectangular" className='profileRect' />
                            <Skeleton variant="rectangular" className='profileRect' />
                            <Skeleton variant="rectangular" className='profileRect' />
                            <Skeleton variant="rectangular" className='profileRect' />
                        </div>
                    )
                }
                {
                    id ? (<DeleteAccount text='Delete account' id={id} />)
                        : (<>
                            <small><a className='smalllink' href="/companycreate">Create a company</a></small>
                            <br />
                            <small><a className='smalllink' href="/changepassword">Change password</a></small>
                            <br />
                            <DeleteAccount text='Delete my account' id={userData.id} />
                        </>)
                }

            </Card>
            {errors.length > 0 && (
                <Alert severity="error">
                    <ul>
                        {errors.map((e) => (
                            <li>{e}</li>
                        ))}
                    </ul>
                </Alert>)}
            {loading ? (
                <CircularProgress className='boxbutton' style={{
                    marginTop: "10px"
                }} />) : (<Button variant="contained" className='boxbutton' onClick={handleSave} disableElevation style={{
                    backgroundColor: "#2173FE",
                    marginTop: "10px"
                }}>
                    Save
                </Button>)}
            <AlertDialog opendialog={opendi} datadia={datadia} dialogAnswer={checkDialogAnswer} />

        </div>
    )
}

export default Profile;