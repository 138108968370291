import { Stack, Card, TextField, Select, MenuItem, Alert, CircularProgress, Button, SelectChangeEvent } from '@mui/material'
import { AlertDialog } from '../../components/Dialog'
import { useState } from 'react';

function Languages() {
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [language, setLanguage] = useState(localStorage.getItem('language') != null ? localStorage.getItem('language') : "1");

    const changeLanguage = (event: SelectChangeEvent) => {
        setLanguage(event.target.value);
    };

    function handleSave() {
        localStorage.setItem('language', ""+language)
        setShowAlert(true)
    }

    return (
        <div className='holder'>
            <Card className='boxcard'>
                <p>Language</p>
                <Select
                    className='inpp'
                    style={{ width: "100%" }}
                    value={""+language}
                    label="Category"
                    onChange={changeLanguage}
                >
                    <MenuItem value={1}>English</MenuItem>
                    <MenuItem value={2}>Arabic</MenuItem>
                </Select>
            </Card>

            {showAlert && (
                <Alert severity="success">Language Changed</Alert>
            )}

            {loading ? (
                <CircularProgress className='boxbutton' style={{
                    marginTop: "10px"
                }} />) : (<Button variant="contained" className='boxbutton' onClick={handleSave} disableElevation style={{
                    backgroundColor: "#2173FE",
                    marginTop: "10px"
                }}>
                    Save
                </Button>)}
        </div>
    )
}

export default Languages