import React, { useState } from 'react'
import PublicIcon from '@mui/icons-material/Public';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';


function GoogleMapLocation() {
    // const [map, setMap] = React.useState(null)
    // const onLoad = React.useCallback(function callback(map: any) {
    //     const bounds = new window.google.maps.LatLngBounds(center);
    //     map.fitBounds(bounds);
    //     setMap(map)
    // }, [])

    // const onUnmount = React.useCallback(function callback(map: any) {
    //     setMap(null)
    // }, [])

    const containerStyle = {
        width: '400px',
        height: '400px'
    };

    const center = {
        lat: 37.7749,
        lng: 122.4194
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: ""
    })
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const [selectedLocation, setSelectedLocation] = useState({
        lat: 37.7749,
        lng: 122.4194
    });

    const handleMapClick = (event: { latLng: any; }) => {
        const { latLng } = event;
        const latitude = latLng.lat();
        const longitude = latLng.lng();
        setSelectedLocation({
            lat: 37.7749,
            lng: 122.4194
        });
    };

    return isLoaded ? (
        <div>
            <PublicIcon style={{ color: "#2173FE",height:"100%",width:"30px",cursor:"pointer" }} onClick={() => { setOpen(true) }} />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Location"}
                </DialogTitle>
                <DialogContent>
                    <div>
                        <GoogleMap
                            center={center}
                            mapContainerStyle={containerStyle}
                            zoom={15}
                            onClick={handleMapClick}
                        >
                            <Marker position={{ lat: 37.7749, lng: -122.4194 }} />

                        </GoogleMap>

                    </div>
                    {/* <DialogContentText id="alert-dialog-description">
                        Let Google help apps determine location. This means sending anonymous
                        location data to Google, even when no apps are running.
                    </DialogContentText> */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleClose} autoFocus>
                        Select
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    ) : (null)
}

export default GoogleMapLocation
