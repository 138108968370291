import { useEffect, useState } from 'react';
import { Tables } from '../../../components/Tables';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteSizes from '../../../components/Settings/Sizes/DeleteSizes';
import GetSizes from '../../../components/Settings/Sizes/GetSizes';

interface Size {
  id: string;
  data: Array<number | string>,
}

function Sizes() {
  const heads = ['Name', 'Size', 'Action'];
  const [sizeData, setSizeData] = useState<Size[]>([]);
  const [page, setPage] = useState(1);
  const { sizes, sizeIndex } = GetSizes();

  useEffect(() => {
    {
      const updatedSizeData = sizes.map((element: any) => ({
        id: element.id,
        data: [
          0,
          localStorage.getItem('language') == "1" ? element.name_en : element.name_ar,
          localStorage.getItem('language') == "1" ? (element.options ? element.options.join(' - ') : '') : (element.options_ar ? element.options_ar.join(' - ') : '')
        ],
        icons: [
          {
            mode: 'linked',
            icon: <EditIcon />,
            color: "#2173FE",
            link: "/newsize/" + element.id
          },
          {
            mode: 'delete',
            item: <DeleteSizes icon={
              {
                color: "red",
                icon: <DeleteIcon />
              }
            } id={element.id} />
          },
        ]
      }));
      setSizeData([...updatedSizeData])
    };
  }, [sizes]);


  function checkDialogAnswer(data: { clicked: boolean, id: string, mode: string }) {
    console.log(data.clicked)
    console.log(data.id)
    console.log(data.mode)
  }

  function pageChange(data: { page: number }) {
    setPage(data.page)
  }

  return (
    <>
      {sizeData.length > 0 ? (
        <Tables heads={heads} rows={sizeData} dialogAnswerMultiTab={checkDialogAnswer} setpage={pageChange} />
      ) : null}
    </>
  );
}

export default Sizes;