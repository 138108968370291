import axios from 'axios';
import api from '../../../services/apiMain';

const headers = { "Content-Type": "multipart/form-data" }
const ColorsApiController = {
    createColor: (data: object) => {
        return api.post('/api/color',data);
    },
    getAllColors: () => {
        return api.get('/api/color');
    },
    getColorById: (id: string | number) => {
        return api.get('/api/color/'+id);
    },
    updateColor: (id: string | number, data: object) => {
        return api.patch('/api/color/'+id,data);
    },
    deleteColor: (id: string | number) => {
        return api.delete('/api/color/'+id);
    },
};

export default ColorsApiController;
