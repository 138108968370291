import UserModel from '../../models/Users/UserModel'
import CategoriesApiService from './CategoriesApiController';

class CategoriesController {
    static getAllCategories() {
        return CategoriesApiService.getAllCategories();
    }
    static getCategoryById(id: string | number) {
        return CategoriesApiService.getCategoryById(id);
    }
    static getSubCategory(id: string | number) {
        return CategoriesApiService.getSubCategory(id);
    }
    static getSearchCategory(id: string | number) {
        return CategoriesApiService.getSearchCategory(id);
    }
    static createCategory(data: FormData) {
        return CategoriesApiService.createCategory(data);
    }
    static updateCategory(id: string, data: FormData) {
        return CategoriesApiService.updateCategory(id, data);
    }
    static deleteCategory(id: string | number) {
        return CategoriesApiService.deleteCategory(id);
    }
    static createSubCategory(data: FormData) {
        return CategoriesApiService.createSubCategory(data);
    }
    static updateSubCategory(id: string, data: FormData) {
        return CategoriesApiService.updateSubCategory(id, data);
    }
    static deleteSubCategory(id: string) {
        return CategoriesApiService.deleteSubCategory(id);
    }
}

export default CategoriesController;
