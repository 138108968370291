import { Route, Routes } from "react-router-dom";
import Dashboard from "./views/Dashboard";
import Shop from "./views/Shop";
import User from "./views/Users/User";
import UserCreate from "./views/Users/UserCreate";
import ProtectedRoute from "./components/ProtectedRoutes";
import Profile from "./views/Users/Profile";
import Changepassword from "./views/Users/changepassword";
import Resetpassword from "./views/Users/resetpassword";
import UserList from "./views/UserAccess/UserList";
import ProtectedRouteAdmin from "./components/ProtectedRoutesAdmin";
import Companycreate from "./views/Companies/Companycreate";
import CategoryCreate from "./views/Categories/Categorycreate";
import Categories from "./views/Categories/Categories";
import Companies from "./views/Companies/Companies";
import SubCategories from "./views/Categories/SubCategories";
import Settings from "./views/Settings";
import SizeCreate from "./views/Settings/Sizes/Sizecreate";
import ColorCreate from "./views/Settings/Colors/Colorcreate";
import Colors from "./views/Settings/Colors/Colors";
import Sizes from "./views/Settings/Sizes/Sizes";
import Languages from "./views/Settings/Languages";
import ItemsCreate from "./views/Items/ItemsCreate";
import ItemsList from "./views/Items/ItemsList";
import ItemColor from "./views/Items/ItemColor";
import ItemSize from "./views/Items/ItemSize";
import Orders from "./views/Orders/Orders";
import Bills from "./views/Orders/Bills";
import OrdersBill from "./views/Orders/OrdersBill";
import Banners from "./views/Banner/Banners";
import BannerCreate from "./views/Banner/Bannercreate";
import Notifications from "./views/Notifications/Notifications";

function Routess() {
    return (
        <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
            <Route path="/overview" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
            <Route path="/orders" element={<ProtectedRoute><Orders /></ProtectedRoute>} />
            <Route path="/bills/:group/:id" element={<ProtectedRoute><Bills /></ProtectedRoute>} />
            <Route path="/notifications" element={<ProtectedRoute><Notifications /></ProtectedRoute>} />
            <Route path="/newbanner" element={<ProtectedRoute><BannerCreate /></ProtectedRoute>} />
            <Route path="/newbanner/:id" element={<ProtectedRoute><BannerCreate /></ProtectedRoute>} />
            <Route path="/banners" element={<ProtectedRoute><Banners /></ProtectedRoute>} />
            <Route path="/ordersbill/:id" element={<ProtectedRoute><OrdersBill /></ProtectedRoute>} />
            <Route path="/shoplist" element={<ProtectedRoute><Shop /></ProtectedRoute>} />
            <Route path="/companies" element={<ProtectedRoute><Companies /></ProtectedRoute>} />
            <Route path="/companies/:id" element={<ProtectedRoute><Companies /></ProtectedRoute>} />
            <Route path="/companies/:group/:id" element={<ProtectedRoute><Companies /></ProtectedRoute>} />
            <Route path="/companymanage" element={<ProtectedRoute><Companycreate /></ProtectedRoute>} />
            <Route path="/companymanage/:id" element={<ProtectedRoute><Companycreate /></ProtectedRoute>} />
            <Route path="/categorymanage" element={<ProtectedRoute><CategoryCreate /></ProtectedRoute>} />
            <Route path="/categorymanage/:id" element={<ProtectedRoute><CategoryCreate /></ProtectedRoute>} />
            <Route path="/categories" element={<ProtectedRoute><Categories /></ProtectedRoute>} />
            <Route path="/categories/:id" element={<ProtectedRoute><Categories /></ProtectedRoute>} />
            <Route path="/categories/:group/:id" element={<ProtectedRoute><Categories /></ProtectedRoute>} />
            <Route path="/subcategories/:id" element={<ProtectedRoute><SubCategories /></ProtectedRoute>} />
            <Route path="/itemslist" element={<ProtectedRoute><ItemsList /></ProtectedRoute>} />
            <Route path="/itemslist/:id" element={<ProtectedRoute><ItemsList /></ProtectedRoute>} />
            <Route path="/itemslist/:group/:id" element={<ProtectedRoute><ItemsList /></ProtectedRoute>} />
            <Route path="/itemsmanage" element={<ProtectedRoute><ItemsCreate /></ProtectedRoute>} />
            <Route path="/itemsmanage/:id" element={<ProtectedRoute><ItemsCreate /></ProtectedRoute>} />
            <Route path="/itemcolor/:id/:color" element={<ProtectedRoute><ItemColor /></ProtectedRoute>} />
            <Route path="/itemcolor/:id/:color/:cid" element={<ProtectedRoute><ItemColor /></ProtectedRoute>} />
            <Route path="/itemsize/:id/:size" element={<ProtectedRoute><ItemSize /></ProtectedRoute>} />
            <Route path="/itemsize/:id/:size/:sid" element={<ProtectedRoute><ItemSize /></ProtectedRoute>} />
            <Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
            <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
            <Route path="/profile/:id" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
            <Route path="/changepassword" element={<ProtectedRoute><Changepassword /></ProtectedRoute>} />
            <Route path="/sizes" element={<ProtectedRoute><Sizes /></ProtectedRoute>} />
            <Route path="/newsize" element={<ProtectedRoute><SizeCreate /></ProtectedRoute>} />
            <Route path="/newsize/:id" element={<ProtectedRoute><SizeCreate /></ProtectedRoute>} />
            <Route path="/colors" element={<ProtectedRoute><Colors /></ProtectedRoute>} />
            <Route path="/newcolor" element={<ProtectedRoute><ColorCreate /></ProtectedRoute>} />
            <Route path="/newcolor/:id" element={<ProtectedRoute><ColorCreate /></ProtectedRoute>} />
            <Route path="/language" element={<ProtectedRoute><Languages /></ProtectedRoute>} />
            <Route path="/userlist" element={<ProtectedRouteAdmin><UserList /></ProtectedRouteAdmin>} />
            <Route path="/userlist/:group/:id" element={<ProtectedRouteAdmin><UserList /></ProtectedRouteAdmin>} />
            <Route path="/resetpassword" element={<Resetpassword />} />
            <Route path="/users" element={<User />} />
            <Route path="/createuser" element={<UserCreate />} />
        </Routes>
    );
}

export default Routess;
