import * as React from 'react';
import { useEffect, useState } from 'react';
import { styled, useTheme, alpha, createTheme } from '@mui/material/styles';

import { makeStyles, createStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import items from './items';
import AppMenuItem from '../implementations/AppMenuItem';
import Routess from '../routes';
import LogoutIcon from '@mui/icons-material/Logout';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import apiService from '../services/apiMain';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Button, InputAdornment, Stack, TextField } from '@mui/material';
import { useLocation } from 'react-router-dom';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    marginTop: 30,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  backgroundColor: "#F4F5F9",
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),

  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));


const Appbar: React.FC = () => {
  const [receivedTitle, setReceivedTitle] = useState('Dashboard');

  const handleTitleChange = (title: string) => {
    setReceivedTitle(title);
  };

  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    // position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
  }));

  const darkTheme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#ffffff00',
      },
    },
  });

  async function Logout() {
    try {
      // const response = await apiService.post('/api/user/log_out');
      // if (response.status == 200) {
      localStorage.removeItem('token')
      localStorage.removeItem('id')
      localStorage.removeItem('admin')
      localStorage.removeItem('company_id')
      window.location.href = "/users";
      // }
    } catch (error: any) {
      console.log(error)
    }
  }

  function gotoprofile() {
    window.location.href = "/profile"
  }

  useEffect(() => {
    if (window.innerWidth <= 800)
      setOpen(false)
  }, []);


  const classes = useStyles()

  const [search, setSearch] = useState('');

  const handleChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setSearch(event.target.value);
  };

  const location = useLocation();

  function handleSearch() {
    let path = location.pathname.replace("/", "")
    if (path == "companies" || path.includes("companies"))
      window.location.href = "/companies/search/" + search
    else if (path == "categories" || path.includes("categories"))
      window.location.href = "/categories/search/" + search
    else if (path == "userlist" || path.includes("userlist"))
      window.location.href = "/userlist/search/" + search
    else
      window.location.href = "/itemslist/search/" + search
  }
  function clearSearch() {
    // let path = location.pathname.replace("/", "")
    // if (path == "companies" || path.includes("companies"))
    //   window.location.href = "/companies"
    // else if (path == "categories" || path.includes("categories"))
    //   window.location.href = "/categories"
    // else if (path == "userlist" || path.includes("userlist"))
    //   window.location.href = "/userlist"
    // else
    //   window.location.href = "/itemslist"
    setSearch("")
  }


  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar theme={darkTheme} open={open}>
        <Toolbar id='titleandsearch'>
          <div id='items'>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
            <p className='titles'>
              {receivedTitle}
            </p>

            <div className='searchbar' style={{
              display: "flex",
              alignContent: "center",
              marginLeft: "15px",
            }}>
              <TextField
                style={{
                  flex: "5",
                  border: 'none'
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                }}
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
                variant='outlined'
                onChange={handleChange}
                value={search}
              />
            </div>

            {
              search != "" &&
              <div style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "25px",
                marginLeft: "10px"
              }}>
                <span
                  style={{
                    cursor: "pointer"
                  }}
                  onClick={clearSearch}
                >X</span>
                <Button variant="contained" className='boxbutton'
                  onClick={handleSearch}
                  disableElevation
                  style={{
                    backgroundColor: "#2173FE",
                    height: "30px",
                    marginLeft: "10px"
                  }}>
                  Search
                </Button>
              </div>
            }


            <div style={{ flex: 1 }} /> {/* This creates a flexible space to push the logout icon to the right */}

            <LogoutIcon style={{
              cursor: "pointer",
              margin: "auto"
            }}
              onClick={Logout} />
          </div>

        </Toolbar>

      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        {/* <List>
          {items.map((item, index) => (
            <ListItem key={item.title} disablePadding component={NavLink} to={item.to}>
              <ListItemButton onClick={() => handleClick(item.title)}>
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.title} />
                {item.sublist && item.sublist.length > 0 && !opene && <IconExpandMore />}
                {item.sublist && item.sublist.length > 0 && opene && <IconExpandLess />}
              </ListItemButton>
              <Collapse in={opene} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.sublist?.map((it) => (
                    <ListItem key={it.title} disablePadding component={NavLink} to={it.to}>
                      <ListItemButton>
                        <ListItemText primary={item.title} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </ListItem>
          ))}
        </List> */}
        <div style={{ width: '80%', margin: 'auto' }}>

          <List component="nav" disablePadding>
            {items.map((item: any, index) => (
              <>
                {item.admin && localStorage.getItem('company_id') ? (null) : (
                  <AppMenuItem clss={classes.menuItem} {...item} key={index} onTitleClick={handleTitleChange} />
                )}
              </>
            ))}
          </List>
        </div>

        <div style={{
          borderRadius: '8px',
          background: 'linear-gradient(180deg, #2F9AFE 0%, #257DFE 45.31%, #309EFE 95.83%)',
          color: 'white',
          margin: "20px"
        }}>
          <Stack direction='row' padding={1} style={{
            cursor: "pointer"
          }}
            onClick={gotoprofile}
          >
            <PersonOutlineIcon />
            <span>Profile</span>
          </Stack>
        </div>

      </Drawer>
      <Main style={{
        marginTop: "20px",
      }} open={open}>
        <DrawerHeader />
        <Routess />
      </Main>
    </Box >
  );
}


const useStyles = makeStyles(theme =>
  createStyles({
    smallic: {
      display: 'none',
      width: '6px',
      height: '30px',
      flexShrink: '0',
      borderRadius: '0px 20px 20px 0px',
      background: '#32A7FD',
      position: 'relative',
      left: '-38px'
    },
    menuItem: {
      '&.active div': {
        display: 'block'
      },
      '&.active': {
        borderRadius: '8px',
        background: 'linear-gradient(180deg, #2F9AFE 0%, #257DFE 45.31%, #309EFE 95.83%)',
        color: 'white',
        '& .MuiListItemIcon-root': {
          color: 'white'
        },
      },
    },
    menuItemIcon: {
      color: '#3A3541',
    },
  }),
)

export default Appbar