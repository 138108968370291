import { useEffect, useState } from 'react';
import { Tables } from '../../../components/Tables';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import GetColors from '../../../components/Settings/Colors/GetColors';
import DeleteColors from '../../../components/Settings/Colors/DeleteColors';

interface Color {
  id: string;
  data: Array<number | string>,
}

function Colors() {
  const heads = ['Name', 'Color', 'Action'];
  const [colorData, setColorData] = useState<Color[]>([]);
  const [page, setPage] = useState(1);
  const [last_page, setLastPage] = useState(1);
  const { colors, colorIndex } = GetColors();

  useEffect(() => {
    {
      const updatedCompanyData = colors.map((element: any) => ({
        id: element.id,
        data: [
          0,
          element.name_en,
          <div style={{ margin: "auto", width: "30px", height: "30px", borderRadius: "50%", backgroundColor: element.code }}></div>
        ],
        icons: [
          {
            mode: 'linked',
            icon: <EditIcon />,
            color: "#2173FE",
            link: "/newcolor/" + element.id
          },
          {
            mode: 'delete',
            item: <DeleteColors icon={
              {
                color: "red",
                icon: <DeleteIcon />
              }
            } id={element.id} />
          },
        ]
      }));
      setColorData([...updatedCompanyData])
    };
  }, [colors]);

  function checkDialogAnswer(data: { clicked: boolean, id: string, mode: string }) {
    console.log(data.clicked)
    console.log(data.id)
    console.log(data.mode)
  }

  function pageChange(data: { page: number }) {
    setPage(data.page)
  }

  return (
    <>
      {colorData.length > 0 ? (
        <Tables heads={heads} rows={colorData} dialogAnswerMultiTab={checkDialogAnswer} setpage={pageChange} />
      ) : null}
    </>
  );
}

export default Colors;