import ColorsApiController from "./ColorsApiController";

class ColorsController {
    static createColor(data: object) {
        return ColorsApiController.createColor(data);
    }
    static getAllColors() {
        return ColorsApiController.getAllColors();
    }
    static getColorById(id: string | number) {
        return ColorsApiController.getColorById(id);
    }
    static updateColor(id: string | number, data: object) {
        return ColorsApiController.updateColor(id, data);
    }
    static deleteColor(id: string | number) {
        return ColorsApiController.deleteColor(id);
    }
}

export default ColorsController;
