import React, { useState } from 'react'
import CompaniesController from '../../../controllers/Companies/CompaniesController';
import handleApiResponse from '../../../services/ApiResponse';
import { AlertDialog } from '../../Dialog';
import ColorsController from '../../../controllers/Settings/Colors/ColorsController';

interface DeleteProps {
    text?: string;
    icon?: any;
    id: string | number;
}


function DeleteColors(props: DeleteProps) {
    const [opendi, setOpendi] = React.useState(false)
    const [datadia, setDiag] = useState({
        title: 'Delete',
        message: 'Delete?',
        mode: 'delete'
    })

    function handleDelete() {
        setOpendi(true)
    }

    async function checkDialogAnswer(data: { clicked: boolean; }): Promise<void> {
        console.log(data.clicked)
        if (data.clicked) {
            try {
                const response = await ColorsController.deleteColor(props.id)
                const apiResponse = handleApiResponse(response)
                if (apiResponse.success) {
                    setOpendi(false)
                    window.location.href = '/colors'
                }
            } catch (error: any) {
                console.log(error)
                setOpendi(false)
            }
        }
        else {
            setOpendi(false)
        }
    }

    return (
        <>
            <small><a style={{
                color: "red"
            }} className='smalllink' onClick={handleDelete}>
                {props.text ? (props.text) : (props.icon.icon)}
            </a></small>
            <AlertDialog opendialog={opendi} datadia={datadia} dialogAnswer={checkDialogAnswer} />
        </>
    )
}

export default DeleteColors