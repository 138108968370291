import { useEffect, useState } from 'react'
import { AlertDialog } from '../Dialog';
import UserController from '../../controllers/Users/UserController';
import handleApiResponse from '../../services/ApiResponse';
import CompaniesController from '../../controllers/Companies/CompaniesController';

interface AdminProps {
    text?: string;
    icon?: any;
    id: string;
    verified: string;
}


function VerifyCompany(props: AdminProps) {
    const [opendi, setOpendi] = useState(false)
    const [datadia, setDiag] = useState({
        title: 'Verification',
        message: 'Remove verification',
        mode: 'admin'
    })
    const [verifyA, setVerifyA] = useState({
        verification_status: "pending"
    })
    const [color, setColor] = useState("green")


    useEffect(() => {
        if (props.verified == "pending") {
            setColor("grey")
            verifyA.verification_status = "verified"
            datadia.message = 'Verify'
        }
        else
        {
            setColor("#03C04A")
            verifyA.verification_status = "pending"
            datadia.message = 'Remove Admin'
        }
      }, [props.verified]);


    function handleAssign() {
        setOpendi(true)
    }

    async function checkDialogAnswer(data: { clicked: boolean; }): Promise<void> {
        console.log(data.clicked)
        if (data.clicked) {
            try {
                const response = await CompaniesController.updateCompanyStatus(props.id,verifyA)
                const apiResponse = handleApiResponse(response)
                if (apiResponse.success) {
                    setOpendi(false)
                    window.location.href = '/companies'
                }
            } catch (error: any) {
                console.log(error)
                setOpendi(false)
            }
        }
        else {
            setOpendi(false)
        }
    }

    return (
        <>
            <small><a style={{
                color: color
            }} className='smalllink' onClick={handleAssign}>
                {props.text ? (props.text) : (props.icon.icon)}
            </a></small>
            <AlertDialog opendialog={opendi} datadia={datadia} dialogAnswer={checkDialogAnswer} />
        </>
    )
}

export default VerifyCompany