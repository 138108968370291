import SizesApiController from "./SizesApiController";

class SizesController {
    static createSize(data: object) {
        return SizesApiController.createSize(data);
    }
    static getAllSizes() {
        return SizesApiController.getAllSizes();
    }
    static getSizeById(id: string | number) {
        return SizesApiController.getSizeById(id);
    }
    static updateSize(id: string | number, data: object) {
        return SizesApiController.updateSize(id, data);
    }
    static deleteSize(id: string | number) {
        return SizesApiController.deleteSize(id);
    }
}

export default SizesController;
