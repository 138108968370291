import React, { ChangeEvent } from 'react'
import { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import { Alert, CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import SizesController from '../../../controllers/Settings/Sizes/SizesController';
import { AlertDialog } from '../../../components/Dialog';
import handleApiResponse from '../../../services/ApiResponse';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Validation from '../../../components/Validation';


interface SizeData {
  name_en: string,
  name_ar: string,
  options: Array<string>
  options_ar: Array<string>
}

function SizeCreate() {
  const [sizeunit, setSizeUnit] = useState("")
  const [sizeunit_ar, setSizeUnitAr] = useState("")

  const [errors, setError] = useState<string[]>([]);

  const [sizedata, setSizedata] = useState<SizeData>({
    name_en: "",
    name_ar: "",
    options: [],
    options_ar: []
  });

  const handleDelete = (data: string) => () => {
    let arindex = sizedata.options.indexOf(data)
    setSizedata({
      ...sizedata,
      options: sizedata.options.filter(item => item !== data),
      options_ar: sizedata.options_ar.filter(item => item !== sizedata.options_ar[arindex])
    })
  };

  const handleDeleteAr = (data: string) => () => {
    let enindex = sizedata.options_ar.indexOf(data)
    setSizedata({
      ...sizedata,
      options_ar: sizedata.options_ar.filter(item => item !== data),
      options: sizedata.options.filter(item => item !== sizedata.options[enindex])
    })
  };

  const handleAppend = () => {
    if (sizedata.options.indexOf(sizeunit) == -1 && sizeunit != "" && sizedata.options_ar.indexOf(sizeunit_ar) == -1 && sizeunit_ar != "") {
      setSizedata({
        ...sizedata,
        options: [...sizedata.options, sizeunit],
        options_ar: [...sizedata.options_ar, sizeunit_ar]
      })
      setSizeUnit("")
      setSizeUnitAr("")
    }
  };

  const [loading, setLoading] = useState(false);
  const [opendi, setOpendi] = React.useState(false)
  const [datadia, setDiag] = useState({
    title: 'Size',
    message: 'Size created successfuly',
    mode: 'normal'
  })

  function checkDialogAnswer(data: { clicked: boolean; }) {
    setOpendi(false)
  }


  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setSizedata({
      ...sizedata,
      [name]: value
    });
  };


  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response
        if (id) {
          response = await SizesController.getSizeById(id)
          const apiResponse = handleApiResponse(response)
          console.log(apiResponse.data)
          if (apiResponse.success) {
            setSizedata(apiResponse.data)
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    return () => {
    }
  }, []);

  async function createSize() {
    setLoading(true)
    try {
      console.log(sizedata)
      let response
      if (id)
        response = await SizesController.updateSize(id, sizedata)
      else
        response = await SizesController.createSize(sizedata)
      const apiResponse = handleApiResponse(response)
      if (apiResponse.success) {
        datadia.title = "Success"
        id ? datadia.message = "Size saved successfully" : datadia.message = "Size added successfully"
        setOpendi(true)
      } else {
        console.error('Failed to fetch user data.');
      }
    } catch (error: any) {
      datadia.title = "Error"
      datadia.message = "Error creating size"
      setOpendi(true)
      console.error('Error fetching user data:', error);
    }
    setLoading(false)
  }

  function handleSave() {
    const { errors, flag } = Validation(
      [
        [sizedata.name_en, "Size name is required", 0],
        [sizedata.name_ar, "Size name in arabic is required", 0],
        [sizedata.options, "Size options are required", 0],
        [sizedata.options_ar, "Size options arabic are required", 0],
      ]
    )
    setError(errors)
    if (flag == 1000)
      createSize()
  }

  return (
    <div className='holder'>
      <Card className='boxcard'>
        <h2 style={{ textAlign: "center" }}>Size Information</h2>
        <Stack direction="row">
          <Stack direction="column" flex={1}>
            <p>Name</p>
            <TextField
              label="Name"
              variant="outlined"
              className='inpp'
              value={sizedata.name_en}
              name='name_en'
              onChange={handleChange} />
          </Stack>
          <Stack direction="column" flex={1} style={{ marginLeft: "10px" }}>
            <p>Arabic Name</p>
            <TextField
              label="Arabic Name"
              variant="outlined"
              className='inpp'
              value={sizedata.name_ar}
              name='name_ar'
              onChange={handleChange} />
          </Stack>
        </Stack>
        <Stack direction="column" flex={1}>
          <p>Size Units</p>
          {sizedata.options.length > 0 &&
            <Paper
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                listStyle: 'none',
                p: 0.5,
                m: 0,
              }}
              component="ul"
            >
              {sizedata.options.map((data) => {
                let icon;
                return (
                  <Chip
                    icon={icon}
                    label={data}
                    onDelete={handleDelete(data)}
                  />
                );
              })}
            </Paper>}
          {sizedata.options_ar.length > 0 &&
            <Paper
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                listStyle: 'none',
                p: 0.5,
                m: 0,
              }}
              component="ul"
            >
              {sizedata.options_ar.map((data) => {
                let icon;
                return (
                  <Chip
                    icon={icon}
                    label={data}
                    onDelete={handleDeleteAr(data)}
                  />
                );
              })}
            </Paper>}
          <Stack direction="row">
            <Stack direction="column" flex={1}>
              <TextField
                label="Size"
                variant="outlined"
                className='inpp'
                value={sizeunit}
                name='sizeunit'
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setSizeUnit(event.target.value);
                }} />
            </Stack>
            <Stack direction="column" flex={1} marginLeft={1}>
              <TextField
                label="Size Arabic"
                variant="outlined"
                className='inpp'
                value={sizeunit_ar}
                name='sizeunit_ar'
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setSizeUnitAr(event.target.value);
                }} />
            </Stack>
            <AddCircleOutlineIcon onClick={handleAppend} style={{ fontSize: "50px", margin: "auto", cursor: "pointer", color: "#2173FE" }} />
          </Stack>
        </Stack>
      </Card>
      {
        errors.length > 0 && (<Alert severity="error">
          <ul>
            {errors.map((e) => (
              <li>{e}</li>
            ))}
          </ul>
        </Alert>)
      }
      {
        loading ? (
          <CircularProgress className='boxbutton' style={{
            marginTop: "10px"
          }} />) : (<Button variant="contained" className='boxbutton' onClick={handleSave} disableElevation style={{
            backgroundColor: "#2173FE",
            marginTop: "10px"
          }}>
            Next
          </Button>)
      }
      <AlertDialog opendialog={opendi} datadia={datadia} dialogAnswer={checkDialogAnswer} />
    </div >
  )
}

export default SizeCreate