import HomeIcon from '@mui/icons-material/Home';
import CategoryIcon from '@mui/icons-material/Category';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';

const items = [
    {
        title: 'Dashboard',
        icon: <HomeIcon />,
        to: '/dashboard',
        admin: true,
        sublist: [
            {
                title: 'Overview',
                to: '/overview'
            }]
    },
    {
        title: 'Category',
        icon: <CategoryIcon />,
        admin: true,
        sublist: [
            {
                title: 'Categories',
                to: '/categories'
            },
            {
                title: 'Create',
                to: '/categorymanage'
            }]
    },
    {
        title: 'Companies',
        icon: <StoreOutlinedIcon />,
        admin: false,
        sublist: [
            {
                title: 'Companies',
                to: '/companies'
            },
            !localStorage.getItem("company_id") &&{
                title: 'Create',
                to: '/companymanage'
            }]
    },
    {
        title: 'Items',
        icon: <ListAltOutlinedIcon />,
        admin: false,
        sublist: [
            {
                title: 'Item list',
                to: '/itemslist'
            },
            localStorage.getItem("company_id") && {
                title: 'New item',
                to: '/itemsmanage',
            }]
    },
    {
        title: 'Banners',
        icon: <AdUnitsIcon />,
        admin: false,
        sublist: [
            {
                title: 'New Banner',
                to: '/newbanner'
            },
            {
                title: 'Banners',
                to: '/banners'
            }
        ]
    },
    {
        title: 'Users',
        icon: <PeopleIcon />,
        admin: true,
        to: '/userlist'
    },
    {
        title: 'Orders',
        icon: <ShoppingCartIcon />,
        admin: false,
        to: '/orders'
    },
    {
        title: 'Notifications',
        icon: <CircleNotificationsIcon />,
        admin: true,
        to: '/notifications'
    },
    {
        title: 'Settings',
        icon: <SettingsIcon />,
        admin: false,
        sublist: [
            {
                title: 'Language',
                to: '/language'
            },
            !localStorage.getItem("company_id") &&{
                title: 'Sizes',
                sublist: [
                    {
                        title: 'Size list',
                        to: '/sizes'
                    },
                    {
                        title: 'New Size',
                        to: '/newsize'
                    }]
            },
            !localStorage.getItem("company_id") &&{
                title: 'Color',
                sublist: [
                    {
                        title: 'Color list',
                        to: '/colors'
                    },
                    {
                        title: 'New Color',
                        to: '/newcolor'
                    }]
            },
        ]
    }
];

export default items