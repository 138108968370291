import Card from '@mui/material/Card';
import React, { ChangeEvent } from 'react';
import { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { TextareaAutosize } from '@mui/material';
import { TextareaAutosizeProps } from '@mui/material';
import Button from '@mui/material/Button';
import Validation from '../../components/Validation';
import NotificationController from '../../controllers/Notifications/NotificationController';
import handleApiResponse from '../../services/ApiResponse';
import { AlertDialog } from '../../components/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
function Notifications() {
    const [errors, setError] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const [opendi, setOpendi] = React.useState(false)
    const [datadia, setDiag] = useState({
        title: 'Notification',
        message: 'Notification was sent successfully.',
        mode: 'normal'
      })

      function checkDialogAnswer(data: { clicked: boolean; }) {
        setOpendi(false);
        window.location.reload();
      }
    interface NotificationData {
        type: number,
        title: string,
        title_ar: string,
        message: string,
        message_ar: string,
        to_all: number
      }
      const [notificationData, setNotificationData] = useState<NotificationData>({
        type: 1,
        title: "",
        title_ar: "",
        message: "",
        message_ar: "",
        to_all:1
      });
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setNotificationData({
          ...notificationData,
          [name]: value
        });
      };

      function objectToFormData(obj: Record<string, any>): FormData {
        const formData = new FormData();
    
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            const value = obj[key];
    
            if (Array.isArray(value)) {
              for (let i = 0; i < value.length; i++) {
                formData.append(`${key}[]`, value[i]);
              }
            } else {
              //console.log(key, value)
              formData.append(key, value);
            }
          }
        }
        console.log(formData);
        return formData;
      }

      async function send_notification(){
        setLoading(true)
        try{
            let response;
            response= await NotificationController.sendNotification(objectToFormData(notificationData));
            const apiResponse= handleApiResponse(response);
            if(apiResponse.success)
            {
                datadia.title = "Success";
                datadia.message=apiResponse.data["message"];
                setOpendi(true)
                //console.log(apiResponse.data["message"]);
            }else{
                console.error('Failed to send notification.');
                //console.log(apiResponse);
            }
        }catch(error:any)
        {
            datadia.title = "Error"
            datadia.message = "Error sending notification."
            setOpendi(true)
            console.error('Error sending notification: ', error);
        }
        setLoading(false)
      }

      function handleSave() {
        const { errors, flag } = Validation(
          [
            [notificationData.title, "English title is required", 0],
            [notificationData.title_ar, "Arabic title is required", 0],
            [notificationData.message, "English body is required", 0],
            [notificationData.message_ar, "Arabic body is required", 0],
          ]
        )
        setError(errors)
        console.log(errors);
        if (flag == 1000)
          //createSize()
          console.log(notificationData);
          send_notification();
          //console.log("form_data"+objectToFormData(notificationData));
      }
    return (
        <div className="holder">
            <Card className='boxcard'>
                <h2 style={{ textAlign: "center" }}>Send New Notification</h2>
                <Stack direction="row">
                    <Stack direction="column" flex={1}>
                        {/* <p>English Title</p> */}
                        <TextField
                            label="English Title"
                            variant="outlined"
                            className='inpp'
                            //value={sizedata.name_en}
                            name='title'
                            onChange={handleChange}
                        />
                    </Stack>

                </Stack>
                <br />
                <Stack direction="row">
                    <Stack direction="column" flex={1}>
                        {/* <p>English Title</p> */}
                        <TextField
                            label="Arabic Title"
                            variant="outlined"
                            className='inpp'
                            //value={sizedata.name_en}
                            name='title_ar'
                            onChange={handleChange}
                        />
                    </Stack>

                </Stack>
                <br />
                <Stack direction="row">
                    <Stack direction="column" flex={1}>
                        {/* <p>English Title</p> */}
                        <TextField
                            
                            label="English Body"
                            variant="outlined"
                            className='inpp'
                            //value={sizedata.name_en}
                            name='message'
                            onChange={handleChange}
                        />
                    </Stack>

                </Stack>
                <br />
                <Stack direction="row">
                    <Stack direction="column" flex={1}>
                        {/* <p>English Title</p> */}
                        <TextField
                            label="Arabic Body"
                            variant="outlined"
                            className='inpp'
                            //value={sizedata.name_en}
                            name='message_ar'
                            onChange={handleChange}
                        />
                    </Stack>

                </Stack>
                <br />
                <Stack direction="row">
                    <Stack direction="column" flex={1}>
                        {
                            loading ? (
                                <CircularProgress className='boxbutton' style={{
                                    marginTop: "10px"
                                  }} />
                            ):(
                                <Button variant="contained" className='boxbutton' onClick={handleSave} disableElevation style={{
                                    backgroundColor: "#2173FE",
                                    marginTop: "10px"
                                  }}>
                                    Send
                                  </Button>
                            )   
                        }
                    
                    </Stack>

                </Stack>
            </Card>
            <AlertDialog opendialog={opendi} datadia={datadia} dialogAnswer={checkDialogAnswer} />
        </div>
    )
}

export default Notifications;