import React, { ChangeEvent } from 'react'
import { useState } from 'react';
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import { CircularProgress } from '@mui/material';
import PasswordStrengthChecker from '../../components/Users/PasswordCheck';
import { AlertDialog } from '../../components/Dialog';
import OTP from '../../components/Users/OTP';
import UserController from '../../controllers/Users/UserController';
import handleApiResponse from '../../services/ApiResponse';

function Changepassword() {
    const [verified, setVerified] = useState(false);
    const [loading, setLoading] = useState(false);
    const [opendi, setOpendi] = React.useState(false)
    const [datadia, setDiag] = useState({
        title: 'Password changed',
        message: 'Password changed successfuly',
        mode: 'normal',
        url:'/users'
    })
    const [passchange, setPasschange] = useState(
        {
            phone_number: "",
            otp_code: "",
            new_password: "AAADDDaaa123$",
            confirm_password: "AAADDDaaa123$"
        });

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setPasschange({
            ...passchange,
            [name]: value
        });
    };

    const checkOTPVerification = (data: { verified: boolean, otp: string, phone: string }) => {
        setVerified(data.verified);
        setPasschange({
            ...passchange,
            ['phone_number']: data.phone,
            ['otp_code']: data.otp
        });
    };

    async function handleSave() {

        setLoading(true);
        try {
            const response = await UserController.resetPassword(passchange);
            const apiResponse = handleApiResponse(response)
            if (apiResponse.success) {
                setOpendi(true)
                window.location.href = '/profile'
                console.log(apiResponse.data.code)
            }
        } catch (error: any) {
            console.log(error)
            datadia.title = "Invalid"
            datadia.message = "User not found!"
            setOpendi(true)
        }
        setLoading(false);
    }

    function checkDialogAnswer(data: { clicked: boolean; }) {
        setOpendi(false)
    }

    return (
        <div className='holder'>
            {
                verified ? (
                    <div>
                        <Card className='boxcard'>
                            <h2 style={{ textAlign: "center" }}>Reset Password</h2>
                            <Stack direction="column" flex={1}>
                                <p>OTP</p>
                                <TextField
                                    type='text'
                                    label="OTP"
                                    variant="outlined"
                                    className='inpp'
                                    value={passchange.otp_code}
                                    name='otp_code'
                                    onChange={handleChange} />
                            </Stack>
                            <Stack direction="column" flex={1}>
                                <p>New password</p>
                                <TextField
                                    type='password'
                                    label="New password"
                                    variant="outlined"
                                    className='inpp'
                                    value={passchange.new_password}
                                    name='new_password'
                                    onChange={handleChange} />
                            </Stack>
                            <PasswordStrengthChecker password={passchange.new_password} />
                            <Stack direction="column" flex={1}>
                                <p>Confirm Password</p>
                                <TextField
                                    type='password'
                                    label="Confirm Password"
                                    variant="outlined"
                                    className='inpp'
                                    value={passchange.confirm_password}
                                    name="confirm_password"
                                    onChange={handleChange}
                                />
                            </Stack>
                        </Card>
                        {loading ? (
                            <CircularProgress className='boxbutton' style={{
                                marginTop: "10px"
                            }} />) : (<Button variant="contained" className='boxbutton' onClick={handleSave} disableElevation style={{
                                backgroundColor: "#2173FE",
                                marginTop: "10px"
                            }}>
                                Reset Password
                            </Button>)}
                    </div>
                ) :
                    (
                        <OTP phonenumber={passchange.phone_number} verifiedComp={checkOTPVerification} withverification={false}/>
                    )}
            <AlertDialog opendialog={opendi} datadia={datadia} dialogAnswer={checkDialogAnswer} />
        </div>
    )
}

export default Changepassword;