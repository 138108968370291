import React, { useEffect, useState } from 'react'
import SizesController from '../../../controllers/Settings/Sizes/SizesController';
import handleApiResponse from '../../../services/ApiResponse';


interface Sizes {
  id: number,
  name_en: string,
  name_ar: string,
  options: null
}

const GetSizes = (id?: number | string) => {
  const sizeIndex: Map<number, Sizes> = new Map();
  let [sizes, setSizes] = useState<Sizes[]>([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        let response
        if (id)
          response = await SizesController.getSizeById(id)
        else
          response = await SizesController.getAllSizes()
        const apiResponse = handleApiResponse(response)
        if (apiResponse.success) {
          sizes = apiResponse.data;
          setSizes(apiResponse.data)
          apiResponse.data.forEach((element: any) => {
            let ob: Sizes = {
              id: element["id"],
              name_en: element["name_en"],
              name_ar: element["name_ar"],
              options: element["options"]
            };
            sizeIndex.set(element["id"], ob);
          });
          console.log(sizes)
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    return () => {
    }
  }, []);


  return { sizes, sizeIndex };
}

export default GetSizes;