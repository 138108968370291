import UserModel from '../../models/Users/UserModel'
import BillApiService from './OrdersApiController';
import ApiService from './OrdersApiController';

class BillsController {
    static getBillById(id: string | number) {
        return BillApiService.getBillById(id);
    }
    static getBillOrders(id: string | number) {
        return BillApiService.getBillOrders(id);
    }
    static getCompanyBills(id: string | number, page: number, status: string) {
        return BillApiService.getCompanyBills(id,page, status);
    }
    static getUserBills(id: string | number, status: string) {
        return BillApiService.getUserBills(id, status);
    }
    static getOrderById(id: string | number) {
        return BillApiService.getOrderById(id);
    }
    static updateOrderById(id: string | number) {
        return BillApiService.updateOrderById(id);
    }
    static deleteOrderById(id: string | number) {
        return BillApiService.deleteOrderById(id);
    }
    static updateOrderStatus(id: string | number, data: Object) {
        return BillApiService.updateOrderStatus(id, data);
    }
}

export default BillsController;
