import AppMenu from './components/AppMenu';

function App() {
  // React.useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await apiService.get('/api/csrf_token?t=9');
  //       localStorage.setItem('csrfToken', response.data["csrf_token"]);
  //       console.log(response.data["csrf_token"]);
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };
  //   fetchData(); // Call the async function to fetch data
  // }, []);

  return (
    <div className="App" style={{ backgroundColor: "#F4F5F9" }}>
      <AppMenu />
    </div>
  );
}

export default App;
